import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile';
import { getSession, getCurrSession } from "../../network/UserPool";
import { VW, VH } from "../../componets/function/sizeAdapter";
import arrowLeft from "../../componets/icon/arrowLeft.svg";
import arrowRight from "../../componets/icon/arrowRight.svg";
import editRounded from "../../componets/icon/EditRounded.svg";

import { Button } from 'antd';
import dayjs from 'dayjs';
import log from "../../componets/function/log";
import { ApiTeishutsuJokyoInfo } from "../../network/api";
import SubmitCsvOutputDialog from "../../componets/dialog/SubmitCsvOutputDialog";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

const styles = (theme) => ({
    ground: {
        height: '100%',
        paddingTop: VH(25),
        paddingLeft: VW(24),
        paddingRight: VW(24)
    },
    icon: {
        width: VW(24),
        height: VH(24),
        marginRight: VW(14)
    },
    iconContainer: {
        transform: 'scale(1.667)',
    },
    item: {
        width: VW(680),
        display: "flex",
        alignItems: "center",
        borderTop: '1px solid #E0E0E0',
        marginLeft: VW(24),
        marginRight: VW(24)
    },
    itemInput: {
        width: VW(368),
        height: VH(56),
        display: "flex",
        alignItems: "center",
    },
    itemLeftKey: {
        width: VW(56),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginRight: VW(8),
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
    itemRightKey: {
        width: VW(56),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginLeft: VW(8),
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
    itemKey: {
        width: VW(240),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
    },
    itemText: {
        marginLeft: VW(16),
    },
    itemHead: {
        height: VH(29),
        alignSelf: 'stretch',
        paddingLeft: VW(8),
        paddingRight: VW(8),
        paddingTop: VH(4),
        paddingBottom: VH(4),
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        display: 'inline-flex'
    },
    itemHeadFont: {
        textAlign: 'center',
        color: 'white',
        fontSize: VW(14),
        fontFamily: 'Noto Sans',
        fontWeight: '700',
        lineHeight: VH(21),
        wordWrap: 'break-word'
    },
    itemBody: {
        height: VH(76),
        alignSelf: 'stretch',
        paddingLeft: VW(8),
        paddingRight: VW(8),
        paddingTop: VH(4),
        paddingBottom: VH(4),
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'inline-flex'
    },
    itemBodyFont: {
        textAlign: 'center',
        color: '#27303D',
        fontSize: VW(14),
        fontFamily: 'Noto Sans',
        fontWeight: '400',
        lineHeight: VH(16.80),
        wordWrap: 'break-word'
    },
    itemMonthlyHead: {
        color: "#27303D",
        fontSize: VW(24),
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        paddingLeft: VW(10)
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
        '& > Button': {
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: '#FFF',
            color: theme.palette.primary.main,
            border: `1px solid #2195D3`,
        }
    },
});


function SubmitConfirmCSVoutput(props) {
    const {
        classes,
        setSelectFlag,
        masterData
    } = props

    useEffect(() => {
        setSelectFlag(routerNames.SubmitConfirmCSVOutput)
        getCurrSession()
        getSubmitConfirmInfo(dayjs())
        log(masterData)
    }, [setSelectFlag])

    const [homonDayDate, setHomonDayDate] = useState(dayjs())
    const [data, setData] = useState()
    const changeHomonDay = (value, dateString) => {
        setHomonDayDate(value)
    };
    const setHononMonth = (flag) => {
        if (flag === 0) {
            // 前月時間を選択する
            const newhomonDayDate = moment(homonDayDate).add(-1, 'M')
            setHomonDayDate(newhomonDayDate)
            // 最新データを検索する
            getSubmitConfirmInfo(newhomonDayDate)
        } else if (flag === 1) {
            // 次月時間を選択する
            const newhomonDayDate = moment(homonDayDate).add(1, 'M')
            setHomonDayDate(newhomonDayDate)
            // 最新データを検索する
            getSubmitConfirmInfo(newhomonDayDate)
        } else {
            const newhomonDayDate = moment(homonDayDate)
            setHomonDayDate(newhomonDayDate)
            return newhomonDayDate
        }

    };

    const navigate = useNavigate()
    const [submitCsvOutputOpen, setSubmitCsvOutputOpen] = useState(false)
    const SubmitCsvOutputOnClose = () => {
        setSubmitCsvOutputOpen(false)
    }
    const SubmitCsvOutputOnOpen = () => {
        setSubmitCsvOutputOpen(true)
    }
    const getSubmitConfirmInfo = (date) => {
        const newdateString = moment(date).format("YYYYMM")
        let params = {
            homon_month: newdateString
        }
        ApiTeishutsuJokyoInfo(params).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                for (let index = 0; index < resData?.teishutsuJokyo_info?.length; index++) {
                    resData.teishutsuJokyo_info[index]["status"] = resData.teishutsuJokyo_info[index].input_status === 0 ? "未提出" : "提出済"
                    resData.teishutsuJokyo_info[index]["statusBackground"] = resData.teishutsuJokyo_info[index].input_status === 0 ? "#27303D" : "#D42020"
                    resData.teishutsuJokyo_info[index]["background"] = (index % 2) === 1 ? '#F4F5F9' : 'white'
                }
                setData(resData)
            } else if (res.status === 201) {

                setData(resData)
            }
        }).catch(err => {

        })
    };
    return (
        <div className={classes.ground}>
            <div className={classes.itemMonthlyHead} style={{ borderLeft: '5px solid #2195D3', fontWeight: 'bold' }}>
                提出状況確認・CSV出力
            </div>
            <div className={classes.buttonGroup} style={{ marginTop: VH(24), marginBottom: VH(24), width: "100%" }}>
                <div className={classes.itemInput} style={{ float: "left" }}>
                    <div className={classes.itemLeftKey}> <img src={arrowLeft} className={classes.icon} onClick={() => { setHononMonth(0) }} /></div>
                    <div className={classes.itemKey}>
                        <div className={classes.itemText}>{
                            homonDayDate !== null &&
                                homonDayDate !== "" &&
                                homonDayDate !== undefined ? moment(homonDayDate).format("YYYY年MM月") : ""
                        }
                        </div>
                    </div>

                    <div className={classes.itemRightKey}><img src={arrowRight} className={classes.icon} onClick={() => { setHononMonth(1) }} /></div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button style={{ width: VW(240), height: VH(56) }} onClick={SubmitCsvOutputOnOpen}>CSV出力</Button>
                </div>
            </div>

            <div className={classes.itemVisitOne}>
                <div style={{ width: "100%", background: '#27303D', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                    <div className={classes.itemHead} style={{ width: "7%", borderRight: '0.50px #E0E0E0 solid', alignItems: 'center' }} />
                    <div className={classes.itemHead} style={{ width: "7%", borderRight: '0.50px #E0E0E0 solid', alignItems: 'center' }}>
                        <div className={classes.itemHeadFont} >No.</div>
                    </div>
                    <div className={classes.itemHead} style={{ width: "12%", borderRight: '0.50px #E0E0E0 solid', alignItems: 'center' }}>
                        <div className={classes.itemHeadFont}>管理番号</div>
                    </div>
                    <div className={classes.itemHead} style={{ width: "59%", borderRight: '0.50px #E0E0E0 solid', alignItems: 'flex-start' }}>
                        <div className={classes.itemHeadFont}>利用者名</div>
                    </div>
                    <div className={classes.itemHead} style={{ width: "15%", borderRight: '0.50px #E0E0E0 solid', alignItems: 'center' }}>
                        <div className={classes.itemHeadFont}>提出状況</div>
                    </div>
                </div>
                {data && data?.teishutsuJokyo_info?.map((item, index) => {
                    let backgroundValue = item.background;
                    let statusBackground = item.statusBackground;
                    let indexNo = index + 1;
                    return (

                        <div key={index} style={{ width: "100%", background: backgroundValue, justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                            <div style={{ width: "7%", height: VH(76), padding: 3, justifyContent: 'center', alignItems: 'center', borderRight: '0.50px #E0E0E0 solid', display: 'inline-flex' }}>
                                <div style={{ width: VW(24), height: VH(24) }}>
                                    <img src={editRounded} className={classes.icon} onClick={() => { navigate(routerNames.MonthlyVisitingRecord, { state: item }) }} />
                                </div>
                            </div>
                            <div className={classes.itemBody} style={{ width: "7%", alignItems: 'center', gap: 4, borderRight: '0.50px #E0E0E0 solid' }}>
                                <div className={classes.itemBodyFont} >{indexNo}</div>
                            </div>
                            <div className={classes.itemBody} style={{ width: "12%", alignItems: 'center', gap: 10, borderRight: '0.50px #E0E0E0 solid' }}>
                                <div className={classes.itemBodyFont} >{item.supporter}</div>
                            </div>
                            <div className={classes.itemBody} style={{ width: "59%", alignItems: 'flex-start', gap: 10, borderRight: '0.50px #E0E0E0 solid' }}>
                                <div className={classes.itemBodyFont} style={{ color: '#2095D4', textDecoration: 'underline' }} onClick={() => { navigate(routerNames.MonthlyVisitingRecord, { state: item }) }}>{item.riyosya_name}</div>
                            </div>
                            <div className={classes.itemBody} style={{ width: "15%", alignItems: 'center', gap: 10 }}>
                                <div className={classes.itemBodyFont} style={{ color: statusBackground }}>{item.status}</div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <SubmitCsvOutputDialog
                open={submitCsvOutputOpen}
                onClose={SubmitCsvOutputOnClose}
                homonMonth={moment(homonDayDate).format("YYYYMM")}

            />
        </div >
    )
}

SubmitConfirmCSVoutput.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(SubmitConfirmCSVoutput);