import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { Checkbox, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: VH(16),
        borderRadius: VW(5),
    },
    buttonGroup1: {
        borderRadius: VW(4),
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontWeight: 'bold',
        // borderRadius: VW(5),
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup3: {
        color: '#FFF',
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        borderRadius: VW(5),
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16)
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    tableBody: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(16),
        marginBottom: VH(5)
    },
});


function DeleteDialog(props) {
    const {
        classes,
        open,
        onClose,
        data,
        deleteConfirm
    } = props

    const [headerList, setHeaderList] = useState([
        {
            "name": "種別",
            "width1": VW(44),
            "key": "syubetsu_name",
            "checkBox": false
        },
        {
            "name": "学校CD",
            "width1": VW(57),
            "key": "gako_code",
            "checkBox": false
        },
        {
            "name": "学校名",
            "width1": VW(130),
            "key": "gako_name",
            "checkBox": false
        },
        {
            "name": "管理番号",
            "width1": VW(74),
            "key": "kanri_no",
            "checkBox": false
        },
        {
            "name": "利用者",
            "width1": VW(58),
            "key": "owner_name",
            "checkBox": false
        },
        {
            "name": "シリアル番号",
            "width1": VW(89),
            "key": "serial_num",
            "checkBox": false
        },
        {
            "name": "MACアドレス",
            "width1": VW(115),
            "key": "mac_address",
            "checkBox": false
        },
        {
            "name": "預かり（作業状況）",
            "width1": VW(142),
            "key": "work_status_name",
            "checkBox": false
        },
        {
            "name": " インシ デント",
            "width1": VW(58),
            "key": "incident_check",
            "checkBox": true
        },
        {
            "name": "紛失",
            "width1": VW(58),
            "key": "funshitsu_check",
            "checkBox": true
        },
        {
            "name": "ステータス",
            "width1": VW(90),
            "key": "jyotai_name",
            "checkBox": false
        }
    ]);

    useEffect(() => {
    }, [open])

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(971)}
            height={VH(447)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>端末情報を削除しますか？</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <div className={classes.tableBody}>
                <div style={{ marginLeft: VW(8) }}>以下の端末情報を削除します。</div>
            </div>

            <div style={{ height: VH(58), marginTop: VH(24), display: "flex" }}>
                {headerList.map((item, headerIndex) => {
                    return (
                        <div key={headerIndex}>
                            <div style={{ backgroundColor: "#27303D", width: item.width1, height: VH(58), display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0", color: "#fff", fontWeight: 700, fontSize: VW(14) }}>
                                {item.name}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div style={{ height: VH(174), overflowY: "scroll" }}>
                {data && data.map((item, listIndex) => {
                    return (
                        <div key={listIndex} style={{ display: "flex", height: VH(58), borderLeft: "1px solid #E0E0E0" }}>
                            {
                                headerList.map((row, headerIndex) => {
                                    return (
                                        <div key={headerIndex} style={{ display: "flex" }}>
                                            <div key={headerIndex} style={{ backgroundColor: "#FFFFFF", width: row.width1, height: VH(58), display: "flex", alignItems: "center", justifyContent: row.checkBox ? "center" : "", borderRight: "1px solid #E0E0E0", color: "#27303D", fontWeight: 400, fontSize: VW(14), borderBottom: "1px solid #E0E0E0", paddingLeft: row.checkBox ? 0 : VW(8), color: headerIndex === 3 && item.newflag == 0 ? "#2095D4" : '#27303D' }}>
                                                {row.checkBox ?
                                                    <Checkbox sx={{ color: "#E0E0E0", width: VW(24), height: VH(24), display: item[row.key] === false ? "none" : "" }} style={{ color: "#000000" }} checked={item[row.key] === false ? false : true} />
                                                    :
                                                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                        {item[row.key]}
                                                    </div>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })}
            </div>

            <div className={classes.buttonGroup} style={{ paddingLeft: VW(2), marginBottom: VH(5), paddingBottom: VH(5) }}>
                <div style={{ width: "50%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    <Button onClick={deleteConfirm} className={classes.buttonGroup3} style={{
                        width: VW(240), height: VH(56), fontSize: VW(16),
                        fontWeight: 'bold', borderRadius: VW(5)
                    }} >削除</Button>
                </div>


                <div style={{ width: "50%", paddingRight: VW(6), display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <Button onClick={onClose} className={classes.buttonGroup1} style={{
                        width: VW(240), height: VH(56), fontSize: VW(16),
                        fontWeight: 'bold', borderRadius: VW(5)
                    }} >キャンセル</Button>
                </div>
            </div>
        </Modal>
    )
}

DeleteDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(DeleteDialog);