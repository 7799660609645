import React, { useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import { Modal, Button, DatePicker, Input } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter";
import { IconButton, Autocomplete } from "@mui/material";
import { Close } from "@mui/icons-material";
import sortIcon from "../../componets/icon/sortIcon.svg"
import deleteIcon from "../../componets/icon/deleteIcon.svg"
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { MenuItem, Select, TextField, Checkbox } from "@mui/material";
import log from "../../componets/function/log";
import { TabletMacRounded } from "@mui/icons-material";
import { type_Option, ownerNo_Option, status_Option } from "../../wordsFile/selectItem";
import { CalendarToday, CloseRounded } from "@mui/icons-material";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import { getSession, getUserPool, getCurrSession } from "../../network/UserPool";

const { TextArea } = Input;
dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24),
        '& > Button': {
            borderRadius: "4px",
            width: VW(240),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24),
        marginBottom: VH(36),
        '& > Button': {
            width: VW(533),
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    },
    buttonGroup3: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16)
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    bar: {
        display: "flex",
        alignItems: "center",
        height: VH(64),
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #E0E0E0',
        justifyContent: 'space-between',
        paddingLeft: VW(16),
        paddingRight: VW(16),
    },
    icon: {
        width: VW(32),
        height: VH(32),
        marginRight: VW(24)
    },
    detailTable: {
        backgroundColor: "#ffffff",
        paddingBottom: VH(24),

    },
    item: {
        display: "flex",
        alignItems: "center",
        marginLeft: VW(24),
        marginRight: VW(24)
    },
    itemText: {
        color: "#27303D",
        fontSize: VW(12),
        fontWeight: 700,
        width: VW(130),
        height: VH(88),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        borderTop: '1px solid #E0E0E0',
    },
    itemInput: {
        width: VW(235),
        height: VH(88),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        fontSize: VW(12),
        borderTop: '1px solid #E0E0E0',
    },
    itemInputInner: {
        height: VH(56),
        width: VW(219),
        fontSize: VW(12)
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
    autocomplete: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(56),
            padding: 0
        },
        // '& .MuiInputBase-input': {
        //     height: "26px",
        // },
        '& .MuiInputBase-root': {
            height: VH(56),
            backgroundColor: "#fff"
        },
        // '& .MuiAutocomplete-input': {
        //     fontSize: "16px",
        //     height: "26px",
        //     border: '0',
        // },
        // '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
        //     backgroundColor: "rgba(0, 0, 0, 0.04)",
        // },
    },

});


function SingleInputDialog(props) {
    const {
        classes,
        open,
        onClose,
        masterData,
        onConfirm,
        errorOpen,
        setRrrorOpen,
        errorContent,
        setErrorContent,
        move,
        setMove

    } = props

    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#9E9E9E"
        }
    }));
    const getGakoAutoList = () => {
        let list = []
        masterData.gako_info.map((item, index) => {
            if (item.tanmatsuKanriFuragu == "1") {
                list.push({ "label": item.gakoMeisho })
            }
        })
        return list;
    };
    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };
    const [data, setData] = useState(
        {
            "syubetsu_code": 1, //種別
            "koshin_bi": null,
            "gako_code": null, //学校CD
            "gako_name_code": null, //学校名
            "kanri_no": null,//管理番号
            "owner": null,//利用者
            "serial_num": null,//シリアル番号
            "mac_address": null,//MACアドレス
            "jyotai_no": null,//ステータス
            "mokushi": null,//目視
            "setsuzokunwmei": null,//接続NW
            "mdm": false,//MDM
            "ifilter": false,//iFilter
            "asm": false,//ASM
            "appleid": null,//AppleID
            "appleidpassword": null,//AppleIDのパスワード
            "donyu_nendo": null,//導入年度
            "donyu_nengappi": null,//導入年月日
            "memo": null,//memo
            "expand": 0,
            "newflag": 0
        }
    )
    useEffect(() => {
        getCurrSession()
        if (open) {
            setData({
                "syubetsu_code": 1, //種別
                "koshin_bi": null,
                "gako_code": null, //学校CD
                "gako_name_code": null, //学校名
                "kanri_no": null,//管理番号
                "owner": null,//利用者
                "serial_num": null,//シリアル番号
                "mac_address": null,//MACアドレス
                "jyotai_no": null,//ステータス
                "mokushi": null,//目視
                "setsuzokunwmei": null,//接続NW
                "mdm": false,//MDM
                "ifilter": false,//iFilter
                "asm": false,//ASM
                "appleid": null,//AppleID
                "appleidpassword": null,//AppleIDのパスワード
                "donyu_nendo": null,//導入年度
                "donyu_nengappi": null,//導入年月日
                "memo": null,//memo
                "expand": 0,
                "newflag": 0
            })
        }
        setRrrorOpen(false)
    }, [open])

    const changeData = (value, key) => {
        data[key] = value
        if (key === "gako_name_code") {
            if (!value) {
                data.gako_code = null
            }
            masterData.gako_info.map((item, index) => {
                if (value == item.gakoMeisho) {
                    data.gako_code = item.gakoCd
                }
            })
        }
        setData({ ...data })
    }

    const handleChange = (e, key) => {
        const { value: inputValue } = e.target;
        const reg = /^[A-Za-z0-9!@#$%^&*()_+{}|:<>?=\-\[\]\\;'\",./~]+$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            changeData(inputValue, key);
        }
    };
    // const [errorOpen, setRrrorOpen] = useState(false)
    // const [errorContent, setErrorContent] = useState("")

    const checkData = () => {
        if (data.syubetsu_code && data.gako_code && data.gako_name_code && data.kanri_no && data.jyotai_no) {
            onConfirm(data)
        } else {
            const modalNode = modalRef.current;
            modalNode.scrollIntoView({ block: "start" });
            setRrrorOpen(true)
            setErrorContent("*のつく項目は必須入力項目です。")
        }
    }

    useEffect(() => {
        if (move) {
            const modalNode = modalRef.current;
            modalNode.scrollIntoView({ block: "start" });
            setMove(false)
        }
    }, [move])
    const modalRef = useRef(null);
    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(840)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>個別登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                    marginTop={VH(24)}
                />
                <div style={{ boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD' }}>
                    <div style={{ marginTop: VH(24), width: "100%" }}>
                        <div className={classes.bar} onClick={() => changeData(data.expand === 0 ? 1 : 0, 'expand')} >
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                                <TabletMacRounded
                                    style={{ marginRight: VW(8), color: "#2095D4", width: VW(25.33), height: VH(32) }}
                                />
                                <div
                                    style={{
                                        color: "#053550",
                                        fontWeight: 700,
                                        fontSize: VW(20)
                                    }}> {"基本情報 "}</div>
                            </div>
                            {data.expand === 0 ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
                        </div>

                        <div className={classes.detailTable} style={{ display: data.expand === 0 ? "" : "none", }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", color: "#E60024", fontSize: VW(14), marginRight: VW(24) }}>* 必須入力項目</div>

                            <div className={classes.item}>
                                <div className={classes.itemText} >種別 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                                <div className={classes.itemInput} >
                                    <Select
                                        className={classes.select}
                                        style={{
                                            width: VW(219), height: VH(56), borderRadius: VW(6)

                                        }}
                                        value={data.syubetsu_code || ""}
                                        label=""
                                        onChange={(event) => changeData(event.target.value, 'syubetsu_code')}
                                        displayEmpty
                                        renderValue={
                                            data.syubetsu_code !== null &&
                                                data.syubetsu_code !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                        }
                                    >
                                        {type_Option.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={parseInt(item.value)}>{item.label}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>

                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} >学校CD <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                                <div className={classes.itemInput} >
                                    <div className={classes.itemInput} >
                                        {data?.gako_code ? data?.gako_code : ""}
                                    </div>
                                </div>

                                <div className={classes.itemText} style={{ marginLeft: VW(16) }}>学校名 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                                <div className={classes.itemInput} >
                                    <Autocomplete
                                        className={classes.autocomplete}
                                        disablePortal
                                        options={getGakoAutoList()}
                                        sx={{ width: VW(219), }}
                                        value={data.gako_name_code || ""}
                                        onChange={(event: any, newValue: string | null) => {
                                            changeData(newValue != null ? newValue.label : "", 'gako_name_code')
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small"
                                        />}
                                    />
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} >管理番号 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                                <div className={classes.itemInput} >
                                    <Input
                                        className={classes.itemInputInner}
                                        value={data.kanri_no || ""}
                                        onChange={(event) => changeData(event.target.value, 'kanri_no')}
                                        placeholder="入力してください"
                                        maxLength={50}
                                    />
                                </div>

                                <div className={classes.itemText} style={{ marginLeft: VW(16) }}>利用者</div>
                                <div className={classes.itemInput} >
                                    <Select
                                        className={classes.select}
                                        style={{
                                            width: VW(219), height: VH(56), borderRadius: VW(6)

                                        }}
                                        value={data.owner || ""}
                                        label=""
                                        onChange={(event) => changeData(event.target.value, 'owner')}
                                        displayEmpty
                                        renderValue={
                                            data.owner !== null &&
                                                data.owner !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                        }
                                    >
                                        {ownerNo_Option.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={parseInt(item.value)}>{item.label}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} >シリアル番号</div>
                                <div className={classes.itemInput} >
                                    <Input
                                        className={classes.itemInputInner}
                                        value={data.serial_num || ""}
                                        // onChange={(event) => changeData(event.target.value.replace(/^[A-Za-z0-9]*$/, ""), 'serial_num')}
                                        onChange={(event) => handleChange(event, 'serial_num')}
                                        placeholder=" 半角英数字で入力してください"
                                        maxLength={50}
                                    />
                                </div>

                                <div className={classes.itemText} style={{ marginLeft: VW(16) }}>MACアドレス</div>
                                <div className={classes.itemInput} >
                                    <Input
                                        className={classes.itemInputInner}
                                        value={data.mac_address || ""}
                                        // onChange={(event) => changeData(event.target.value.replace(/^[A-Za-z0-9]*$/, ""), 'mac_address')}
                                        onChange={(event) => handleChange(event, 'mac_address')}
                                        placeholder="00:00:00:00:00:00"
                                        maxLength={50}
                                    />
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} >ステータス <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                                <div className={classes.itemInput} >
                                    <Select
                                        className={classes.select}
                                        style={{
                                            width: VW(219), height: VH(56), borderRadius: VW(6)

                                        }}
                                        value={data.jyotai_no || ""}
                                        label=""
                                        onChange={(event) => changeData(event.target.value, 'jyotai_no')}
                                        displayEmpty
                                        renderValue={
                                            data.jyotai_no !== null &&
                                                data.jyotai_no !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                        }
                                    >
                                        {status_Option.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>

                                <div className={classes.itemText} style={{ marginLeft: VW(16) }}>目視</div>
                                <div className={classes.itemInput} >
                                    <Input
                                        className={classes.itemInputInner}
                                        value={data.mokushi || ""}
                                        onChange={(event) => changeData(event.target.value, 'mokushi')}
                                        placeholder="入力してください"
                                        maxLength={50}
                                    />
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} >接続NW</div>
                                <div className={classes.itemInput} >
                                    <Select
                                        className={classes.select}
                                        style={{
                                            width: VW(219), height: VH(56), borderRadius: VW(6)

                                        }}
                                        value={data.setsuzokunwmei || ""}
                                        label=""
                                        onChange={(event) => changeData(event.target.value, 'setsuzokunwmei')}
                                        displayEmpty
                                        renderValue={
                                            data.setsuzokunwmei !== null &&
                                                data.setsuzokunwmei !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                        }
                                    >
                                        {masterData.terminalnw_info.map((item, i) => {
                                            return (
                                                <MenuItem key={i} value={parseInt(item.no)}>{item.terminalnw}</MenuItem>
                                            )

                                        })}
                                    </Select>
                                </div>

                                <div className={classes.itemText} style={{ marginLeft: VW(16) }}>MDM</div>
                                <div className={classes.itemInput} >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF' }} sx={{ color: "#E0E0E0" }} checked={data.mdm} onChange={(event) => changeData(event.target.checked, 'mdm')} />
                                        <div style={{ color: "#27303D", fontSize: VW(12) }}>
                                            あり
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} >iFilter</div>
                                <div className={classes.itemInput} >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF' }} sx={{ color: "#E0E0E0" }} checked={data.ifilter} onChange={(event) => changeData(event.target.checked, 'ifilter')} />
                                        <div style={{ color: "#27303D", fontSize: VW(12) }}>
                                            あり
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.itemText} style={{ marginLeft: VW(16) }}>ASM</div>
                                <div className={classes.itemInput} >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF' }} sx={{ color: "#E0E0E0" }} checked={data.asm} onChange={(event) => changeData(event.target.checked, 'asm')} />
                                        <div style={{ color: "#27303D", fontSize: VW(12) }}>
                                            あり
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} >AppleID</div>
                                <div className={classes.itemInput} >
                                    <Input
                                        className={classes.itemInputInner}
                                        value={data.appleid || ""}
                                        // onChange={(event) => changeData(event.target.value.replace(/^[A-Za-z0-9]*$/, ""), 'appleid')}
                                        onChange={(event) => handleChange(event, 'appleid')}
                                        placeholder="半角英数字で入力してください"
                                        maxLength={100}
                                    />
                                </div>

                                <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                    <div>
                                        <div>AppleIDの</div>
                                        <div>パスワード</div>
                                    </div>
                                </div>
                                <div className={classes.itemInput} >
                                    <Input
                                        className={classes.itemInputInner}
                                        value={data.appleidpassword || ""}
                                        // onChange={(event) => changeData(event.target.value.replace(/^[A-Za-z0-9]*$/, ""), 'appleidpassword')}
                                        onChange={(event) => handleChange(event, 'appleidpassword')}
                                        placeholder="半角英数字で入力してください"
                                        maxLength={50}
                                    />
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} >導入年度</div>
                                <div className={classes.itemInput} >
                                    <Input
                                        className={classes.itemInputInner}
                                        value={data.donyu_nendo || ""}
                                        onChange={(event) => changeData(event.target.value, 'donyu_nendo')}
                                        placeholder="R1"
                                        maxLength={10}
                                    />
                                </div>

                                <div className={classes.itemText} style={{ marginLeft: VW(16) }}>導入年月日</div>
                                <div className={classes.itemInput} >
                                    <Input
                                        className={classes.itemInputInner}
                                        value={data.donyu_nengappi || ""}
                                        onChange={(event) => changeData(event.target.value, 'donyu_nengappi')}
                                        placeholder="R1.1.1"
                                        maxLength={10}
                                    />
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemText} style={{ height: VH(188) }}>Memo</div>
                                <div className={classes.itemInput} style={{ height: VH(188), width: VW(616), alignItems: "center", justifyContent: "center" }}>
                                    <TextArea
                                        style={{ height: VH(156), width: VW(600), fontSize: VW(12), padding: 16 }}
                                        value={data.memo || ""}
                                        onChange={(event) => changeData(event.target.value, 'memo')}
                                        maxLength={200}
                                        placeholder="Memoを入力してください"
                                    />
                                </div>
                            </div>

                        </div>

                    </div>
                </div >
                <div className={classes.buttonGroup} style={{ marginBottom: VH(5), paddingBottom: VH(5) }}>
                    <div style={{ width: "50%", paddingLeft: VW(14), display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <Button onClick={onClose} className={classes.buttonGroup1} style={{
                            width: 200, borderRadius: "4px", width: VW(152), height: VH(48), fontSize: VW(16),
                            fontWeight: 'bold', borderRadius: VW(5)
                        }} >閉じる</Button>
                    </div>
                    <div style={{ width: "100%", paddingRight: VW(10), display: "flex", alignItems: "flex-end", justifyContent: "flex-end", marginTop: VH(14) }}>
                        <Button onClick={checkData} className={classes.buttonGroup3} style={{
                            borderRadius: "4px", width: VW(152), height: VH(48), fontSize: VW(16),
                            fontWeight: 'bold', borderRadius: VW(5)
                        }} >登録</Button>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

SingleInputDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(SingleInputDialog);