import React, { useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { VW, VH } from "../../componets/function/sizeAdapter"
import sortIcon from "../../componets/icon/sortIcon.svg"
import visitIcon from "../../componets/icon/visitIcon.svg"
import infoIcon from "../../componets/icon/infoIcon.svg"
import deleteIcon from "../../componets/icon/deleteIcon.svg"
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { Box, MenuItem, Select, Button } from "@mui/material";
import Input from "antd/es/input/Input";
import log from "../../componets/function/log";
import { kotsu_kikanOption } from "../../wordsFile/selectItem";
import routeIcon from "../../componets/icon/routeIcon.svg"
const styles = (theme) => ({
    bar: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#ffffff",
        justifyContent: 'space-between',

    },
    icon: {
        width: VW(24),
        height: VH(24),
        marginRight: VW(8)
    },
    item: {
        display: "flex",
        alignItems: "center",
    },
    itemText: {
        color: "#27303D",
        fontSize: VW(12),
        fontWeight: 700,
        width: VW(80),
        height: VH(72),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        borderTop: '1px solid #E0E0E0',
    },
    itemInput: {
        width: VW(184),
        height: VH(72),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        fontSize: VW(12),
        borderTop: '1px solid #E0E0E0',
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
});

function RouteView(props) {
    const {
        classes,
        visitData,
        setVisitData,
        index,
        item,
        routeIndex,
        routeItem,
        updateVisitData,
        masterData,
        updateVisitRouteData,
        deleteRouteData,
        nameNum

    } = props



    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#9E9E9E",
        }
    }));

    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };


    return (
        <div >
            <div className={classes.bar}>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <img
                        src={routeIcon}
                        className={classes.icon}
                    />
                    <div
                        style={{
                            color: "#053550",
                            fontWeight: 700,
                            fontSize: VW(16)
                        }}>{"移動手段 " + nameNum}</div>
                </div>
                <img
                    style={{ width: VW(24), height: VH(24) }}
                    src={deleteIcon}
                    onClick={() => { deleteRouteData(index, routeIndex) }}
                />
            </div>
            <div style={{ marginTop: VH(14) }}>
                <div className={classes.item}>
                    <div className={classes.itemText} style={{ width: VW(80) }}>交通機関</div>
                    <div className={classes.itemInput} style={{ width: VW(184) }} >
                        <Select
                            className={classes.select}
                            style={{
                                width: VW(168), height: VH(56), borderRadius: VW(6), fontSize: VW(12)

                            }}
                            value={routeItem.kotsukikan_code || ""}
                            label=""
                            onChange={(event) => updateVisitRouteData(event.target.value, index, routeIndex, 'kotsukikan_code')}
                            displayEmpty
                            renderValue={
                                routeItem.kotsukikan_code !== null &&
                                    routeItem.kotsukikan_code !== "" ? undefined : () => <Placeholder>交通機関を選択してください</Placeholder>
                            }
                        >
                            {kotsu_kikanOption.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div className={classes.itemText} style={{ width: VW(160) }}>出発地：IC / 駅 / バス停</div>
                    <div className={classes.itemInput} style={{ width: VW(345) }}>
                        <Input
                            style={{ height: VH(56), width: VW(329), fontSize: VW(12) }}
                            value={routeItem.shuppatsu_ic || ""}
                            onChange={(event) => updateVisitRouteData(event.target.value, index, routeIndex, 'shuppatsu_ic')}
                            maxLength={60}
                            placeholder="出発地を入力してください"
                        />
                    </div>

                </div>

                <div className={classes.item}>
                    <div className={classes.itemText} style={{ width: VW(80) }}>
                        <div>
                            <div>高速料金</div>
                            <div>/ 運賃</div>
                        </div>
                    </div>
                    <div className={classes.itemInput} style={{ width: VW(184) }}>
                        <Input
                            type="number"
                            style={{ height: VH(56), width: VW(168), fontSize: VW(12) }}
                            value={routeItem.unchin_kosoku_ryokin || ""}
                            onChange={(event) =>
                                event.target.value.length <= 10 &&
                                updateVisitRouteData(event.target.value, index, routeIndex, 'unchin_kosoku_ryokin')
                            }
                            placeholder="料金を入力してください"
                        />
                    </div>
                    <div className={classes.itemText} style={{ width: VW(160) }}>到着地：IC / 駅 / バス停</div>
                    <div className={classes.itemInput} style={{ width: VW(345) }}>
                        <Input
                            style={{ height: VH(56), width: VW(329), fontSize: VW(12) }}
                            value={routeItem.tochaku_ic || ""}
                            onChange={(event) => updateVisitRouteData(event.target.value, index, routeIndex, 'tochaku_ic')}
                            maxLength={60}
                            placeholder="到着地を入力してください"
                        />
                    </div>
                </div>
                {/* <div style={{
                    marginTop: VH(8),
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    fontSize: VW(18),
                    fontWeight: 400,
                    color: "#D42020",
                }}
                    onClick={() => { deleteRouteData(index, routeIndex) }}>
                    {"移動手段 " + nameNum + "の削除"}
                    <img
                        style={{ width: VW(24), height: VH(24), marginLeft: VW(8) }}
                        src={deleteIcon}

                    />
                </div> */}
            </div>
        </div >
    )
}

RouteView.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(RouteView);