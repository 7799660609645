import { message } from "antd";
import axios from "axios";
import { ApiTokenRefresh } from "./api";
import { localstorage } from "../wordsFile/wordsFile";
var isRefreshing = false
var requests = []
const dealReturn = (response) => {
  if (!response) {
    return "接続中断";
  }
  const { status, data, config } = response;

  if (status === 401) {
    if (data.detail == '認証に失敗しました。再ログインしてください。' || data.detail == 'アクセストークンの有効期限が切れているため、アクセスできません。' || data.detail == "Access token expired") {
      if (!isRefreshing) {
        isRefreshing = true
        return ApiTokenRefresh({
          refresh_token: localStorage.getItem(localstorage.refresh_token)
        }).then(sessionData => {
          if (sessionData.status == 200) { // verify after login
            localStorage.setItem(localstorage.access_token, sessionData.data.access_token)
            config.headers["authorization"] = 'Bearer' + " " + sessionData.data.access_token
            requests.forEach(cb => cb(sessionData.data.access_token))
            requests = []
            isRefreshing = false
            return axios(config)
          }
          isRefreshing = false
        }).catch(() => {
          localStorage.clear()
          window.location.href = '/login'
        })
      } else {

        // the token is being refreshed and will return a promise that does not execute resolve
        return new Promise((resolve) => {
          // put resolve in the queue, use a function to save it, and execute it directly when token is refreshed
          requests.push((token) => {
            // config.baseURL = ''
            config.headers.Authorization = token;
            resolve(axios(config))
          })
        })
      }
    } else {
      localStorage.clear()
      window.location.href = '/login'
    }

  }
  return response;
};

export default dealReturn;
