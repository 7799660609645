import React, { useState, useEffect } from "react";
import {
    Box
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames, localstorage } from '../../wordsFile/wordsFile'
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { VW, VH } from "../function/sizeAdapter"
import AdminPanelSettingsRoundedIcon from "../icon/AdminPanelSettingsRounded.svg"
import VisitMenu from "../icon/VisitMenu";
import SupportIcon from "../icon/supportListIcon.svg"
import log from "../function/log";
import { ApiMenuAuthorityInfo } from "../../network/api";
import { EditRounded, EventRounded, FormatListBulletedRounded, TabletMacRounded } from "@mui/icons-material";
import { stringToObejct } from "../function/JsonUtil";
import dayjs from "dayjs";

const styles = (theme) => ({
    menuLink: {
        textDecoration: "none",
        color: 'black'
    },
    menuItem: {
        width: '100%',
        height: VH(56),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid #1E4962`,
    },
    iconItem: {
        width: '100%',
        marginLeft: VW(16),
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(14),
        position: 'relative'
    }
});

function LeftMenu(props) {
    const {
        classes,
        selectFlag,
        refreshVisit,
        setRefreshVisit,
        windowCloseOpen,
        setWindowCloseOpen,
        setWindowNextRoute,
        visitDataCompared,
        setVisitDataCompared,
        visitData,
        setVisitData
    } = props

    const [menuItems, setMenuItems] = useState(localStorage.getItem(localstorage.menuItem) && stringToObejct(localStorage.getItem(localstorage.menuItem)).data)
    const location = useLocation();



    // menu change
    const changeMenu = (item, index) => {
        if (selectFlag == routerNames.VisitRecordInput) {
            return
        }
        if (item.name === "翌月訪問予定入力") {
            return
        }
        if (item.selectColor === '#2095D4') {
            return
        } else {
            menuItems.map(element => {
                element.selectColor = '#053550'
                element.iconColor = '#AAAAAA'
            })
            for (var i = 0; i < menuItems.length; i++) {
                menuItems[index].selectColor = '#2095D4'
                menuItems[index].iconColor = '#053550'
            }
            if (index === 0 && selectFlag == "/home/monthlyVisitingRecord") {
                setRefreshVisit(true)
            }
            setMenuItems([...menuItems])
        }
    }


    useEffect(() => {
        menuItems.map(element => {
            if (element.link === selectFlag) {
                element.selectColor = '#2095D4'
                element.iconColor = '#053550'
            } else {
                element.selectColor = '#053550'
                element.iconColor = '#AAAAAA'
            }
        })
        setMenuItems([...menuItems])
    }, [selectFlag])




    const toLink = (item, e) => {
        const loginDate = dayjs(localStorage.getItem(localstorage.loginDate))
        if (selectFlag == routerNames.VisitRecordInput && item.link != routerNames.VisitRecordInput && JSON.stringify(visitDataCompared) != JSON.stringify(visitData) && location.pathname != "/home/visitSubmitted") {
            setWindowCloseOpen(true)
            if (item.name === '翌月訪問予定入力') {
                setWindowNextRoute("翌月訪問予定入力")
            } else {
                setWindowNextRoute(item.link)
            }
            e.preventDefault();
        }
    }

    return (
        <>
            {
                menuItems && menuItems.map((item, index) => (
                    <Link
                        to={item.link}
                        onClick={(e) => { toLink(item, e) }}
                        key={index}
                        className={classes.menuLink}
                        target={item.name === '翌月訪問予定入力' ? '_blank' : '_self'}
                    >
                        <Box
                            sx={{ backgroundColor: item.selectColor }}
                            className={classes.menuItem}
                            key={index}
                            onClick={() => { changeMenu(item, index) }}
                        >
                            <span className={classes.iconItem}>
                                {
                                    item.name === '訪問実績入力' ? <EditRounded style={{ color: "#FFFFFF", width: VW(24), height: VH(24) }} />
                                        :
                                        item.name === '月間訪問実績' ? <EventRounded style={{ color: "#FFFFFF", width: VW(24), height: VH(24) }} />
                                            :
                                            item.name === '翌月訪問予定入力' ? <FormatListBulletedRounded style={{ color: "#FFFFFF", width: VW(24), height: VH(24) }} />
                                                :
                                                item.name === '支援内容検索' ? <img src={SupportIcon} style={{ color: "#FFFFFF", width: VW(24), height: VH(24) }} />
                                                    :
                                                    item.name === '端末管理' ? <TabletMacRounded style={{ color: "#FFFFFF", width: VW(24), height: VH(24) }} />
                                                        :
                                                        item.name === '端末状態 問い合わせ' ? <TabletMacRounded style={{ color: "#FFFFFF", width: VW(24), height: VH(24) }} />
                                                            :
                                                            item.name === '提出状況確認・CSV出力' ? <img src={AdminPanelSettingsRoundedIcon} style={{ color: "#FFFFFF", width: VW(24), height: VH(24) }} />
                                                                : ''
                                }
                                <span style={{ marginLeft: VW(8), color: "#ffffff", fontWeight: 700, fontSize: VW(16) }}>{item.name}</span>
                            </span>
                        </Box>
                    </Link>
                ))
            }
        </>
    )
}

LeftMenu.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(LeftMenu);