
import React, { useState, useEffect } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, DatePicker, message } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import locale from "antd/es/date-picker/locale/ja_JP";
import { routerNames } from '../../wordsFile/wordsFile'
import { formatCheck, errorMessage, localstorage } from "../../wordsFile/wordsFile";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton, Checkbox } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ApiGetFavorite, ApiDeleteFavorite } from "../../network/api";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { kotsu_kikanOption } from "../../wordsFile/selectItem";
dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        borderRadius: VW(5),
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontWeight: 'bold',
        borderRadius: VW(5),
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup3: {
        color: '#FFF',
        borderRadius: VW(5),
        backgroundColor: "#2095D4",
        marginLeft: VW(16),
        marginTop: VH(14)
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(20)
    },
    tableBody: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(16),
        marginBottom: VH(5)
    },
});


function CommonDialog(props) {
    const {
        classes,
        open,
        onClose,
        masterData,
        onSelect,
        visitData,
        visitDataIndex
    } = props
    const [data, setData] = useState([])

    useEffect(() => {
        if (open) {
            getFavorite()
        }
    }, [open])
    const getFavorite = () => {
        const user_id = localStorage.getItem(localstorage.username)
        let card1_fukuro = false
        let card2_flag = false
        let card1_school = getGakoName(visitData?.homon_jiseki[0]?.homon_saki)
        let card1_workspace = getSagyoBasho(visitData?.homon_jiseki[0]?.sagyo_basho, (visitData?.homon_jiseki[0]))

        visitData?.homon_jiseki[0]?.kotsukikanList?.map((row) => {
            if (row.ofuku == 2) {
                card1_fukuro = true
            }
        })

        if (visitDataIndex == 0) {
            card2_flag = false
        } else {
            card2_flag = true
        }
        let params = {
            user_id: user_id,
            card1_fukuro: false,
            card2_flag: false,
            card1_school: card1_school,
            card1_workspace: card1_workspace,
        }
        ApiGetFavorite(params).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                resData.homon_jiseki.map((item) => {
                    item["checked"] = false
                })
                setData(resData.homon_jiseki)
            } else {
                setData([])
            }
        }).catch(err => {

        })
    }
    const getGakoName = (value) => {
        let sakiName = ""
        masterData.gako_info.map((item, i) => {
            if (item.homonJissekiFuragu === "1" && value == item.no) {
                sakiName = item.gakoMeisho
            }
        })
        return sakiName;
    };
    const deleteFavorite = () => {
        let id = ""
        data.map((item, i) => {
            if (item.checked) {
                id = item.homon_jiseki_id
            }
        })
        if (id == "") {
            return
        }
        let params = {
            "homon_jiseki_id_list": [id],
        }
        ApiDeleteFavorite(params).then(res => {
            if (res.status === 200) {
                getFavorite()
            }
        }).catch(err => {

        })
    }
    const onCheckChange = (checked, index) => {
        data.map((item, i) => {
            data[i].checked = false
        })
        data[index].checked = true
        setData([...data])

    };
    const onConfirmSelect = () => {
        let item = {}
        data.map((row, i) => {
            if (row.checked) {
                item = row
            }
        })
        if (Object.keys(item).length !== 0) {
            onSelect(item)
        }

    };
    const getSagyoBasho = (value, item) => {
        let string = ""
        if (!value) {
            string = ""
        } else if (value == "0") {
            string = item.sagyo_basho_sonota;
        } else {
            masterData.sagyobasyo_info.map((row, i) => {
                if (value == row.no) {
                    string = row.homonsakiMeisho
                }
            })
        }
        return string
    };

    const getKotsukikanCode = (value) => {
        let string = ""
        if (!value) {
            string = ""
        } else {
            kotsu_kikanOption.map((row, i) => {
                if (value == row.value) {
                    string = row.label
                }
            })
        }
        return string
    };
    let routeItem = {
        "kotsukikan_jiseki_id": null,
        "homon_jiseki_id": null,
        "ofuku": 1,
        "kotsukikan_code": null,
        "shuppatsu_ic": null,
        "tochaku_ic": null,
        "unchin_kosoku_ryokin": null,
        "delete_flag": null,
        "expand": 0
    }
    let routeItemEnd = {
        "kotsukikan_jiseki_id": null,
        "homon_jiseki_id": null,
        "ofuku": 2,
        "kotsukikan_code": null,
        "shuppatsu_ic": null,
        "tochaku_ic": null,
        "unchin_kosoku_ryokin": null,
        "delete_flag": null,
        "expand": 0
    }
    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}

            centered
            width={VW(892)}
            closable={false}

        >
            <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold' }}> よく行く訪問先の選択</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(20),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <div style={{ width: VW(844), height: VH(50), backgroundColor: "#27303D", display: "flex" }}>
                <div style={{ width: VW(30), borderRight: "1px solid #E0E0E0" }}>
                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>選</div>
                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>択</div>
                </div>
                <div style={{ width: VW(240), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                    訪問先（作業場所）
                </div>
                <div>
                    <div style={{ width: VW(287), height: VH(25), borderRight: "1px solid #E0E0E0", borderBottom: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                        交通手段1
                    </div>
                    <div style={{ width: VW(287), height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                        <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                            交通機関
                        </div>
                        <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                            出発IC / 駅
                        </div>
                        <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                            到着IC / 駅
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ width: VW(287), height: VH(25), borderBottom: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                        交通手段2
                    </div>
                    <div style={{ width: VW(287), height: VH(25), display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                        <div style={{ flex: 1, height: VH(25), display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                            交通機関
                        </div>
                        <div style={{ flex: 1, height: VH(25), display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                            出発IC / 駅
                        </div>
                        <div style={{ flex: 1, height: VH(25), display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontSize: VW(14), fontWeight: 700 }}>
                            到着IC / 駅
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: VH(480), overflowY: "scroll" }}>
                {data.map((item, index) => {
                    let newList1 = [];
                    let newList2 = [];
                    for (const row of item.kotsukikanList) {
                        if (newList1.length < 2 && !newList1.some((newItem) => newItem.ofuku === row.ofuku)) {
                            newList1.push(row);
                        } else if (newList2.length < 2 && !newList2.some((newItem) => newItem.ofuku === row.ofuku)) {
                            newList2.push(row);
                        }
                    }
                    if (newList1.length == 1) {
                        if (newList1[0].ofuku == 1) {
                            newList1.push(routeItemEnd)
                        } else {
                            newList1.push(routeItem)
                        }
                    }
                    if (newList2.length == 1) {
                        if (newList2[0].ofuku == 1) {
                            newList2.push(routeItemEnd)
                        } else {
                            newList2.push(routeItem)
                        }
                    }
                    newList1.sort((a, b) => a.ofuku - b.ofuku);
                    newList2.sort((a, b) => a.ofuku - b.ofuku);


                    return (
                        <div key={index} style={{ width: VW(844), height: VH(50), backgroundColor: "#fff", display: "flex", border: "1px solid #E0E0E0" }}>
                            <div style={{ width: VW(30), borderRight: "1px solid #E0E0E0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                    sx={{ color: "#E0E0E0", width: "24px", height: "24px" }} checked={item.checked} onChange={(event) => onCheckChange(event.target.checked, index)} />
                            </div>
                            <div style={{ width: VW(240), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                {getSagyoBasho(item.sagyo_basho, item)}
                            </div>
                            <div>
                                {newList1.length > 0 ?
                                    newList1.map((newList1Item, newList1Index) => {
                                        return (
                                            <div key={newList1Index}>
                                                <div style={{ width: VW(287), backgroundColor: newList1Item.ofuku == 1 ? "#fff" : "#F4F5F9", height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                                    <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                                        {getKotsukikanCode(newList1Item.kotsukikan_code)}
                                                    </div>
                                                    <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400, overflow: "hidden" }}>
                                                        <p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{newList1Item.shuppatsu_ic}</p>
                                                    </div>
                                                    <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400, overflow: "hidden" }}>
                                                        <p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{newList1Item.tochaku_ic}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <div >
                                        <div style={{ width: VW(287), backgroundColor: "#fff", height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                            <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                            <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                        </div>
                                        <div style={{ width: VW(287), backgroundColor: "#F4F5F9", height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                            <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                            <div style={{ flex: 1, height: VH(25), borderRight: "1px solid #E0E0E0", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div>
                                {newList2.length > 0 ?
                                    newList2.map((newList2Item, newList1Index2) => {
                                        return (
                                            <div key={newList1Index2}>
                                                <div style={{ width: VW(287), backgroundColor: newList2Item.ofuku == 1 ? "#fff" : "#F4F5F9", borderRight: "1px solid #E0E0E0", height: VH(25), display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                                    <div style={{ flex: 1, height: VH(25), display: "flex", alignItems: "center", borderRight: "1px solid #E0E0E0", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                                        {getKotsukikanCode(newList2Item.kotsukikan_code)}
                                                    </div>
                                                    <div style={{ flex: 1, height: VH(25), display: "flex", alignItems: "center", borderRight: "1px solid #E0E0E0", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400, overflow: "hidden" }}>
                                                        <p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{newList2Item.shuppatsu_ic}</p>
                                                    </div>
                                                    <div style={{ flex: 1, height: VH(25), display: "flex", alignItems: "center", borderRight: "1px solid #E0E0E0", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400, overflow: "hidden" }}>
                                                        <p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{newList2Item.tochaku_ic}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div >
                                        <div style={{ width: VW(287), backgroundColor: "#fff", height: VH(25), display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            <div style={{ flex: 1, height: VH(25), display: "flex", borderRight: "1px solid #E0E0E0", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                            <div style={{ flex: 1, height: VH(25), display: "flex", borderRight: "1px solid #E0E0E0", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                            <div style={{ flex: 1, height: VH(25), display: "flex", borderRight: "1px solid #E0E0E0", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                        </div>
                                        <div style={{ width: VW(287), backgroundColor: "#F4F5F9", height: VH(25), display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            <div style={{ flex: 1, height: VH(25), display: "flex", borderRight: "1px solid #E0E0E0", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                            <div style={{ flex: 1, height: VH(25), display: "flex", borderRight: "1px solid #E0E0E0", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                            <div style={{ flex: 1, height: VH(25), display: "flex", borderRight: "1px solid #E0E0E0", alignItems: "center", justifyContent: "center", color: "#27303D", fontSize: VW(14), fontWeight: 400 }}>
                                            </div>
                                        </div>
                                    </div>}

                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.buttonGroup} style={{ marginBottom: VH(5), paddingBottom: VH(5) }}>
                <div style={{ width: "50%", paddingLeft: VW(14), display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    <Button onClick={deleteFavorite} className={classes.buttonGroup1} style={{
                        width: 200, borderRadius: "4px", width: VW(152), height: VH(48), fontSize: VW(16),
                        fontWeight: 'bold', borderRadius: VW(5)
                    }} >削除</Button>
                </div>
                <div style={{ width: "100%", paddingRight: VW(10), display: "flex", alignItems: "flex-end", justifyContent: "flex-end", marginTop: VH(14) }}>
                    <Button onClick={onConfirmSelect} className={classes.buttonGroup3} style={{
                        borderRadius: "4px", width: VW(152), height: VH(48), fontSize: VW(16),
                        fontWeight: 'bold', borderRadius: VW(5)
                    }} >選択</Button>
                </div>
            </div>
        </Modal>
    )
}

CommonDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(CommonDialog);