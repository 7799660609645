import { CognitoUserPool } from "amazon-cognito-identity-js";
import { localstorage } from "../wordsFile/wordsFile";

// epro user pool
const ICTUserPool = new CognitoUserPool({
  UserPoolId: "ap-northeast-1_ZTwBzJSew",
  ClientId: "5ijhd3brsel8fqqg3bdqiibs2t",
});

const getUserPool = () => {
  return ICTUserPool;
};

const getSession = async () =>
  await new Promise((resolve, reject) => {
    const userPool = getUserPool();
    const user = userPool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject();
        } else {
          resolve(session);
        }
      });
    } else {
      reject();
    }
  });
const getCurrSession = async () => {
  let currSession;
  try {
    currSession = await getSession();
  } catch (err) {
    localStorage.removeItem(localstorage.access_token)
    localStorage.removeItem(localstorage.refresh_token)
    localStorage.removeItem(localstorage.id_token)
    localStorage.removeItem(localstorage.authority_id)
    localStorage.removeItem(localstorage.preferred_username)
    localStorage.removeItem(localstorage.email)
    localStorage.removeItem(localstorage.username)
    window.location.href = '/login'
  }
  return currSession;
};
export { ICTUserPool, getUserPool, getSession, getCurrSession };
