import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'


const styles = (theme) => ({
    ground: {
        width: '100%',
        height: '100%',
        padding: '30px'
    }
});

function DashBoard(props) {
    const {
        classes,
        setSelectFlag
    } = props

    useEffect(() => {
        setSelectFlag(routerNames.DashBoard)
    }, [setSelectFlag])


    return (
        <div className={classes.ground}>

        </div>
    )
}

DashBoard.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(DashBoard);