import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { routerNames, localstorage } from '../../wordsFile/wordsFile'
import { getSession, getUserPool, getCurrSession } from "../../network/UserPool";
import { VW, VH } from "../../componets/function/sizeAdapter"
import Stepper from '@mui/material/Stepper';

import { KeyboardArrowUpRounded, KeyboardArrowDownRounded, ArrowLeft } from "@mui/icons-material";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Modal, Button, Input, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import arrowLeft from "../../componets/icon/arrowLeft.svg";
import arrowRight from "../../componets/icon/arrowRight.svg";
import editRounded from "../../componets/icon/EditRounded.svg";
import inactivenot from "../../componets/icon/inactivenot.svg";
import inactived from "../../componets/icon/inactived.svg";
import deleteRounded from "../../componets/icon/DeleteForeverRounded.svg";
import { Box } from "@mui/material";
import log from "../../componets/function/log";
import timeFormat from "../../componets/function/timeFormat";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiHomonMonthInfo } from "../../network/api";
import { ApiHomonMonthConfirmInfo } from "../../network/api";
import { ApiHomonMonthDelete } from "../../network/api";
import { ApiHomonMonthUpdate } from "../../network/api";
import MonthlyRecordSubmitDelete from "../../componets/dialog/MonthlyRecordSubmitDelete";
import moment from 'moment'
import { DeleteForeverRounded, EditRounded } from "@mui/icons-material";
const styles = (theme) => ({
    ground: {
        height: '100%',
        paddingTop: VH(25),
        paddingLeft: VW(24),
        paddingRight: VW(24)
    },
    icon: {
        width: VW(24),
        height: VH(24),
        marginRight: VW(14)
    },
    iconContainer: {
        transform: 'scale(1.667)',
    },
    step: {
        '& .MuiStepLabel-root .Mui-completed': {
            color: '#2195D3',
        },
        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
            fontWeight: 400,
        },
        '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700
        },
        '& .MuiStepLabel-root .Mui-active': {
            color: '#2195D3',
        },
        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
            fontWeight: 400,
        },
        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700,
            fontSize: VW(14)
        },
        '& .Mui-disabled .MuiStepIcon-root': {
            color: '#E0E0E0',
        },
        '& .Mui-disabled .MuiStepIcon-root .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700,
            fontSize: VW(14),
        },
        '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
        },
        "& .MuiStepConnector-line": {
            borderColor: "#E0E0E0",
        }
    },
    bar: {
        display: "flex",
        alignItems: "center",
        height: VH(64),
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #E0E0E0',
        justifyContent: 'space-between',
        paddingLeft: VW(12),
        paddingRight: VW(16),
    },
    item: {
        width: VW(680),
        display: "flex",
        alignItems: "center",
        borderTop: '1px solid #E0E0E0',
        marginLeft: VW(24),
        marginRight: VW(24)
    },
    itemVisitOne: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        // fontSize: VW(20),
        // marginBottom: VH(13)
    },
    itemText: {
        height: VH(42),
        textAlign: 'center',
        borderRight: '0.50px #E0E0E0 solid',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        display: 'flex'
    },
    itemInput: {
        width: VW(368),
        height: VH(56),
        display: "flex",
        alignItems: "center",
    },
    itemLeftKey: {
        width: VW(56),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginRight: VW(8),
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
    itemRightKey: {
        width: VW(56),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginLeft: VW(8),
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
    itemDeleteKey: {
        width: VW(56),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: VW(23),
    },
    itemKey: {
        width: VW(240),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
    },
    itemMonthlyHead: {
        color: "#27303D",
        fontSize: VW(24),
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        paddingLeft: VW(10)
        // marginLeft: VW(24),
        // marginRight: VW(24)
    },
    itemTextOne: {
        height: VH(84),
        textAlign: 'center',
        borderRight: '0.50px #E0E0E0 solid',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        // display: 'inline-flex'
        // display: 'flex'
    },
    itemHeadBody: {
        textAlign: 'center',
        color: 'white',
        fontSize: 14,
        fontFamily: 'Noto Sans',
        fontWeight: '700',
        lineHeight: VH(16.80),
        wordWrap: 'break-word'
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
        '& > Button': {
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: '#FFF',
            color: theme.palette.primary.main,
            border: `1px solid #2195D3`,
        }
    },
    buttonGroupBottom: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        marginBottom: VH(40),
        paddingBottom: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(58),
            fontWeight: 'bold',
            fontSize: VW(16),
            fontWeight: 'bold',
            fontWeight: 700,
            backgroundColor: '#FFF',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
        '& > Button': {
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: theme.palette.primary.main,
            color: '#FFF',
            border: `1px solid #2195D3`,
        }
    },
    buttonGroup3: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16)
    },
    rowClass: {
        borderTop: '1px #E0E0E0 solid',
        borderBottom: '1px #E0E0E0 solid',
        borderLeft: '1px #E0E0E0 solid',
        borderRight: '1px #E0E0E0 solid',
    },
    newItem: {
        height: VH(42),
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        // paddingRight: "1px",
        borderRight: '0.50px #E0E0E0 solid',


    }
});

const steps = [
    '訪問先の登録',
    '登録内容の確認',
    '登録完了',
];
function MonthlyRecordConfirm(props) {
    const {
        classes,
        setSelectFlag,
        insteadUserId,
        insteadUserName,
        // userId,
        // visitData,
        setVisitData,
        // isUpdate,
        // setIsUpdate,
        masterData,
        menuFlag
    } = props
    const location = useLocation();
    const { state } = location;
    useEffect(() => {
        setVisitData({})
        setSelectFlag(routerNames.MonthlyVisitingRecord)
        getCurrSession()
        if (state) {
            setHomonDayDate(dayjs(state.homon_month))
            initMonthlyRecordConfirm(dayjs(state.homon_month))
        } else {
            setHomonDayDate(dayjs())
            initMonthlyRecordConfirm(dayjs())
        }
    }, [setSelectFlag, state])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [homonDayDate, setHomonDayDate] = useState(dayjs())
    const [data, setData] = useState()
    const [inactiveicon, setInactiveicon] = useState(0)
    const [subbmit, setSubbmit] = useState(false)
    const [homonJisekiId, setHomonJisekiId] = useState([])
    const [homonJisekiDay, setHomonJisekiDay] = useState([])

    const [monthlyRecordSubmitDeleteOpen, setMonthlyRecordSubmitDeleteOpen] = useState(false)
    const monthlyRecordSubmitDeleteOnClose = () => {
        setMonthlyRecordSubmitDeleteOpen(false)
    }
    const monthlyRecordSubmitDeleteCloseOnOpen = () => {
        if (inactiveicon === 2) {
            return
        }
        setHomonJisekiDay([])
        if (homonJisekiId.length == 0) {
            return
        }
        getCheckDate()
        setMonthlyRecordSubmitDeleteOpen(true)
    }

    const addHomonJisekiId = (value) => {
        setHomonJisekiId([...homonJisekiId, value])
    }
    const deleteHomonJisekiId = (value) => {
        setHomonJisekiId(
            homonJisekiId.filter((item, index) => (item !== value))
        )
    }
    const changeHomonJisekiId = (event, item) => {
        let value = event.target.value
        if ((value != '') && (value != 0)) {
            if (event.target.checked) {
                // 選択した訪問実績追加
                let valuelist = value.split(',')
                let itemvalueone = valuelist.length > 0 ? valuelist[0] : ''
                let itemvaluetwo = valuelist.length > 1 ? valuelist[1] : ''
                if (valuelist.length > 1) {
                    addHomonJisekiId(itemvalueone)
                    addHomonJisekiId(itemvaluetwo)
                } else {
                    addHomonJisekiId(itemvalueone)
                }

            } else {
                // 選択した訪問実績削除
                let valuelist = value.split(',')
                let itemvalueone = valuelist.length > 0 ? valuelist[0] : ''
                let itemvaluetwo = valuelist.length > 1 ? valuelist[1] : ''
                if (valuelist.length > 1) {
                    deleteHomonJisekiId(itemvalueone)
                    deleteHomonJisekiId(itemvaluetwo)
                } else {
                    deleteHomonJisekiId(itemvalueone)
                }
            }
        }
        data.homon_month_data.map(rows => {
            if (item === rows) {
                if (rows.checked) {
                    rows.checked = false
                } else {
                    rows.checked = true
                }
            }
        })
        var checked = true
        data.homon_month_data.map(rows => {
            if (!rows.checked) {
                checked = false
            }
        })
        if (checked) {
            setCheckAll(true)
        } else {
            setCheckAll(false)
        }
        setData({ ...data })
        log("homonJisekiId =" + homonJisekiId)
    };
    const onCheckAllChange = (e) => {
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        var list = []
        data.homon_month_data.map(item => {
            if (e.target.checked) {
                item.checked = true
                if (item.day_homonjiseki.length > 0) {
                    item.day_homonjiseki.map(rows => {
                        list.push(rows.homon_jiseki_id.toString())
                    })
                }
            } else {
                item.checked = false
                setHomonJisekiId([])
            }
        })
        log(list)
        setHomonJisekiId([...list])
        setData({ ...data })
    };

    const getCheckDate = () => {
        var list = []
        var listId = []
        data.homon_month_data.map(item => {
            if (item.checked) {
                item.day_homonjiseki.map(itemDay => {
                    listId.push(itemDay.homon_jiseki_id)
                })
                list.push(item.homon_day)
            }
        })
        setHomonJisekiDay(list)
        setHomonJisekiId(listId)
    };

    const setHononMonth = (flag) => {
        if (flag === 0) {
            // 前月時間を選択する
            const newhomonDayDate = moment(homonDayDate).add(-1, 'M')
            setHomonDayDate(newhomonDayDate)
            log("newhomonDayDate = " + moment(newhomonDayDate).format("YYYYMM"))
            // 最新データを検索する
            initMonthlyRecordConfirm(newhomonDayDate)
        } else if (flag === 1) {
            // 次月時間を選択する
            const newhomonDayDate = moment(homonDayDate).add(1, 'M')
            setHomonDayDate(newhomonDayDate)
            log("newhomonDayDate = " + moment(newhomonDayDate).format("YYYYMM"))
            // 最新データを検索する
            initMonthlyRecordConfirm(newhomonDayDate)
        } else {
            const newhomonDayDate = moment(homonDayDate)
            setHomonDayDate(newhomonDayDate)
            return newhomonDayDate
        }

    };

    const navigate = useNavigate()

    const initMonthlyRecordConfirm = (date) => {
        //利用者の場合
        const user_id = state ? state.user_id : localStorage.getItem(localstorage.username)
        const newdateString = moment(date).format("YYYYMM")
        let params = {
            user_id: user_id,
            homon_month: newdateString
        }
        //　月間訪問提出状況を取得する
        ApiHomonMonthConfirmInfo(params).then(res => {
            let resData = res.data
            log(resData)
            if (res.status === 200) {
                setInactiveicon(0)
                let temp = 0
                if (resData.homon_month_status.input_status == 2) {
                    setInactiveicon(2)
                    setSubbmit(true)
                    temp = 2
                } else {
                    setSubbmit(false)
                }

                let businessMonth = masterData.taishoGatsudo_info[0].taishogatsudo + "01"
                let selectdateString = newdateString + "01"

                // 未提出の場合、提出可能
                temp === 0 ? setButtonDisabled(false) : setButtonDisabled(true)
                // 提出可能なのは対象月のみなので、対象月以外の月の提出ボタンは非活性となるべき
                if ((dayjs(selectdateString).startOf('month') > dayjs(businessMonth).endOf('month'))
                    || (dayjs(selectdateString).endOf('month') < dayjs(businessMonth).startOf('month'))) {
                    setButtonDisabled(true)
                }
                if (state !== null) {
                    // 提出ボタン活性：通常入力時、対象月度が未提出の場合。代行入力時は常に可能 （stateはnull出ない場合、代行入力）
                    setButtonDisabled(false)
                    setInactiveicon(0)
                }
            } else if (res.status === 201) {

            }
        }).catch(err => {

        })

        // 月間訪問実績情報を取得する
        ApiHomonMonthInfo(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                for (let index = 0; index < resData?.homon_month_data?.length; index++) {
                    resData.homon_month_data[index]["holidayBackground"] = resData.homon_month_data[index].holidayFlag === 1 ? "#D42020" : "#27303D"
                    resData.homon_month_data[index]["background"] = (index % 2) === 1 ? 'white' : 'white' //'#F4F5F9' : 'white'

                    if (resData.homon_month_data[index].kyuka_kubun === '1') {
                        resData.homon_month_data[index]["holidayBody"] = '有給1日'
                    } else if (resData.homon_month_data[index].kyuka_kubun === '2') {
                        resData.homon_month_data[index]["holidayBody"] = '有給AM'
                    } else if (resData.homon_month_data[index].kyuka_kubun === '3') {
                        resData.homon_month_data[index]["holidayBody"] = '有給PM'
                    } else if (resData.homon_month_data[index].kyuka_kubun === '4') {
                        resData.homon_month_data[index]["holidayBody"] = '特休1日'
                    } else if (resData.homon_month_data[index].kyuka_kubun === '5') {
                        resData.homon_month_data[index]["holidayBody"] = '特休AM'
                    } else if (resData.homon_month_data[index].kyuka_kubun === '6') {
                        resData.homon_month_data[index]["holidayBody"] = '特休PM'
                    } else {
                        resData.homon_month_data[index]["holidayBody"] = ''
                    }
                    resData.homon_month_data[index]["checked"] = false
                }
                setData(resData)
                log(resData)

            } else if (res.status === 201) {

                setData(resData)
            }
        }).catch(err => {

        })


    };

    // レコーダー削除処理APIを呼び出し
    const updateMonthlyRecord = (date) => {
        //利用者の場合
        const userId = state ? state.user_id : localStorage.getItem(localstorage.username)
        let params = {
            homon_month: moment(homonDayDate).format("YYYYMM"),
            user_id: userId,
            input_status: 2 //0:未入力,1:入力中,2:入力完了
        }
        log(params)
        ApiHomonMonthUpdate(params).then(res => {
            let resData = res.data
            // log(res)
            if (res.status === 200) {

                setData(resData)
                log(resData)
                navigate(routerNames.MonthlyRecordConfirmSubmitted)
            } else if (res.status === 201) {

                setData(resData)
            }
        }).catch(err => {

        })
    };

    const monthlyRecordSubmitDeleteConfirm = (date) => {
        let params = {
            homon_jiseki_id: homonJisekiId,
        }
        log(params)
        ApiHomonMonthDelete(params).then(res => {
            let resData = res.data
            if (res.status === 200) {

                setData(resData)
                log(resData)
                initMonthlyRecordConfirm(homonDayDate)
                monthlyRecordSubmitDeleteOnClose()
            } else if (res.status === 201) {

                setData(resData)
            }
        }).catch(err => {

        })

    };

    const disabledDate = (current) => {
        var businessMonth = masterData.taishoGatsudo_info[0].taishogatsudo + "01"
        return dayjs(current) && (dayjs(current) > dayjs().endOf('day') || dayjs(current) < dayjs(businessMonth));
    };
    const JumpToEdit = (item, inactiveFlag) => {
        // 有効期間以外の場合、編集できない
        if (disabledDate(item.homon_day)) {
            return
        }
        if (!inactiveFlag) {
            return
        }
        if (inactiveicon === 2) {
            return
        }
        let toUserId = state !== null ? state.user_id : localStorage.getItem(localstorage.username)
        let toUserName = state !== null ? state.riyosya_name : ""
        navigate(routerNames.VisitRecordInput, { state: { userID: toUserId, businessDay: item.homon_day, riyosya_name: toUserName, from: state !== null ? "manager" : "self" } })
    }
    const JumpToConfirm = (item, inactiveFlag) => {
        if (!inactiveFlag) {
            return
        }
        let toUserId = state !== null ? state.user_id : localStorage.getItem(localstorage.username)
        let toUserName = state !== null ? state.riyosya_name : ""
        navigate(routerNames.MonthlyVisitingRecordConfirmDetail, { state: { userID: toUserId, businessDay: item.homon_day, riyosya_name: toUserName } })
    }
    return (
        <div className={classes.ground}>
            <div className={classes.itemMonthlyHead} style={{ borderLeft: '5px solid #2195D3', fontWeight: 'bold' }}>
                {state !== null ? state.riyosya_name + 'さんの月間訪問実績' : '月間訪問実績'}
            </div>
            <div className={classes.buttonGroup} style={{ marginTop: VH(24), marginBottom: VH(24), width: "100%" }}>
                <div className={classes.itemInput} style={{ float: "left" }}>
                    <div className={classes.itemLeftKey}> <img src={arrowLeft} className={classes.icon} onClick={() => { setHononMonth(0) }} /></div>
                    <div className={classes.itemKey}>
                        <div style={{ textAlign: 'center', marginLeft: VW(16), marginTop: VH(22), marginBottom: VH(18) }}>
                            <img src={
                                subbmit ? inactived : inactivenot
                            } style={{ width: VW(52), height: VH(16), marginRight: VW(8) }} />
                        </div>
                        <div>{
                            homonDayDate !== null &&
                                homonDayDate !== "" &&
                                homonDayDate !== undefined ? moment(homonDayDate).format("YYYY年MM月") : ""
                        }
                        </div>
                    </div>

                    <div className={classes.itemRightKey}><img src={arrowRight} className={classes.icon} onClick={() => { setHononMonth(1) }} /></div>
                </div>
                <div className={classes.buttonGroup2}>
                    <div className={classes.itemDeleteKey}><DeleteForeverRounded style={{ width: VW(24), height: VH(24), color: inactiveicon === 0 ? "#D42020" : "#d9d9d9" }} onClick={() => { monthlyRecordSubmitDeleteCloseOnOpen() }} /></div>
                    <Button style={{ width: VW(240) }} disabled={buttonDisabled} onClick={updateMonthlyRecord}>提出</Button>
                </div>
            </div>
            <div>
                <div className={classes.itemVisitOne}>
                    <div style={{ width: "100%", height: VH(74), background: '#27303D' }}>
                        <div style={{ width: "100%", height: VH(74), left: VW(0), top: VH(0), background: '#27303D', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
                            <div style={{ width: VW(40), alignSelf: 'stretch', paddingLeft: VW(8), paddingRight: VW(8), paddingTop: VH(4), paddingBottom: VH(4), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF' }} sx={{ color: "#fff" }} key={checkAll} indeterminate={indeterminate} checked={checkAll} onChange={onCheckAllChange} />
                                </div>
                            </div>
                            <div style={{ width: VW(44), alignSelf: 'stretch', paddingLeft: VW(8), paddingRight: VW(8), paddingTop: VH(4), paddingBottom: VH(4), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                <div className={classes.itemHeadBody}>日付</div>
                            </div>
                            <div style={{ width: VW(72), alignSelf: 'stretch', paddingLeft: VW(8), paddingRight: VW(8), paddingTop: VH(4), paddingBottom: VH(4), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                <div className={classes.itemHeadBody}>休暇区分</div>
                            </div>
                            <div style={{ width: VW(120), alignSelf: 'stretch', paddingLeft: VW(8), paddingRight: VW(8), paddingTop: VH(4), paddingBottom: VH(4), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                <div className={classes.itemHeadBody}>訪問先</div>
                            </div>
                            <div style={{ width: VW(72), alignSelf: 'stretch', paddingLeft: VW(8), paddingRight: VW(8), paddingTop: VH(4), paddingBottom: VH(4), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
                                <div style={{ color: 'white', fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '700', lineHeight: VH(16.80), wordWrap: 'break-word' }}>定時時間<br />時間外</div>
                            </div>
                            <div style={{ flex: '1 1 0', height: VH(74), borderRight: '0.50px #E0E0E0 solid', borderBottom: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'block' }}>
                                <div className={classes.itemHeadBody} style={{ height: VH(32), borderBottom: '0.50px #E0E0E0 solid' }}>
                                    <div style={{ width: "100%", height: VH(32), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '700', wordWrap: 'break-word' }}>交通手段1</div>
                                    </div>
                                </div>
                                <div className={classes.itemHeadBody} style={{ height: VH(42), display: 'flex' }}>
                                    <div style={{ width: "24%", height: VH(42), alignSelf: 'stretch', borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                        <div className={classes.itemHeadBody} >交通<br />機関</div>
                                    </div>
                                    <div style={{ width: "27%", height: VH(42), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '700', wordWrap: 'break-word' }}>出発地</div>
                                    </div>
                                    <div style={{ width: "27%", height: VH(42), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className={classes.itemHeadBody} >到着地</div>
                                    </div>
                                    <div style={{ width: "22%", height: VH(42), flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className={classes.itemHeadBody} >料金</div>
                                    </div>

                                </div>
                            </div>

                            <div style={{ flex: '1 1 0', height: VH(74), borderRight: '0.50px #E0E0E0 solid', borderBottom: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'block' }}>
                                <div className={classes.itemHeadBody} style={{ height: VH(32), borderBottom: '0.50px #E0E0E0 solid' }}>
                                    <div style={{ width: "100%", height: VH(32), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '700', wordWrap: 'break-word' }}>交通手段2</div>
                                    </div></div>
                                <div className={classes.itemHeadBody} style={{ height: VH(42), display: 'flex' }}>
                                    <div style={{ width: "24%", height: VH(42), alignSelf: 'stretch', borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                        <div className={classes.itemHeadBody} >交通<br />機関</div>
                                    </div>
                                    <div style={{ width: "27%", height: VH(42), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className={classes.itemHeadBody} style={{ textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '700', wordWrap: 'break-word' }}>出発地</div>
                                    </div>
                                    <div style={{ width: "27%", height: VH(42), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className={classes.itemHeadBody} >到着地</div>
                                    </div>
                                    <div style={{ width: "22%", height: VH(42), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className={classes.itemHeadBody} >料金</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {data && data?.homon_month_data?.map((item, index) => {
                    let backgroundValue = item.background;
                    let holidayBackground = item.holidayBackground;
                    let homonJiseki_Id = 0;
                    if (item.day_homonjiseki.length == 0) {
                        return (
                            <div key={index} className={classes.rowClass}>
                                <div style={{ width: "100%", justifyContent: 'flex-start', alignItems: 'flex-start', background: backgroundValue, display: 'flex' }}>
                                    <div style={{ width: VW(40), height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Checkbox value={homonJiseki_Id} onChange={(event) => changeHomonJisekiId(event, item)} checked={item.checked} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: VW(44), height: VH(84), alignSelf: 'stretch', borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                        <div>
                                            <div style={{ textAlign: 'center', color: holidayBackground, fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '400', wordWrap: 'break-word' }}>{item.dayNumber}<br />{item.weekFlag}</div>
                                            <div style={{ width: VW(24), height: VH(24) }}>
                                                <EditRounded style={{ color: inactiveicon === 0 ? "#2095D4" : "#d9d9d9" }} className={classes.icon} onClick={() => { JumpToEdit(item, item.inactiveFlag) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: VW(72), height: VH(84), borderRight: '0.50px #E0E0E0 solid', paddingLeft: VW(8), paddingRight: VW(8), papaddingTop: VH(4), paddingBottom: VH(4), flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                        <div style={{ textAlign: 'center', color: holidayBackground, fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '400', wordWrap: 'break-word' }}>{item.holidayBody}</div>
                                    </div>
                                    <div style={{ width: VW(120), height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, textOverflow: 'ellipsis' }} />
                                    <div style={{ width: VW(72), height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10 }} />

                                    <div style={{ display: "flex", width: "68%", flex: "1 1 0" }}>
                                        <div style={{ width: "12%", height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }} />
                                        <div style={{ width: "13.5%", height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }} />
                                        <div style={{ width: "13.5%", height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }} />
                                        <div style={{ width: "12%", height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }} />
                                        <div style={{ width: "12%", height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }} />
                                        <div style={{ width: "13.5%", height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }} />
                                        <div style={{ width: "13.5%", height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }} />
                                        <div style={{ width: "11%", height: VH(84), flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10 }} />
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (item.day_homonjiseki.length == 1) {
                        let homonJisekiRecordId = item.day_homonjiseki[0].homon_jiseki_id;
                        let homonSakiOne = item.day_homonjiseki[0].homon_saki === 0 ? "" : item.day_homonjiseki[0].homon_saki;
                        let teijiJikanOne = item.day_homonjiseki[0].teiji_jikan;
                        let jikangaiOne = item.day_homonjiseki[0].jikangai;
                        let kotsuShudanOneKotsukikan = "";
                        let kotsuShudanOneShuppatsuIc = "";
                        let kotsuShudanOneTochakuIc = "";
                        let kotsuShudanOneRyokin = "";
                        let kotsuShudanTwoKotsukikan = "";
                        let kotsuShudanTwoShuppatsuIc = "";
                        let kotsuShudanTwoTochakuIc = "";
                        let kotsuShudanTwoRyokin = "";
                        return (
                            <div key={index} className={classes.rowClass}>
                                <div style={{ width: "100%", justifyContent: 'flex-start', alignItems: 'flex-start', background: backgroundValue, display: 'flex' }}>
                                    {/* <div style={{ width: VW(40), height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                            <div style={{ borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                                <div style={{ width: VW(24), height: VH(24) }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Checkbox value={homonJisekiRecordId} onChange={(event) => changeHomonJisekiId(event, item)} checked={item.checked} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div style={{ width: VW(40), height: VH(84), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Checkbox value={homonJisekiRecordId} onChange={(event) => changeHomonJisekiId(event, item)} checked={item.checked} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: VW(44), height: VH(84), alignSelf: 'stretch', borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                        <div>
                                            <div style={{ textAlign: 'center', color: holidayBackground, fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '400', wordWrap: 'break-word' }}>{item.dayNumber}<br />{item.weekFlag}</div>
                                            <div style={{ width: VW(24), height: VH(24) }}>
                                                <EditRounded style={{ color: inactiveicon === 0 ? "#2095D4" : "#d9d9d9" }} className={classes.icon} onClick={() => { JumpToEdit(item, item.inactiveFlag) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: VW(72), height: VH(84), borderRight: '0.50px #E0E0E0 solid', paddingLeft: VW(8), paddingRight: VW(8), papaddingTop: VH(4), paddingBottom: VH(4), flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                        <div style={{ textAlign: 'center', color: holidayBackground, fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '400', wordWrap: 'break-word' }}>{item.holidayBody}</div>
                                    </div>
                                    <div className={classes.itemTextOne} style={{ width: VW(120), color: '#2095D4', textDecoration: 'underline', display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => { JumpToConfirm(item, item.inactiveFlag) }} ><p>{homonSakiOne}</p></div>
                                    <div className={classes.itemTextOne} style={{ width: VW(72), }} ><p>{teijiJikanOne + "時間"}<br />{jikangaiOne + "時間"}</p></div>
                                    <div style={{ flex: '1 1 0', width: "100%", height: VH(84), flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'block' }}>
                                        {
                                            item.day_homonjiseki[0].kotsukikanList.map((itemkotsu, index) => {
                                                let itembackground = (index == 0) || (index == 1) ? 'white' : '#F4F5F9'
                                                if ((index + 1) % 2 == 0) {
                                                    kotsuShudanOneKotsukikan = item.day_homonjiseki[0].kotsukikanList[index - 1].kotsukikan_code;
                                                    kotsuShudanOneShuppatsuIc = item.day_homonjiseki[0].kotsukikanList[index - 1].shuppatsu_ic;
                                                    kotsuShudanOneTochakuIc = item.day_homonjiseki[0].kotsukikanList[index - 1].tochaku_ic;
                                                    kotsuShudanOneRyokin = item.day_homonjiseki[0].kotsukikanList[index - 1].unchin_kosoku_ryokin;
                                                    kotsuShudanTwoKotsukikan = item.day_homonjiseki[0].kotsukikanList[index].kotsukikan_code == null ? '' : item.day_homonjiseki[0].kotsukikanList[index].kotsukikan_code;
                                                    kotsuShudanTwoShuppatsuIc = item.day_homonjiseki[0].kotsukikanList[index].shuppatsu_ic == null ? '' : item.day_homonjiseki[0].kotsukikanList[index].shuppatsu_ic;
                                                    kotsuShudanTwoTochakuIc = item.day_homonjiseki[0].kotsukikanList[index].tochaku_ic == null ? '' : item.day_homonjiseki[0].kotsukikanList[index].tochaku_ic;
                                                    kotsuShudanTwoRyokin = item.day_homonjiseki[0].kotsukikanList[index].unchin_kosoku_ryokin == null ? '' : item.day_homonjiseki[0].kotsukikanList[index].unchin_kosoku_ryokin;

                                                    return (
                                                        <div key={index} style={{ width: "100%", height: VH(42), display: 'flex', backgroundColor: itembackground }} >
                                                            <div className={classes.newItem} style={{ width: "12%" }} ><p style={{ textAlign: "center", verticalAlign: "middle" }}>{kotsuShudanOneKotsukikan}</p></div>
                                                            <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{kotsuShudanOneShuppatsuIc}</p></div>
                                                            <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{kotsuShudanOneTochakuIc}</p></div>
                                                            <div className={classes.newItem} style={{ width: "12%" }} ><p style={{}}>{kotsuShudanOneRyokin}</p></div>
                                                            <div className={classes.newItem} style={{ width: "12%" }} ><p style={{ textAlign: "center", verticalAlign: "middle" }}>{kotsuShudanTwoKotsukikan}</p></div>
                                                            <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{kotsuShudanTwoShuppatsuIc}</p></div>
                                                            <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', }}>{kotsuShudanTwoTochakuIc}</p></div>
                                                            <div className={classes.newItem} style={{ width: "11%", textAlign: 'center', flexDirection: 'column' }} ><p>{kotsuShudanTwoRyokin}</p></div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (item.day_homonjiseki.length == 2) {
                        let homonJisekiRecordId = item.day_homonjiseki[0].homon_jiseki_id + ',' + item.day_homonjiseki[1].homon_jiseki_id;
                        let homonSakiOne = item.day_homonjiseki[0].homon_saki === 0 ? "" : item.day_homonjiseki[0].homon_saki;
                        let homonSakiTwo = item.day_homonjiseki[1].homon_saki === 0 ? "" : item.day_homonjiseki[1].homon_saki;
                        let teijiJikanOne = item.day_homonjiseki[0].teiji_jikan;
                        let jikangaiOne = item.day_homonjiseki[0].jikangai;
                        let teijiJikanTwo = item.day_homonjiseki[1].teiji_jikan;
                        let jikangaiTwo = item.day_homonjiseki[1].jikangai;
                        let homonJiseki_IdOne = item.day_homonjiseki[0].homon_jiseki_id;
                        let homonJiseki_IdTwo = item.day_homonjiseki[1].homon_jiseki_id;

                        let homonOnekotsuShudanOneKotsukikan, homonTwokotsuShudanOneKotsukikan = "";
                        let homonOnekotsuShudanOneShuppatsuIc, homonTwokotsuShudanOneShuppatsuIc = "";
                        let homonOnekotsuShudanOneTochakuIc, homonTwokotsuShudanOneTochakuIc = "";
                        let homonOnekotsuShudanOneRyokin, homonTwokotsuShudanOneRyokin = "";
                        let homonOnekotsuShudanTwoKotsukikan, homonTwokotsuShudanTwoKotsukikan = "";
                        let homonOnekotsuShudanTwoShuppatsuIc, homonTwokotsuShudanTwoShuppatsuIc = "";
                        let homonOnekotsuShudanTwoTochakuIc, homonTwokotsuShudanTwoTochakuIc = "";
                        let homonOnekotsuShudanTwoRyokin, homonTwokotsuShudanTwoRyokin = "";

                        return (

                            <div key={index} className={classes.rowClass}>
                                <div style={{ width: "100%", justifyContent: 'flex-start', alignItems: 'flex-start', background: backgroundValue, display: 'flex' }}>
                                    {/* <div style={{ width: VW(40), height: VH(168), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                            <div style={{ borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                                <div style={{ width: VW(24), height: VH(24) }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Checkbox value={homonJisekiRecordId} onChange={(event) => changeHomonJisekiId(event, item)} checked={item.checked} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div style={{ width: VW(40), height: VH(168), borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Checkbox value={homonJisekiRecordId} onChange={(event) => changeHomonJisekiId(event, item)} checked={item.checked} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: VW(44), height: VH(168), alignSelf: 'stretch', borderRight: '0.50px #E0E0E0 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                        <div>
                                            <div style={{ textAlign: 'center', color: holidayBackground, fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '400', wordWrap: 'break-word' }}>{item.dayNumber}<br />{item.weekFlag}</div>
                                            <div style={{ width: VW(24), height: VH(24) }}>
                                                <EditRounded style={{ color: inactiveicon === 0 ? "#2095D4" : "#d9d9d9" }} className={classes.icon} onClick={() => { JumpToEdit(item, item.inactiveFlag) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: VW(72), height: VH(168), borderRight: '0.50px #E0E0E0 solid', paddingLeft: VW(8), paddingRight: VW(8), papaddingTop: VH(4), paddingBottom: VH(4), flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                                        <div style={{ textAlign: 'center', color: holidayBackground, fontSize: 14, fontFamily: 'Noto Sans', fontWeight: '400', wordWrap: 'break-word' }}>{item.holidayBody}</div>
                                    </div>
                                    <div style={{ flex: '1 1 0', width: "100%", height: VH(168), flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'block' }}>
                                        <div style={{ width: "100%", height: VH(84), display: 'flex' }} >
                                            <div className={classes.itemText} style={{ width: VW(120), height: VH(84), color: '#2095D4', textDecoration: 'underline' }} onClick={() => { JumpToConfirm(item, item.inactiveFlag) }} ><p>{homonSakiOne}</p></div>
                                            <div className={classes.itemText} style={{ width: VW(72), height: VH(84) }} ><p>{teijiJikanOne + "時間"}<br />{jikangaiOne + "時間"}</p></div>
                                            <div style={{ flex: '1 1 0', width: "100%", height: VH(84), flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'block' }}>
                                                {
                                                    item.day_homonjiseki[0].kotsukikanList.map((itemkotsu, index) => {
                                                        let itembackground = (index == 0) || (index == 1) ? 'white' : '#F4F5F9'
                                                        if ((index + 1) % 2 == 0) {
                                                            homonOnekotsuShudanOneKotsukikan = item.day_homonjiseki[0].kotsukikanList[index - 1].kotsukikan_code;
                                                            homonOnekotsuShudanOneShuppatsuIc = item.day_homonjiseki[0].kotsukikanList[index - 1].shuppatsu_ic;
                                                            homonOnekotsuShudanOneTochakuIc = item.day_homonjiseki[0].kotsukikanList[index - 1].tochaku_ic;
                                                            homonOnekotsuShudanOneRyokin = item.day_homonjiseki[0].kotsukikanList[index - 1].unchin_kosoku_ryokin;
                                                            homonOnekotsuShudanTwoKotsukikan = item.day_homonjiseki[0].kotsukikanList[index].kotsukikan_code == null ? '' : item.day_homonjiseki[0].kotsukikanList[index].kotsukikan_code;
                                                            homonOnekotsuShudanTwoShuppatsuIc = item.day_homonjiseki[0].kotsukikanList[index].shuppatsu_ic == null ? '' : item.day_homonjiseki[0].kotsukikanList[index].shuppatsu_ic;
                                                            homonOnekotsuShudanTwoTochakuIc = item.day_homonjiseki[0].kotsukikanList[index].tochaku_ic == null ? '' : item.day_homonjiseki[0].kotsukikanList[index].tochaku_ic;
                                                            homonOnekotsuShudanTwoRyokin = item.day_homonjiseki[0].kotsukikanList[index].unchin_kosoku_ryokin == null ? '' : item.day_homonjiseki[0].kotsukikanList[index].unchin_kosoku_ryokin;

                                                            return (
                                                                <div key={index} style={{ width: "100%", height: VH(42), display: 'flex', backgroundColor: itembackground }} >
                                                                    <div className={classes.newItem} style={{ width: "12%" }} ><p style={{ textAlign: "center", verticalAlign: "middle" }}>{homonOnekotsuShudanOneKotsukikan}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{homonOnekotsuShudanOneShuppatsuIc}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{homonOnekotsuShudanOneTochakuIc}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "12%" }} ><p>{homonOnekotsuShudanOneRyokin}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "12%" }} ><p style={{ textAlign: "center", verticalAlign: "middle" }}>{homonOnekotsuShudanTwoKotsukikan}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{homonOnekotsuShudanTwoShuppatsuIc}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{homonOnekotsuShudanTwoTochakuIc}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "11%", textAlign: 'center', flexDirection: 'column' }} ><p>{homonOnekotsuShudanTwoRyokin}</p></div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", height: VH(84), display: 'flex' }} >
                                            <div className={classes.itemText} style={{ width: VW(120), height: VH(84), color: '#2095D4', textDecoration: 'underline' }} onClick={() => { JumpToConfirm(item, item.inactiveFlag) }} ><p>{homonSakiTwo}</p></div>
                                            <div className={classes.itemText} style={{ width: VW(72), height: VH(84), }} ><p>{teijiJikanTwo + "時間"}<br />{jikangaiTwo + "時間"}</p></div>
                                            <div style={{ flex: '1 1 0', width: "100%", height: VH(84), flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'block' }}>
                                                {
                                                    item.day_homonjiseki[1].kotsukikanList.map((itemkotsu, index) => {
                                                        let itembackground = (index == 0) || (index == 1) ? 'white' : '#F4F5F9'
                                                        if ((index + 1) % 2 == 0) {
                                                            homonTwokotsuShudanOneKotsukikan = item.day_homonjiseki[1].kotsukikanList[index - 1].kotsukikan_code;
                                                            homonTwokotsuShudanOneShuppatsuIc = item.day_homonjiseki[1].kotsukikanList[index - 1].shuppatsu_ic;
                                                            homonTwokotsuShudanOneTochakuIc = item.day_homonjiseki[1].kotsukikanList[index - 1].tochaku_ic;
                                                            homonTwokotsuShudanOneRyokin = item.day_homonjiseki[1].kotsukikanList[index - 1].unchin_kosoku_ryokin;
                                                            homonTwokotsuShudanTwoKotsukikan = item.day_homonjiseki[1].kotsukikanList[index].kotsukikan_code == null ? '' : item.day_homonjiseki[1].kotsukikanList[index].kotsukikan_code;
                                                            homonTwokotsuShudanTwoShuppatsuIc = item.day_homonjiseki[1].kotsukikanList[index].shuppatsu_ic == null ? '' : item.day_homonjiseki[1].kotsukikanList[index].shuppatsu_ic;
                                                            homonTwokotsuShudanTwoTochakuIc = item.day_homonjiseki[1].kotsukikanList[index].tochaku_ic == null ? '' : item.day_homonjiseki[1].kotsukikanList[index].tochaku_ic;
                                                            homonTwokotsuShudanTwoRyokin = item.day_homonjiseki[1].kotsukikanList[index].unchin_kosoku_ryokin == null ? '' : item.day_homonjiseki[1].kotsukikanList[index].unchin_kosoku_ryokin;

                                                            return (
                                                                <div key={index} style={{ width: "100%", height: VH(42), display: 'flex', backgroundColor: itembackground }} >
                                                                    <div className={classes.newItem} style={{ width: "12%" }} ><p style={{ textAlign: "center", verticalAlign: "middle" }}>{homonTwokotsuShudanOneKotsukikan}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{homonTwokotsuShudanOneShuppatsuIc}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{homonTwokotsuShudanOneTochakuIc}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "12%" }} ><p>{homonTwokotsuShudanOneRyokin}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "12%" }} ><p style={{ textAlign: "center", verticalAlign: "middle" }}>{homonTwokotsuShudanTwoKotsukikan}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{homonTwokotsuShudanTwoShuppatsuIc}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "13.5%" }} ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{homonTwokotsuShudanTwoTochakuIc}</p></div>
                                                                    <div className={classes.newItem} style={{ width: "11%", textAlign: 'center', flexDirection: 'column' }} ><p>{homonTwokotsuShudanTwoRyokin}</p></div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )

                    }

                })}

            </div>

            <MonthlyRecordSubmitDelete
                open={monthlyRecordSubmitDeleteOpen}
                onClose={monthlyRecordSubmitDeleteOnClose}
                monthlyRecordSubmitDeleteConfirm={monthlyRecordSubmitDeleteConfirm}
                homonJisekiDay={homonJisekiDay}
            />
        </div >
    )
}

MonthlyRecordConfirm.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(MonthlyRecordConfirm);