// import { message, Upload } from 'antd';
import { withStyles } from "@mui/styles";
import { useDropzone } from 'react-dropzone';
import { Button } from 'antd';
import fileUpload from '../icon/FileUpload.svg';
import React, { useState, useEffect } from "react";
import { VW, VH } from "../../componets/function/sizeAdapter"

// const { Dragger } = Upload;

const styles = (theme) => ({
    uploadButton: {
        width: VW(360),
        height: VH(50),
        borderRadius: '4px',
        border: '1px solid #DDD',
        backgroundColor: '#FFF',
        fontSize: VW(16),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: VW(324)
    },
    sectionBlock: {
        width: '100%',
        backgroundColor: '#F8FAFB',
    },
    itemRightKey: {
        width: "100%",
        height: VH(50),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        justifyContent: 'center',
        backgroundColor: "#F8FAFB",
        display: "flex",
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(358),
        height: VH(50),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        fontWeight: 'bold',
        border: `1px solid #E0E0E0`
    },
    itemUpload: {
        width: VW(792),
        height: VH(178),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        justifyContent: 'center',
        alignItems: "center",
        backgroundColor: "#F8FAFB",
        borderLeft: '2px dotted #2095D4',
        borderRight: '2px dotted #2095D4',
        borderTop: '2px dotted #2095D4',
        borderBottom: '2px dotted #2095D4',
    }
});
function UploadFiles(props) {
    const {
        classes,
        acceptedFiles,
        getRootProps,
        getInputProps
    } = props



    if (acceptedFiles.length > 0) {
        var files = acceptedFiles.map(file => (
            <div key={file.path} style={{ height: '25px', fontSize: '16px' }}>
                {file.path}
            </div>
        ));
    }


    return (
        <>
            <section className={classes.sectionBlock}>
                <div
                    {...getRootProps({ className: 'dropzone disabled' })}>
                    <input {...getInputProps()} accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />

                    <div className={classes.itemRightKey} style={{ justifyContent: 'center', marginTop: VH(24), height: VH(45) }}>
                        <img src={fileUpload} style={{ width: VW(32), height: VH(32) }} />
                    </div>
                    <div className={classes.itemRightKey} style={{ height: VH(40) }}>
                        ここに .xlsx ファイルをドラッグ＆ドロップ
                    </div>
                    <div className={classes.itemRightKey} style={{ justifyContent: 'center', marginBottom: VH(24), height: VH(45) }}>
                        <Button className={classes.buttonGroup1}>ファイルを選択する</Button>
                    </div>


                </div>
            </section>
            <div>{files}</div>
        </>
    )
}

UploadFiles.defaultProps = {

}


export default withStyles(styles, { withTheme: true })(UploadFiles);