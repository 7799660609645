// 1280 * 720
function VW(width) {
    const vw = (width / 1133) * 100;
    return `${vw}vw`;
}

function VH(height) {
    const vh = (height / 720) * 100;
    return `${vh}vh`;
}

export { VW, VH };
