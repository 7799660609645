import React, { useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import { localstorage, routerNames } from '../../wordsFile/wordsFile'
import { getSession, getUserPool, getCurrSession } from "../../network/UserPool";
import { VW, VH } from "../../componets/function/sizeAdapter"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box, MenuItem, Select } from "@mui/material";
import { Modal, Input, Button, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import locale from "antd/es/date-picker/locale/ja_JP";
import { CalendarToday, CloseRounded } from "@mui/icons-material";
import VisitView from "./VisitView";
import { ApiHomonJisekiGetInfo } from "../../network/api";
import log from "../../componets/function/log";
import { stringToObejct } from "../../componets/function/JsonUtil";
import { useNavigate } from "react-router-dom";
import { kyuka_kubunOption } from "../../wordsFile/selectItem";
import { useLocation } from "react-router-dom";
import { ApiVisitUpdateInfo, ApiHomonMonthConfirmInfo } from "../../network/api";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import DeleteDialog from "../../componets/dialog/DeleteDialog";
import ChangeDateDialog from "../../componets/dialog/ChangeDateDialog";
import moment from "moment";
import Common_successAlert from "../../componets/common/Common_successAlert";
const styles = (theme) => ({
    ground: {
        paddingTop: VH(16),
        paddingBottom: VH(24),
        paddingLeft: VW(24),
        paddingRight: VW(24),
        "& .ant-picker .ant-picker-input>input": {
            fontSize: VW(12)
        },
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
    itemText: {
        color: "#27303D",
        fontSize: VW(12),
        fontWeight: 700,
        width: VW(117),
        height: VH(72),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        borderTop: '1px solid #E0E0E0',
        borderBottom: '1px solid #E0E0E0',
    },
    itemInput: {
        width: VW(160),
        height: VH(72),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        fontSize: VW(12),
        borderTop: '1px solid #E0E0E0',
        borderBottom: '1px solid #E0E0E0',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        '& > Button': {
            width: VW(120),
            backgroundColor: "#2095D4",
            color: "#fff",
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            border: `1px solid #2195D3`,
            // '&:hover': {
            //     backgroundColor: '#2195D3',
            // },
        },
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            width: VW(400),
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    },
    itemMonthlyHead: {
        color: "#27303D",
        fontSize: VW(24),
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        paddingLeft: VW(10),
        marginBottom: VH(25),
        // marginRight: VW(24)
    },
    container: {
        position: "relative",
        width: "140px",
        height: "56px",
        marginLeft: "16px",
    },
    topLeft: {
        position: "absolute",
        top: 0,
        left: 0,
    },
    bottomRight: {
        position: "absolute",
        bottom: 0,
        right: 0,
    }
});


let visitItem = {
    "homon_jiseki_id": null,
    "user_id": null,
    "homon_day": null,
    "homon_yobi": null,
    "yobi": null,
    "jigyo_kubun": null,
    "kyuka_kubun": null,
    "homon_saki": null,
    "gyomu_shurui": "1",
    "sagyo_basho": null,
    "sagyo_basho_sonota": null,
    "sagyo_naiyo": null,
    "teiji_jikan": null,
    "jikangai": null,
    "delete_flag": false,
    "expand": 0,
    "name": null,
    "homon_shuppatsu": "自宅",
    "homon_tochaku_sonota": "",
    "homon_tochaku": "自宅",
    "homon_shuppatsu_sonota": "",
    "kotsukikanList": [
        // {
        //     "kotsukikan_jiseki_id": null,
        //     "homon_jiseki_id": null,
        //     "ofuku": 1,
        //     "kotsukikan_code": null,
        //     "shuppatsu_ic": null,
        //     "tochaku_ic": null,
        //     "unchin_kosoku_ryokin": null,
        //     "delete_flag": false,
        //     "expand": 0
        // }
    ],
    "supportList": [
        // {
        //     "support_contents_id": null,
        //     "homon_jiseki_id": null,
        //     "gako_code": null,
        //     "sienkubun_code": null,
        //     "kyoka_code": null,
        //     "gakunen_code": null,
        //     "requester": null,
        //     "ict_kankyo_code1": null,
        //     "ict_kankyo_code2": null,
        //     "software_code1": null,
        //     "software_code2": null,
        //     "status": null,
        //     "support": null,
        //     "delete_flag": false,
        //     "expand": 0
        // }
    ]
}

function VisitRecordInput(props) {
    const {
        classes,
        setSelectFlag,
        visitData,
        setVisitData,
        isUpdate,
        setIsUpdate,
        updateVisitData,
        masterData,
        updateVisitSupportData,
        updateVisitRouteData,
        refreshVisit,
        setRefreshVisit,
        visitDataCompared,
        setVisitDataCompared,
        menuFlag

    } = props
    const [homonDayDate, setHomonDayDate] = useState('')
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;
    const [userID, setUserId] = useState(state ? state.userID : localStorage.getItem(localstorage.username))
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    const [startOfuku, setStartOfuku] = useState({ 'ostartone': '', 'fukustartone': '', 'ostarttwo': '', 'fukustarttwo': '' })
    const [endOfuku, setEndOfuku] = useState({ 'oendone': '', 'fukuendone': '', 'oendtwo': '', 'fukuendtwo': '' })

    const [isNullData, setIsNullData] = useState(false)

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [deleteDialogValue, setDeleteDialogValue] = useState()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [distance, setDistance] = useState("0.0")
    const deleteDialogOnClose = () => {
        setDeleteDialogOpen(false)
    }
    const deleteDialogOnOpen = (index) => {
        if (visitData?.homon_jiseki?.length <= 1) {
            return
        }
        setDeleteDialogValue(index)
        setDeleteDialogOpen(true)
    }
    const deleteDialogConfirm = () => {
        deleteVisitData(deleteDialogValue)
    }


    const [changeDateDialogOpen, setChangeDateDialogOpen] = useState(false)
    const [changeDateDialogValue, setChangeDateDialogValue] = useState()
    const changeDateDialogOnClose = () => {
        setChangeDateDialogOpen(false)
    }
    const changeDateDialogOnOpen = (value, dateString) => {
        setChangeDateDialogValue(value)
        setChangeDateDialogOpen(true)
    }
    const changeDateDialogConfirm = () => {
        changeHomonDay(changeDateDialogValue)
    }


    useEffect(() => {
        document.getElementById('father_Block').scrollTop = 0;
        getCurrSession()
        if (state) {
            // setSelectFlag(routerNames.MonthlyVisitingRecord)
            setSelectFlag(routerNames.VisitRecordInput)
            // setUserId(state.userId)
            setHomonDayDate(dayjs(state.businessDay))
            getMonthStatus(dayjs(state.businessDay))
            // if (Object.keys(visitData) == 0) {
            getVisitInfo(dayjs(state.businessDay))
            // }
        }
        // else if (localStorage.getItem(localstorage.businessDay)) {
        //     setSelectFlag(routerNames.VisitRecordInput)
        //     setHomonDayDate(dayjs(localStorage.getItem(localstorage.businessDay)))
        //     getMonthStatus(dayjs(localStorage.getItem(localstorage.businessDay)))
        //     if (Object.keys(visitData) == 0) {
        //         getVisitInfo(dayjs(localStorage.getItem(localstorage.businessDay)))
        //     }
        // }
        else {
            // setUserId(localStorage.getItem(localstorage.username))
            setSelectFlag(routerNames.VisitRecordInput)
            setHomonDayDate(dayjs())
            getMonthStatus(dayjs())
            // if (Object.keys(visitData) == 0) {
            getVisitInfo(dayjs())
            // }
        }

    }, [setSelectFlag, state])


    // useEffect(() => {
    //     if (refreshVisit) {
    //         document.getElementById('father_Block').scrollTop = 0;
    //         if (localStorage.getItem(localstorage.businessDay)) {
    //             setSelectFlag(routerNames.VisitRecordInput)
    //             setHomonDayDate(dayjs(localStorage.getItem(localstorage.businessDay)))
    //             getVisitInfo(dayjs(localStorage.getItem(localstorage.businessDay)))
    //             getMonthStatus(dayjs(localStorage.getItem(localstorage.businessDay)))

    //         } else {
    //             setSelectFlag(routerNames.VisitRecordInput)
    //             setHomonDayDate(dayjs())
    //             getVisitInfo(dayjs())
    //             getMonthStatus(dayjs())
    //         }
    //     }
    //     setRefreshVisit(false)

    // }, [refreshVisit])
    const getMonthStatus = (date) => {
        //利用者の場合
        const user_id = state ? state.user_id : localStorage.getItem(localstorage.username)
        const newdateString = date.format("YYYYMM")
        let params = {
            user_id: user_id,
            homon_month: newdateString
        }
        //　月間訪問提出状況を取得する
        ApiHomonMonthConfirmInfo(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                if (resData.homon_month_status.input_status == 2 && !state) {
                    setButtonDisabled(true)
                } else {
                    setButtonDisabled(false)
                }

            }
        }).catch(err => {

        })
    }
    const changeHomonDay = (value) => {
        changeDateDialogOnClose()
        // localStorage.setItem(localstorage.businessDay, value)
        setHomonDayDate(value)
        getVisitInfo(value)
        getMonthStatus(value)
    };

    const deleteVisitData = (index) => {
        setDeleteDialogOpen(false)
        if (visitData?.homon_jiseki?.length <= 1) {
            return
        }

        visitData?.homon_jiseki?.splice(index, 1)
        for (let index = 0; index < visitData.homon_jiseki.length; index++) {
            visitData.homon_jiseki[index].name = "訪問先 " + (index + 1)
            visitData.homon_jiseki[index].homon_shuppatsu = "自宅" //訪問先1
            visitData.homon_jiseki[index].homon_tochaku = "自宅"
        }
        setVisitData({ ...visitData })
    };
    const addVisitData = (index) => {
        if (visitData?.homon_jiseki?.length >= 2) {
            return
        }

        let tempList = visitData.homon_jiseki
        visitItem.kyuka_kubun = visitData.homon_jiseki[0].kyuka_kubun
        if (visitItem.kyuka_kubun == "1" || visitItem.kyuka_kubun == "4") {
            visitItem.supportList = []
            visitItem.kotsukikanList = []
            visitItem.gyomu_shurui = null
        } else {
            visitItem.gyomu_shurui = "1"
        }
        tempList.push(visitItem)
        for (let index = 0; index < tempList.length; index++) {
            tempList[index].name = "訪問先 " + (index + 1)
            tempList[index].homon_day = homonDayDate.format("YYYY-MM-DD")
            tempList[index].user_id = state ? state.userID : localStorage.getItem(localstorage.username)
            // if (index === 0) {
            //     tempList[index].homon_shuppatsu = "自宅" //訪問先1
            //     tempList[index].homon_tochaku = "自宅"
            // } else if (index === 1) {
            //     tempList[index].homon_shuppatsu = "自宅" //訪問先2
            //     tempList[index].homon_tochaku = "自宅"
            // }
        }
        setVisitData(JSON.parse(JSON.stringify({ "homon_jiseki": tempList })))


    };

    const getDistance = (visitData) => {
        let distance = 0.0
        for (let index = 0; index < visitData?.homon_jiseki?.length; index++) {
            if (visitData?.homon_jiseki[index]?.day_distance) {
                distance += parseFloat(visitData?.homon_jiseki[index]?.day_distance)
            }
        }
        if (distance > 1000) {
            distance = 999.9
        }

        setDistance(distance > 0 ? distance.toFixed(1).toString() : "0.0")
    };
    const getVisitInfo = (date) => {
        let params = {
            user_id: state ? state.userID : localStorage.getItem(localstorage.username),
            homon_day: date.format("YYYY-MM-DD")
        }
        ApiHomonJisekiGetInfo(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                setIsNullData(false)
                for (let index = 0; index < resData?.homon_jiseki?.length; index++) {
                    resData.homon_jiseki[index].name = "訪問先 " + (index + 1)
                    resData.homon_jiseki[index].homon_day = date.format("YYYY-MM-DD")
                    resData.homon_jiseki[index].user_id = state ? state.userID : localStorage.getItem(localstorage.username)
                }
                setVisitDataCompared({ ...JSON.parse(JSON.stringify(resData)) })
                setVisitData({ ...JSON.parse(JSON.stringify(resData)) })
                getDistance(JSON.parse(JSON.stringify(resData)))
            } else if (res.status === 201) {
                if (isNullData) {
                    for (let index = 0; index < visitData?.homon_jiseki?.length; index++) {
                        // visitData.homon_jiseki[index].name = "訪問先 " + (index + 1)
                        visitData.homon_jiseki[index].homon_day = date.format("YYYY-MM-DD")
                        // visitData.homon_jiseki[index].user_id = state ? state.userID : localStorage.getItem(localstorage.username)
                    }
                    // setVisitDataCompared({ ...JSON.parse(JSON.stringify(resData.detail)) })
                    setVisitData({ ...visitData })
                    getDistance(visitData)
                } else {
                    for (let index = 0; index < resData?.detail.homon_jiseki?.length; index++) {
                        resData.detail.homon_jiseki[index].name = "訪問先 " + (index + 1)
                        resData.detail.homon_jiseki[index].homon_day = date.format("YYYY-MM-DD")
                        resData.detail.homon_jiseki[index].user_id = state ? state.userID : localStorage.getItem(localstorage.username)
                    }
                    setVisitDataCompared({ ...JSON.parse(JSON.stringify(resData.detail)) })
                    setVisitData({ ...JSON.parse(JSON.stringify(resData.detail)) })
                    getDistance(JSON.parse(JSON.stringify(resData.detail)))
                }
                setIsNullData(true)
                // if (isUpdate) {
                //     for (let index = 0; index < resData?.detail.homon_jiseki?.length; index++) {
                //         resData.detail.homon_jiseki[index].name = "訪問先 " + (index + 1)
                //         resData.detail.homon_jiseki[index].homon_day = date.format("YYYY-MM-DD")
                //         resData.detail.homon_jiseki[index].user_id = state ? state.userID : localStorage.getItem(localstorage.username)
                //     }
                //     setVisitData({ ...resData.detail })
                // } else {
                // if (localStorage.getItem(localstorage.visitData)) {
                //     let obj = stringToObejct(localStorage.getItem(localstorage.visitData))
                //     for (let index = 0; index < obj?.homon_jiseki?.length; index++) {
                //         obj.homon_jiseki[index].name = "訪問先 " + (index + 1)
                //         obj.homon_jiseki[index].homon_day = date.format("YYYY-MM-DD")
                //         obj.homon_jiseki[index].user_id = state ? state.userID : localStorage.getItem(localstorage.username)
                //         obj.homon_jiseki[index].homon_jiseki_id = null
                //     }
                //     setVisitData({ ...obj })

                // } else {

                // for (let index = 0; index < resData?.detail.homon_jiseki?.length; index++) {
                //     resData.detail.homon_jiseki[index].name = "訪問先 " + (index + 1)
                //     resData.detail.homon_jiseki[index].homon_day = date.format("YYYY-MM-DD")
                //     resData.detail.homon_jiseki[index].user_id = state ? state.userID : localStorage.getItem(localstorage.username)
                // }
                // setVisitDataCompared({ ...JSON.parse(JSON.stringify(resData.detail)) })
                // setVisitData({ ...JSON.parse(JSON.stringify(resData.detail)) })
                // }
                // }

                // setIsUpdate(false)

                //     for (let index = 0; index < resData?.detail.homon_jiseki?.length; index++) {
                //         resData.detail.homon_jiseki[index].name = "訪問先 " + (index + 1)
                //         resData.detail.homon_jiseki[index].homon_day = date.format("YYYY-MM-DD")
                //         resData.detail.homon_jiseki[index].user_id = state ? state.userID : localStorage.getItem(localstorage.username)
                //     }
                //     setVisitData({ ...resData.detail })


            }
        }).catch(err => {

        })
    }
    const subbmit = () => {
        let error = false
        let errorMessage = ""
        let message1 = "・訪問先1の往路 / 復路の交通機関を、それぞれ1つ以上入力してください。\n・交通機関が JR、バス、フェリー、タクシーの場合には、出発地：IC / 駅 / バス停、到着地：IC / 駅 / バス停、高速料金 / 運賃を必ず入力してください。"
        let message2 = "・訪問先1の往路 の交通機関を1つ以上入力してください。\n・訪問先2の往路 / 復路の交通機関を、それぞれ1つ以上入力してください。\n・交通機関が JR、バス、フェリー、タクシーの場合には、出発地：IC / 駅 / バス停、到着地：IC / 駅 / バス停、高速料金 / 運賃を必ず入力してください。"
        if (localStorage.getItem(localstorage.authority_id1) == "1") {
            visitData.homon_jiseki.map((item, index) => {
                if (visitData.homon_jiseki.length == 1) {
                    if (item.kyuka_kubun != "1" && item.kyuka_kubun != "4" && parseInt(item.sagyo_basho) !== 1 && item.sagyo_basho_sonota != "自宅") {

                        if (item.kotsukikanList.length == 0) {
                            error = true
                            errorMessage = message1
                        } else {
                            item.kotsukikanList.map((routeItem, routeIndex) => {
                                if (!routeItem.kotsukikan_code) {
                                    error = true
                                    errorMessage = message1
                                } else {
                                    if (routeItem.kotsukikan_code == "2" || routeItem.kotsukikan_code == "3" || routeItem.kotsukikan_code == "4" || routeItem.kotsukikan_code == "6") {
                                        if (!routeItem.shuppatsu_ic) {
                                            error = true
                                            errorMessage = message1
                                        }
                                        if (!routeItem.unchin_kosoku_ryokin) {
                                            error = true
                                            errorMessage = message1
                                        }
                                        if (!routeItem.tochaku_ic) {
                                            error = true
                                            errorMessage = message1
                                        }
                                    }
                                }
                            })
                        }
                        if (visitData.homon_jiseki[0].kyuka_kubun != "1" && visitData.homon_jiseki[0].kyuka_kubun != "4" && parseInt(visitData.homon_jiseki[0].sagyo_basho) !== 1 && visitData.homon_jiseki[0].sagyo_basho_sonota != "自宅") {
                            var backRoute = false
                            var goRoute = false
                            visitData.homon_jiseki[0].kotsukikanList.map((item, index) => {
                                if (item.ofuku == 2) {
                                    backRoute = true
                                }
                                if (item.ofuku == 1) {
                                    goRoute = true
                                }
                            })
                            if (!backRoute || !goRoute) {
                                error = true
                                errorMessage = message1
                            }
                        }
                    }
                } else {
                    if (item.kyuka_kubun != "1" && item.kyuka_kubun != "4" && parseInt(item.sagyo_basho) !== 1 && item.sagyo_basho_sonota != "自宅") {
                        if (item.kotsukikanList.length == 0) {
                            error = true
                            errorMessage = message2
                        } else {
                            item.kotsukikanList.map((routeItem, routeIndex) => {
                                if (!routeItem.kotsukikan_code) {
                                    error = true
                                    errorMessage = message2
                                } else {
                                    if (routeItem.kotsukikan_code == "2" || routeItem.kotsukikan_code == "3" || routeItem.kotsukikan_code == "4" || routeItem.kotsukikan_code == "6") {
                                        if (!routeItem.shuppatsu_ic) {
                                            error = true
                                            errorMessage = message2
                                        }
                                        if (!routeItem.unchin_kosoku_ryokin) {
                                            error = true
                                            errorMessage = message2
                                        }
                                        if (!routeItem.tochaku_ic) {
                                            error = true
                                            errorMessage = message2
                                        }
                                    }
                                }
                            })
                        }
                        if (visitData.homon_jiseki[0].kyuka_kubun != "1" && visitData.homon_jiseki[0].kyuka_kubun != "4" && parseInt(visitData.homon_jiseki[0].sagyo_basho) !== 1 && visitData.homon_jiseki[0].sagyo_basho_sonota != "自宅") {
                            if (visitData.homon_jiseki[1].kyuka_kubun != "1" && visitData.homon_jiseki[1].kyuka_kubun != "4" && parseInt(visitData.homon_jiseki[1].sagyo_basho) !== 1 && visitData.homon_jiseki[1].sagyo_basho_sonota != "自宅") {
                                var backRoute2 = false
                                var goRoute2 = false
                                visitData.homon_jiseki[1].kotsukikanList.map((item, index) => {
                                    if (item.ofuku == 2) {
                                        backRoute2 = true
                                    }
                                    if (item.ofuku == 1) {
                                        goRoute2 = true
                                    }
                                })
                                if (!backRoute2 || !goRoute2) {
                                    error = true
                                    errorMessage = message2
                                }
                            }
                            var goRoute1 = false
                            visitData.homon_jiseki[0].kotsukikanList.map((item, index) => {
                                if (item.ofuku == 1) {
                                    goRoute1 = true
                                }
                            })
                            if (!goRoute1) {
                                error = true
                                errorMessage = message2
                            }
                        }
                    }
                }
            })
        }
        if (error) {
            setErrorContent(errorMessage)
            setRrrorOpen(true)
            return
        } else {
            if (visitData.homon_jiseki.length > 0) {
                if (visitData.homon_jiseki[0].kyuka_kubun == "1" || visitData.homon_jiseki[0].kyuka_kubun == "4" || visitData.homon_jiseki[0].sagyo_basho == "1" || visitData.homon_jiseki[0].sagyo_basho_sonota == "自宅") {
                    // 往 出発地
                    visitData.homon_jiseki[0]["homon_shuppatsu"] = ""
                    // 複 出発地 
                    visitData.homon_jiseki[0]["homon_shuppatsu_sonota"] = ""
                    // 往 到着地
                    visitData.homon_jiseki[0]["homon_tochaku"] = ""
                    // 複 出発地 
                    visitData.homon_jiseki[0]["homon_tochaku_sonota"] = ""
                } else {
                    // 往 出発地
                    visitData.homon_jiseki[0]["homon_shuppatsu"] = startOfuku.ostartone
                    // 複 出発地 
                    visitData.homon_jiseki[0]["homon_shuppatsu_sonota"] = startOfuku.fukustartone
                    // 往 到着地
                    visitData.homon_jiseki[0]["homon_tochaku"] = endOfuku.oendone
                    // 複 出発地 
                    visitData.homon_jiseki[0]["homon_tochaku_sonota"] = endOfuku.fukuendone
                }
            }

            if (visitData.homon_jiseki.length > 1) {
                if (visitData.homon_jiseki[1].kyuka_kubun == "1" || visitData.homon_jiseki[1].kyuka_kubun == "4" || visitData.homon_jiseki[1].sagyo_basho == "1" || visitData.homon_jiseki[1].sagyo_basho_sonota == "自宅") {
                    // 往 出発地  
                    visitData.homon_jiseki[1]["homon_shuppatsu"] = ""
                    // 複 出発地 
                    visitData.homon_jiseki[1]["homon_shuppatsu_sonota"] = ""
                    // 往 到着地
                    visitData.homon_jiseki[1]["homon_tochaku"] = ""
                    // 複 出発地 
                    visitData.homon_jiseki[1]["homon_tochaku_sonota"] = ""
                } else {
                    // 往 出発地  
                    visitData.homon_jiseki[1]["homon_shuppatsu"] = startOfuku.ostarttwo
                    // 複 出発地 
                    visitData.homon_jiseki[1]["homon_shuppatsu_sonota"] = startOfuku.fukustarttwo
                    // 往 到着地
                    visitData.homon_jiseki[1]["homon_tochaku"] = endOfuku.oendtwo
                    // 複 出発地 
                    visitData.homon_jiseki[1]["homon_tochaku_sonota"] = endOfuku.fukuendtwo
                }
            }

            // return
            ApiVisitUpdateInfo(visitData).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    if (!state) {
                        // localStorage.removeItem(localstorage.visitData)
                        // localStorage.removeItem(localstorage.businessDay)
                    }
                    setVisitData({})
                    // let route = state !== null ? "month" : "visit"
                    // navigate(routerNames.VisitSubmitted, { state: { route: route } })
                    setSuccessOpen(true)
                    setSuccessContent("登録完了しました！本日もお疲れ様でした")
                    getVisitInfo(homonDayDate)


                } else if (res.status === 406) {
                    setErrorContent(resData.detail)
                    setRrrorOpen(true)
                    document.getElementById('father_Block').scrollTop = 0;
                }
            }).catch(err => {

            })
        }



    }

    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#9E9E9E"
        }
    }));

    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };

    const JumpToConfirm = () => {
        let toUserId = state !== null ? state.userID : localStorage.getItem(localstorage.username)
        let toUserName = state !== null ? state.riyosya_name : ""
        let from = state !== null ? state.from : ""
        let route = state !== null ? "month" : "visit"
        navigate(routerNames.VisitRecordConfirm, { state: { data: visitData, userID: toUserId, riyosya_name: toUserName, from: from, route: route } })
    }

    const disabledDate = (current) => {
        var businessMonth = masterData.taishoGatsudo_info[0].taishogatsudo + "01"
        // dayjs() && (dayjs() > moment().endOf('day') || dayjs() < dayjs(businessMonth));
        // Can not select days before today and today
        return current && (current > dayjs().endOf('day') || current < dayjs(businessMonth));
    };


    return (
        <div className={classes.ground} >
            {state != null && state.from === "manager" && <div className={classes.itemMonthlyHead} style={{ borderLeft: '5px solid #2195D3', fontWeight: 'bold' }}>
                {state.riyosya_name + 'さんの月間訪問実績'}
            </div>}
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginBottom={VH(24)}
            />
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                border
                marginBottom={VH(24)}
            />
            <div style={{ width: "100%" }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'space-between',
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <div className={classes.itemText}>訪問日</div>
                        <div className={classes.itemInput}>
                            <div>
                                <DatePicker
                                    suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                    allowClear={<CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>}
                                    style={{ width: VW(144), height: VH(56) }}
                                    onChange={changeDateDialogOnOpen}
                                    placeholder=''
                                    format="YYYY年M月D日"
                                    value={homonDayDate}
                                    locale={locale}
                                    disabled={state ? true : false}
                                    disabledDate={disabledDate}
                                />
                            </div>
                        </div>
                        <div className={classes.itemText}>休暇区分</div>
                        <div className={classes.itemInput}>
                            <Select
                                className={classes.select}
                                style={{ width: "100%", height: VH(56), borderRadius: VW(6), fontSize: VW(12), }}
                                value={Object.keys(visitData) != 0 ? visitData?.homon_jiseki[0]?.kyuka_kubun || "" : ""}
                                label=""
                                onChange={(event) => updateVisitData(event.target.value, 0, 'kyuka_kubun')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) != 0) &&
                                        visitData?.homon_jiseki[0]?.kyuka_kubun !== null &&
                                        visitData?.homon_jiseki[0]?.kyuka_kubun !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {kyuka_kubunOption.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className={classes.container}>
                            <div className={classes.topLeft} style={{ fontWeight: 700, fontSize: 12, color: "#27303D" }}>1日の移動距離</div>
                            <div className={classes.bottomRight}>
                                <div style={{ display: "flex", alignItems: "flex-end" }}>
                                    <div style={{ fontWeight: 700, fontSize: 20, color: "#27303D", alignSelf: "flex-end", }}>
                                        {distance}
                                    </div>
                                    <div style={{ fontWeight: 700, fontSize: 14, color: "#27303D", marginLeft: 4, marginBottom: 3 }}>
                                        Km
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.buttonGroup}  >
                        <Button onClick={subbmit} disabled={buttonDisabled} type="primary">保存</Button>

                    </div>
                </div>


            </div>
            {Object.keys(visitData) != 0 && visitData?.homon_jiseki?.map((item, index) => {
                return (<VisitView
                    key={index}
                    visitData={visitData}
                    setVisitData={setVisitData}
                    index={index}
                    item={item}
                    updateVisitData={updateVisitData}
                    masterData={masterData}
                    updateVisitSupportData={updateVisitSupportData}
                    deleteVisitData={deleteDialogOnOpen}
                    updateVisitRouteData={updateVisitRouteData}
                    startOfuku={startOfuku}
                    setStartOfuku={setStartOfuku}
                    endOfuku={endOfuku}
                    setEndOfuku={setEndOfuku}
                    userID={state ? state.userID : localStorage.getItem(localstorage.username)}
                    menuFlag={menuFlag}
                />)
            })}
            <div className={classes.buttonGroup2}  >
                {
                    visitData?.homon_jiseki?.length >= 2
                        ?
                        ""
                        :
                        <Button style={{ width: VW(400) }} onClick={addVisitData}
                            disabled={Object.keys(visitData) != 0 && (
                                visitData.homon_jiseki[0].kyuka_kubun == "1" ||
                                visitData.homon_jiseki[0].kyuka_kubun == "4" ||
                                visitData.homon_jiseki[0].kyuka_kubun == "2" ||
                                visitData.homon_jiseki[0].kyuka_kubun == "3" ||
                                visitData.homon_jiseki[0].kyuka_kubun == "5" ||
                                visitData.homon_jiseki[0].kyuka_kubun == "6")
                                ? true : false}
                        >
                            訪問先の追加
                        </Button>
                }
            </div>
            {/* <div className={classes.buttonGroup2}  >
                <Button onClick={JumpToConfirm} >提出内容の確認へ</Button>

            </div> */}
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={deleteDialogOnClose}
                deleteDialogValue={deleteDialogValue}
                deleteDialogConfirm={deleteDialogConfirm}
            />
            <ChangeDateDialog
                open={changeDateDialogOpen}
                onClose={changeDateDialogOnClose}
                changeDateDialogConfirm={changeDateDialogConfirm}
            />
        </div >
    )
}

VisitRecordInput.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(VisitRecordInput);