import React, { useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import { localstorage, routerNames } from '../../wordsFile/wordsFile'
import { VW, VH } from "../../componets/function/sizeAdapter"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Modal, Button, Input, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import submittedIcon from "../../componets/icon/submitted.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { getCurrSession } from "../../network/UserPool";

const styles = (theme) => ({
    ground: {
        height: '100%',
        paddingTop: VH(25),
        paddingLeft: VW(24),
        paddingRight: VW(24),
    },
    iconContainer: {
        transform: 'scale(1.667)',
    },
    step: {
        '& .MuiStepLabel-root .Mui-completed': {
            color: '#2195D3',
        },
        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
            fontWeight: 400,
        },
        '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700
        },
        '& .MuiStepLabel-root .Mui-active': {
            color: '#2195D3',
        },
        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
            fontWeight: 400,
        },
        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700,
            fontSize: VW(14)
        },
        '& .Mui-disabled .MuiStepIcon-root': {
            color: '#E0E0E0',
        },
        '& .Mui-disabled .MuiStepIcon-root .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700,
            fontSize: VW(14),
        },
        '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
        },
        "& .MuiStepConnector-line": {
            borderColor: "#2195D3",
        }
    },
    itemText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        fontSize: VW(24),
        fontWeight: 700
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: VH(40),
        paddingBottom: VH(5),
        '& > Button': {
            width: VW(400),
            backgroundColor: "#2095D4",
            color: "#fff",
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            border: `1px solid #2195D3`,
        }
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(10),
        '& > Button': {
            width: VW(533),
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    }
});

const steps = [
    '訪問先の登録',
    '登録内容の確認',
    '登録完了',
];

function VisitSubmitted(props) {
    const {
        classes,
        setSelectFlag,
        visitData,
        setVisitData,
        isUpdate,
        setIsUpdate,
        updateVisitData,
        masterData,
        updateVisitSupportData
    } = props
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(() => {
        getCurrSession()
        if (state && state.route == "visit") {
            setSelectFlag(routerNames.VisitRecordInput)
        } else {
            setSelectFlag(routerNames.MonthlyVisitingRecord)
        }
    }, [setSelectFlag])

    const goBack = () => {
        navigate(routerNames.VisitRecordInput)
    }
    return (
        <div className={classes.ground} >
            {/* <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'center', }} >

                <Stepper activeStep={3} alternativeLabel style={{ width: VW(648) }}>
                    {steps.map((label) => (
                        <Step key={label}
                            className={classes.step}>
                            <StepLabel classes={{
                                iconContainer: classes.iconContainer
                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div> */}
            <div style={{ marginTop: VH(32), width: "100%", paddingTop: VH(60) }}>
                <div>
                    <div className={classes.itemText}>登録完了しました！</div>
                    <div className={classes.itemText}>本日もお疲れ様でした</div>

                </div>
                <div style={{ marginTop: VH(24), width: "100%", display: 'flex', justifyContent: 'center' }}>
                    <img src={submittedIcon} style={{ marginRight: VW(8) }} />
                </div>
            </div>
            <div className={classes.buttonGroup}  >
                <Button style={{ width: VW(400) }} onClick={goBack}>ホームに戻る</Button>
            </div>
            {/* <div className={classes.buttonGroup2}  >
                <Button style={{ width: VW(400) }} onClick={goBack} >別の実績を登録する</Button>

            </div> */}
        </div >
    )
}

VisitSubmitted.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(VisitSubmitted);