import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Home from '../home/Home'
import LoginRouters from './LoginRouters';

const RoutesDom = () => (
     <BrowserRouter>
          <Routes>
               <Route path="*" element={<LoginRouters />} />
               <Route path="login/*" element={<LoginRouters />} />
               <Route path="home/*" element={<Home />} />
          </Routes>
     </BrowserRouter>
)

export default RoutesDom;