import React, { useEffect, useState, useRef } from "react";
import {
    Alert,
    Collapse
} from "@mui/material";
import { withStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { VW, VH } from "../../componets/function/sizeAdapter"
const styles = (theme) => ({

});

function Common_errorAlert(props) {
    const {
        classes,
        open,
        setRrrorOpen,
        errorContent,
        marginTop,
        marginBottom,
        border,
        listError,
        closeErrorItem
    } = props





    return (
        <div>
            <Collapse in={open}>
                <Alert
                    severity="error"
                    // onClose={() => { setRrrorOpen(false); }}
                    action={
                        <div
                            style={{ display: 'flex', alignItems: "center" }}
                        >
                            <CloseIcon
                                onClick={() => {
                                    listError ? closeErrorItem() : setRrrorOpen(false);
                                }}
                                style={{ color: "#323232", width: VW(20), height: VH(20), marginRight: VW(10) }} />
                        </div>
                    }


                    style={{
                        fontSize: VW(16),
                        border: border ? '1px solid red' : 0,
                        marginTop: marginTop ? marginTop : 0,
                        marginBottom: marginBottom ? marginBottom : 0,
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                        border: '1px solid #E60024',
                        backgroundColor: '#FEE7E7',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >{errorContent}
                </Alert>
            </Collapse>
        </div >
    )
}

Common_errorAlert.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Common_errorAlert);