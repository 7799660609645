import React, { useEffect, useState, Fragment } from "react";
import {
    Button,
    TextField,
} from "@mui/material";
import log from "../componets/function/log";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { routerNames } from '../wordsFile/wordsFile'
import Common_errorAlert from "../componets/common/Common_errorAlert";
import { formatCheck, errorMessage } from "../wordsFile/wordsFile";
import { ApiTokenForgotPassword } from '../network/api'
import { VW, VH } from "../componets/function/sizeAdapter"
import { ArrowBackIos } from "@mui/icons-material";
const styles = (theme) => ({
    groundBlock: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    LoginView: {
        width: VW(680),
        backgroundColor: '#FFF',
        boxShadow: '0px 0.26vw 1.05vh 0.26vw #EAEFF3',
        textAlign: 'center',
        paddingTop: VH(40),
        position: 'absolute',
        top: VH(104)
    },
    tittleIcon: {
        width: '100%',
        textAlign: 'center',
        fontSize: VW(24),
        fontWeight: 'bold'
    },
    annotateText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24),
        '& > div': {
            textAlign: 'left',
            fontSize: VW(16),
        }
    },
    label: {
        width: '100%',
        display: "flex",
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24)
    },
    text: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontSize: VW(16),
    },
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        marginTop: VH(40),
        fontSize: VW(16),
        fontWeight: 'bold',
        marginBottom: VH(40)
    },
    input: {
        height: VH(48),
        fontSize: VW(16),
    },
    inputContent: {
        padding: VH(14),
        fontSize: VW(16),
    },
    linkFont: {
        color: '#0052CC',
        marginTop: VH(12),
        fontSize: VW(16),
        '& > span': {
            cursor: 'pointer'
        }
    },
    backLink: {
        fontSize: VW(12),
        color: theme.palette.primary.main,
        position: 'absolute',
        top: VH(12),
        left: VW(12),
        cursor: 'pointer'
    },
    errorAlert: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24)
    }
});

function SendCode(props) {
    const {
        classes,
    } = props


    const navigate = useNavigate()


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    // email send code api
    const [emailAddress, setEmailAddress] = useState('')
    const sendCode = () => {
        if (emailAddress === '') {
            setErrorContent(errorMessage.nameEmptyError)
            setRrrorOpen(true)
        } else if (!formatCheck.Email.test(emailAddress)) {
            setErrorContent(errorMessage.nameFormatError)
            setRrrorOpen(true)
        } else {
            let param = {
                email: emailAddress
            }
            ApiTokenForgotPassword(param).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    log(resData)
                    navigate(routerNames.ChangePassword, { state: emailAddress })
                }
            }).catch(err => {

            })
        }
    }


    return (
        <div className={classes.groundBlock}>
            <div className={classes.LoginView}>
                <div className={classes.backLink} onClick={() => { navigate('/') }}><div style={{ display: 'flex', alignContent: "center", alignItems: "center" }}><ArrowBackIos style={{ width: VW(16), height: VW(16) }}></ArrowBackIos>ログイン画面へ戻る </div></div>
                <div className={classes.tittleIcon}>パスワード再設定</div>
                <div className={classes.errorAlert}>
                    <div style={{ width: VW(400) }}>
                        <Common_errorAlert
                            open={errorOpen}
                            setRrrorOpen={setRrrorOpen}
                            errorContent={errorContent}
                        />
                    </div>
                </div>
                <div className={classes.annotateText}>
                    <div>登録メールアドレスを入力してください。<br />パスワード再設定のための認証コードを送信します。</div>
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>メールアドレス  <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        sx={{ width: VW(400) }}
                        type='text'
                        onChange={(event) => { setEmailAddress(event.target.value) }}
                        value={emailAddress}
                        autoComplete="off"
                        placeholder="メールアドレスを入力してください"
                        InputProps={{
                            className: classes.input,
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>
                <Button
                    className={classes.loginButton}
                    variant="contained"
                    sx={{ width: VW(400), height: VH(48) }}
                    onClick={sendCode}
                >送信
                </Button>
            </div>
        </div>
    )
}

SendCode.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(SendCode);