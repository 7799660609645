// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  休暇区分 select 
const kyuka_kubunOption = [
    { value: '', label: '-' },
    { value: '1', label: '有給1日' },
    { value: '2', label: '有給AM' },
    { value: '3', label: '有給PM' },
    { value: '4', label: '特休1日' },
    { value: '5', label: '特休AM' },
    { value: '6', label: '特休PM' }
]

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  支援区分 select 
const support_kubunOption = [
    { value: '1', label: '授業支援' },
    { value: '2', label: '操作説明' },
    { value: '3', label: '相談対応' },
    { value: '4', label: 'トラブル対応' },
    { value: '5', label: '修理・故障対応' },
    { value: '6', label: '資料作成' },
    { value: '7', label: '環境整備' },
    { value: '8', label: '機材管理' },
    { value: '9', label: '研修・勉強会' },
    { value: '10', label: '会議・打合せ' },
    { value: '11', label: '学校行事' },
    { value: '12', label: '問合せ対応' },
    { value: '13', label: 'その他' }
]

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  ステータス（0:未完了,1:完了） select 
const statusOption = [
    { value: '', label: '-' },
    { value: '1', label: '完了' },
    { value: '0', label: '未完了' }
]

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  業務の種類 select 
const gyomu_shuruiOption = [
    // { value: '', label: '-' },
    { value: '1', label: '訪問業務' },
    { value: '2', label: '訪問業務以外' }

]

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  訪問区分 select
const teiji_jikanOption = [
    // { value: '1', label: '-' },
    { value: '2', label: '1日' },
    { value: '3', label: '0.5日（AM）' },
    { value: '4', label: '0.5日（PM）' }

]

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  時間外 select
const jikangaiOption = [
    { value: '1', label: '0.0' },
    { value: '2', label: '0.5' },
    { value: '3', label: '1.0' },
    { value: '4', label: '1.5' },
    { value: '5', label: '2.0' },
    { value: '6', label: '2.5' },
    { value: '7', label: '3.0' },
    { value: '8', label: '3.5' },
    { value: '9', label: '4.0' },
    { value: '10', label: '4.5' },
    { value: '11', label: '5.0' },
    { value: '12', label: '5.5' },
    { value: '13', label: '6.0' },
    { value: '14', label: '6.5' },
    { value: '15', label: '7.0' },
    { value: '16', label: '7.5' },
    { value: '17', label: '8.0' },
]

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  交通機関 select 
const kotsu_kikanOption = [
    { value: '1', label: '自家用車' },
    { value: '2', label: 'JR' },
    { value: '3', label: 'バス' },
    { value: '4', label: 'フェリー' },
    { value: '5', label: '徒歩・自転車' },
    { value: '6', label: 'タクシー' },
    { value: '7', label: '便乗' }
]

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  到着地 select 
const tochaku_Option = [
    { label: '自宅' },
    { label: '訪問先2' }
]

const committeeCode_Option = [
    { value: '1', label: '大分県教育委員会' }
    // { value: '0', label: 'その他' }
]
const ownerNo_Option = [
    { value: '1', label: '生徒' },
    { value: '2', label: '教員' }
]
const flag_Option = [
    { value: '0', label: '含まない' },
    { value: '1', label: '含む' }
]
const flag_Option_Storage = [
    { value: '0', label: '1つずつ表示' },
    { value: '1', label: 'まとめて表示' }
]
const type_Option = [
    { value: '1', label: 'GIGA' },
    { value: '2', label: 'V3' }
]
const status_Option = [
    { value: '1', label: '未使用' },
    { value: '2', label: '使用中' },
    { value: '3', label: '紛失' },
    { value: '4', label: '廃棄予定' },
    { value: '5', label: '削除' }
]
const operating_Option = [
    { value: '1', label: '未着手' },
    { value: '2', label: '初期化・初期設定中' },
    { value: '3', label: '確認作業前' },
    { value: '4', label: '充電・クリーン前' },
    { value: '5', label: '（準備中）業者引渡し前' },
    { value: '6', label: 'デジ室故障一覧連絡前' },
    { value: '7', label: '様式未提出' },
    { value: '8', label: 'デジ室故障一覧返答待ち' },
    { value: '9', label: '業者引渡し前' },
    { value: '10', label: '業者対応中' },
    { value: '11', label: '業者戻り後未着手' },
    { value: '12', label: '業者戻り後設定中' },
    { value: '13', label: 'テプラ対応前' },
    { value: '14', label: '返却可能' },
    { value: '15', label: '返却中' },
    { value: '16', label: '完了' },
    { value: '17', label: 'キャンセル' },
    { value: '18', label: 'IP・DNS手動設定前' },
    { value: '19', label: 'Mac認証変更依頼中' },
    { value: '20', label: '検討中(デジ室)' },
    { value: '21', label: '検討中(GIGAヘルプ)' },
    { value: '22', label: 'その他検討中' },
]
const checked_Option = [
    { value: "true", label: 'あり' },
    { value: "false", label: 'なし' }
]
const break_Option = [
    { value: "true", label: '提出' },
    { value: "false", label: '未提出' }
]
const kikikubun_code_Option = [
    { value: "1", label: 'V3端末' },
    { value: "2", label: 'GIGA端末' },
    { value: "3", label: '特支教育課端末' },
    { value: '4', label: 'キーボード' },
    { value: '5', label: '充電ケーブル' },
    { value: '6', label: 'その他' },
    { value: '7', label: '-' }
]
const azukari_code_Option = [
    { value: "1", label: '初期化' },
    { value: "2", label: '故障' },
    { value: "3", label: '修理' },
    { value: '4', label: 'プロファイル適用' },
    { value: '5', label: 'その他' },
    { value: '6', label: '-' }
]
const shuri_ipad_code_Option = [
    { value: "1", label: 'MDM管理' },
    { value: "2", label: 'オフ' },
    { value: "3", label: '不可' },
    { value: '4', label: '-' }
]
const funshitsu_mode_Option = [
    { value: "1", label: 'PUSH送信中' },
    { value: "2", label: '設定済み' },
    { value: "3", label: 'ジョブ取消' },
    { value: '4', label: '-' }
]
const icchi_info_Option = [
    { value: "1", label: '校内に存在' },
    { value: '2', label: '-' }
]
const mitekiyo_idou_Option = [
    { value: "1", label: '移動中' },
    { value: '2', label: '移動解除' },
    { value: '3', label: '-' }
]
export {
    kyuka_kubunOption,
    support_kubunOption,
    statusOption,
    gyomu_shuruiOption,
    teiji_jikanOption,
    jikangaiOption,
    kotsu_kikanOption,
    tochaku_Option,
    committeeCode_Option,
    ownerNo_Option,
    flag_Option,
    flag_Option_Storage,
    type_Option,
    status_Option,
    operating_Option,
    checked_Option,
    break_Option,
    kikikubun_code_Option,
    azukari_code_Option,
    shuri_ipad_code_Option,
    funshitsu_mode_Option,
    icchi_info_Option,
    mitekiyo_idou_Option
}