import React, { useEffect, useState } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton } from "@mui/material";
import { ApiTerminalFileAdd } from "../../network/api";
import UploadFiles from "../common/UploadFiles";
import { Close } from "@mui/icons-material";
import { useDropzone } from 'react-dropzone';
import Common_errorAlert from "../common/Common_errorAlert"
import Common_successAlert from "../common/Common_successAlert";
import * as XLSX from "xlsx"

dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(60),
        '& > Button': {
            borderRadius: "4px",
            width: VW(240),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup2: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16),
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        fontWeight: 'bold',
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    itemUpload: {
        width: VW(792),
        height: VH(178),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        justifyContent: 'center',
        marginLeft: VW(8),
        alignItems: "center",
        backgroundColor: "#F8FAFB",
        borderLeft: '2px dotted #2095D4',
        borderRight: '2px dotted #2095D4',
        borderTop: '2px dotted #2095D4',
        borderBottom: '2px dotted #2095D4',
    },
    itemRightKey: {
        width: "100%",
        height: VH(50),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        justifyContent: 'center',
        backgroundColor: "#F8FAFB",
        display: "flex",
        marginLeft: VW(8),
    },
    itemLeft: {
        width: "100%",
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginLeft: VW(8),
    },
});



function BulkRegistrationDialog(props) {
    const {
        classes,
        open,
        onClose,
    } = props

    useEffect(() => {
        if (!open) {
            setAcceptedFilesStandIn([])
            setErrorList([])
            setRrrorOpen(false)
            setCsvData(null)
            setSuccessOpen(false)
        }
    }, [open]);
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // file list
    var { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1
    });

    const [csvData, setCsvData] = useState(null);
    const [acceptedFilesStandIn, setAcceptedFilesStandIn] = useState([])
    const isEmptyObject = obj => {
        return Object.values(obj).every(value => value === '' || value === null || value === undefined);
    };
    useEffect(() => {
        log("acceptedFiles")
        log(acceptedFiles[0])
        log(acceptedFiles)
        if (acceptedFiles[0] && acceptedFiles[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setAcceptedFilesStandIn([...acceptedFiles])
            if (acceptedFiles[0]) {
                setCsvData(null)
                const fileReader = new FileReader();
                fileReader.onload = ((e) => {
                    const workbook = XLSX.read(e.target.result, {
                        type: "binary",
                    });
                    const wsname = workbook.SheetNames[0];
                    const sheetCsv = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
                    const sheetCsvTmp = []
                    var indexNumber = 0

                    const filteredList = sheetCsv.filter(obj => !isEmptyObject(obj));

                    filteredList.forEach(element => {
                        // if (
                        //     element.appleid != null && element.appleid != "" &&
                        //     element.appleidpassword != null && element.appleidpassword != "" &&
                        //     element.asm != null && element.asm != "" &&
                        //     element.donyu_nendo != null && element.donyu_nendo != "" &&
                        //     element.donyu_nengappi != null && element.donyu_nengappi != "" &&
                        //     element.gako_code != null && element.gako_code != "" &&
                        //     element.ifilter != null && element.ifilter != "" &&
                        //     element.jyotai_no != null && element.jyotai_no != "" &&
                        //     element.kanri_no != null && element.kanri_no != "" &&
                        //     element.mac_address != null && element.mac_address != "" &&
                        //     element.mdm != null && element.mdm != "" &&
                        //     element.memo != null && element.memo != "" &&
                        //     element.mokushi != null && element.mokushi != "" &&
                        //     element.owner != null && element.owner != "" &&
                        //     element.serial_num != null && element.serial_num != "" &&
                        //     element.setsuzokunwmei != null && element.setsuzokunwmei != "" &&
                        //     element.syubetsu_code != null && element.syubetsu_code != ""
                        // ) {

                        sheetCsvTmp.push(element)
                        indexNumber = indexNumber + 1;

                    });
                    if (indexNumber > 5000) {
                        setErrorContent('一度に投入可能な端末数の上限5000件を超えているため、投入できません。')
                        setRrrorOpen(true)
                        setAcceptedFilesStandIn([])
                        setCsvData(null);
                        return
                    }
                    let content = JSON.stringify(sheetCsvTmp);
                    let sheetCsvBlob = new File([content], acceptedFiles[0].name + '.json', { type: "text/plain;charset=utf-8" });

                    setCsvData(sheetCsvBlob);
                })
                fileReader.readAsBinaryString(acceptedFiles[0]);
            }
        } else {
            setAcceptedFilesStandIn([])
            setCsvData(null);
        }
    }, [acceptedFiles]);
    const [errorList, setErrorList] = useState([])
    const closeErrorItem = (item) => {
        errorList.map(ll => {
            if (ll.errorKey == item.errorKey) {
                ll.openFlag = false
            }
        })
        setErrorList([...errorList])
        log(item)
    }

    // upload
    const toAdd = () => {
        if (acceptedFilesStandIn.length == 0) {
            setErrorContent('ファイルをアップロードして下さい')
            setRrrorOpen(true)
            return
        } else {
            setRrrorOpen(false)
            var data = new FormData();
            acceptedFilesStandIn.map((item) => {
                data.append('files', csvData)
            })

            ApiTerminalFileAdd(data).then(res => {
                let resData = res.data
                if (res.status == 200) {
                    log(resData)
                    if (resData.data == 'Failure') {
                        resData.error_message.map((item, index) => {
                            item.openFlag = true
                            item.errorKey = index
                        })
                        setErrorList(resData.error_message)
                        setSuccessOpen(false)
                    } else {
                        // onClose()
                        setSuccessContent("ファイルをアップロード完了しました。")
                        setSuccessOpen(true)
                        setRrrorOpen(false)
                        setCsvData(null)
                    }
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                    setSuccessOpen(false)
                }
            }).catch(err => {
            })
        }
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(840)}
            height={VH(417)}
            backgroundColor={"#F4F5F9"}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>一括登録</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>

            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(24)}
            />
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}

                border
                marginTop={VH(24)}
            />
            <div className={classes.itemLeft}>
                Excelをアップロードしてください。
            </div>
            <div className={classes.itemUpload}>
                <UploadFiles
                    acceptedFiles={acceptedFilesStandIn}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                />
            </div>
            <div className={classes.buttonGroup} style={{ marginBottom: VH(5), paddingBottom: VH(5) }}>
                <div style={{ width: "50%", paddingLeft: VW(0) }}>
                    <Button className={classes.buttonGroup1} onClick={onClose}>閉じる</Button>
                </div>
                <div style={{ width: "50%", paddingRight: VW(0), display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <Button className={classes.buttonGroup2} onClick={toAdd} disabled={csvData == null ? true : false}>追加</Button>
                </div>
            </div>

        </Modal>
    )
}

BulkRegistrationDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(BulkRegistrationDialog);