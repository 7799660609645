import React, { useEffect, useState, Fragment } from "react";
import {
    Button,
    TextField,
    IconButton
} from "@mui/material";
import log from "../componets/function/log";
import { withStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { routerNames } from '../wordsFile/wordsFile'
import { ApiTokenForceChangePassword } from '../network/api'
import { formatCheck, errorMessage } from "../wordsFile/wordsFile";
import Common_errorAlert from "../componets/common/Common_errorAlert";
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { VW, VH } from "../componets/function/sizeAdapter"


const styles = (theme) => ({
    groundBlock: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    LoginView: {
        width: VW(680),
        backgroundColor: '#FFF',
        boxShadow: '0px 0.26vw 1.05vh 0.26vw #EAEFF3',
        textAlign: 'center',
        paddingTop: VH(40),
        position: 'absolute',
    },
    tittleIcon: {
        width: '100%',
        textAlign: 'center',
        fontSize: VW(24),
        fontWeight: 'bold'
    },
    annotateText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24),
        '& > div': {
            width: VW(400),
            textAlign: 'left',
            fontSize: VW(16),
        }
    },
    label: {
        width: '100%',
        display: "flex",
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24)
    },
    text: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontSize: VW(16),
    },
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        marginTop: VH(40),
        fontSize: VW(16),
        fontWeight: 'bold',
        marginBottom: VH(40)
    },
    input: {
        height: VH(48),
        fontSize: VW(16),
    },
    inputContent: {
        padding: VH(14),
        fontSize: VW(16),
        fontSize: VW(16)
    },
    errorAlert: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24)
    }
});

function NewPassword(props) {
    const {
        classes,
    } = props

    useEffect(() => {
        log(state)
    }, [])

    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // password display
    const [showPasswordA, setShowPasswordA] = useState(true);
    const handleClickShowPasswordA = () => setShowPasswordA((show) => !show);
    const handleMouseDownPasswordA = (event) => {
        event.preventDefault();
    };
    const [showPasswordB, setShowPasswordB] = useState(true);
    const handleClickShowPasswordB = () => setShowPasswordB((show) => !show);
    const handleMouseDownPasswordB = (event) => {
        event.preventDefault();
    };


    // change password api
    const [newPasswordA, setNewPasswordA] = useState('')
    const [newPasswordB, setNewPasswordB] = useState('')
    const toChangePassword = () => {
        var a = newPasswordA.match(/[^\x00-\xff]/g)

        if (newPasswordA === '') {
            setErrorContent(errorMessage.E0004)
            setRrrorOpen(true)
        } else if (!formatCheck.Password.test(newPasswordA) || !formatCheck.Password.test(newPasswordB)) {
            setErrorContent(errorMessage.passwordFormatError)
            setRrrorOpen(true)
        } else if (newPasswordA != newPasswordB) {
            setErrorContent(errorMessage.namePasswordDif)
            setRrrorOpen(true)
        } else if (a != null) {
            setErrorContent(errorMessage.E0007)
            setRrrorOpen(true)
        } else {
            let params = {
                session: state.session,
                email: state.email,
                password: newPasswordA,
            }
            ApiTokenForceChangePassword(params).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    log(resData)
                    navigate(routerNames.ChangeOK)
                }
            }).catch(err => {

            })
        }
    }


    return (
        <div className={classes.groundBlock}>
            <div className={classes.LoginView}>
                <div className={classes.tittleIcon}>パスワード再設定</div>
                {errorOpen && <div className={classes.errorAlert}>
                    <div style={{ width: VW(400) }}>
                        <Common_errorAlert
                            open={errorOpen}
                            setRrrorOpen={setRrrorOpen}
                            errorContent={errorContent}
                        />
                    </div>
                </div>}
                <div className={classes.annotateText}>
                    <div>新しいパスワードを入力してください。<br />パスワード再設定後、新しいパスワードで再度ログインしてください。<br />※パスワードは8文字以上、英大文字、英小文字、数字をそれぞれ最低1つずつ含める必要があります。</div>
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>新しいパスワード <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        sx={{ width: VW(400) }}
                        type={showPasswordA ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setNewPasswordA(event.target.value) }}
                        value={newPasswordA}
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPasswordA}
                                    onMouseDown={handleMouseDownPasswordA}
                                    edge="end"
                                >
                                    {showPasswordA ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>新しいパスワード（確認用） <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        sx={{ width: VW(400) }}
                        type={showPasswordB ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setNewPasswordB(event.target.value) }}
                        value={newPasswordB}
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPasswordB}
                                    onMouseDown={handleMouseDownPasswordB}
                                    edge="end"
                                >
                                    {showPasswordB ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>
                <Button
                    className={classes.loginButton}
                    variant="contained"
                    sx={{ width: VW(400), height: VH(48) }}
                    onClick={toChangePassword}
                >パスワード再設定
                </Button>
            </div>
        </div>
    )
}

NewPassword.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(NewPassword);