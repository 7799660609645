import React, { useEffect, useState } from "react";
import log from "../../componets/function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { ApiTerminalCsvDownload } from "../../network/api";
import { Checkbox, RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { errorMessage } from '../../wordsFile/wordsFile'
import Common_errorAlert from "../../componets/common/Common_errorAlert"

dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24),
        '& > Button': {
            borderRadius: "4px",
            width: VW(240),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup2: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16),
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        fontWeight: 'bold',
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginTop: VH(13),
        marginBottom: VH(13)
    },
    itemRightKey: {
        width: VW(700),
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginLeft: VW(8),
        paddingLeft: VW(2),
        paddingRight: VW(16)
    },
});


function TerminalCsvOutputDialog(props) {
    const {
        classes,
        open,
        onClose,
        homonMonth,
    } = props


    useEffect(() => {
        setRrrorOpen(false)
        setErrorContent("")

    }, [open])

    const [chkData, setChkData] = useState({
        chk1: false,
        chk2: false,
        chk3: false,
    });

    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    const csvChecked1 = (event) => {
        setChkData(prev => {
            let data = {
                chk1: event.target.checked,
                chk2: prev.chk2,
                chk3: prev.chk3,
            }
            return data
        });
    };

    const csvChecked2 = (event) => {
        setChkData(prev => {
            let data = {
                chk1: prev.chk1,
                chk2: event.target.checked,
                chk3: prev.chk3,
            }
            return data
        });
    };
    const csvChecked3 = (event) => {
        setChkData(prev => {
            let data = {
                chk1: prev.chk1,
                chk2: prev.chk2,
                chk3: event.target.checked,
            }
            return data
        });
    };

    const [terminalAllDelete, setTerminalAllDelete] = useState('0')
    const [terminalStorageDelete, setTerminalStorageDelete] = useState('0')

    const csvAllRadioClick = (event) => {
        setTerminalAllDelete(event.target.value);
    };

    const csvStorageRadioClick = (event) => {
        setTerminalStorageDelete(event.target.value);
    };

    const getUrlname = (url) => {
        url = url.split('?')[0];
        var urlSlashCount = url.split('/').length;
        return url.split('/')[urlSlashCount - 1];
    };
    const csvOutput = (event) => {

        if (chkData.chk1 === false && chkData.chk2 === false && chkData.chk3 === false) {
            setErrorContent(errorMessage.E0005)
            setRrrorOpen(true)
            return
        } else {
            setErrorContent("")
            setRrrorOpen(false)
        }
        let params = {
            csv_flag: chkData.chk1 + ',' + chkData.chk2 + ',' + chkData.chk3,
            all_delete_flag: terminalAllDelete,
            storagecheck_delete_flag: terminalStorageDelete
        }

        log(params)
        ApiTerminalCsvDownload(params).then(res => {
            let resData = res.data
            log(resData.data)
            if (res.status === 200) {
                var downloadName = getUrlname(decodeURIComponent(resData.data))

                log(downloadName)
                fetch(resData.data, {
                    method: 'get',
                    responseType: 'blob'
                }).then(res => {
                    return res.blob();
                }).then(blob => {
                    let a = document.createElement('a');
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = downloadName;
                    a.click();
                    a.remove();
                })
            } else if (res.status === 201) {

            }
        }).catch(err => {

        })
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(840)}
            height={VH(351)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>ダウンロード</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>

            <div className={classes.itemRightKey} style={{ fontSize: VW(16) }}>
                出力するCSVを選択してください。
            </div>
            <div className={classes.itemRightKey}>
                <div style={{ width: VW(250) }}>
                    <FormControlLabel control={<Checkbox className={classes.itemRightKey} size="string" style={{ width: VW(24), height: VW(24) }} onChange={csvChecked1} />}
                        label={<Typography style={{ fontSize: VW(16) }}>全端末データ</Typography>} />
                </div>
                <div style={{ width: VW(400) }}>
                    <FormControlLabel control={
                        <RadioGroup row defaultValue="0" name="radio-group" value={terminalAllDelete}>
                            <FormControlLabel value="0" control={<Radio onClick={csvAllRadioClick} />}
                                label={<Typography style={{ fontSize: VW(14) }}>削除端末を含まない</Typography>} />
                            <FormControlLabel value="1" control={<Radio onClick={csvAllRadioClick} />}
                                label={<Typography style={{ fontSize: VW(14) }}>削除端末を含む</Typography>} />
                        </RadioGroup>
                    } />
                </div>

            </div>
            <div className={classes.itemRightKey}>
                <div style={{ width: VW(250) }}>
                    <FormControlLabel control={<Checkbox className={classes.itemRightKey} style={{ width: VW(24), height: VW(24) }} onChange={csvChecked2} />}
                        label={<Typography style={{ fontSize: VW(16) }}>預かり端末データ</Typography>} />
                </div>
                <div style={{ width: VW(400) }}>
                    <FormControlLabel control={
                        <RadioGroup row defaultValue="0" name="radio-group" value={terminalStorageDelete}>
                            <FormControlLabel value="0" control={<Radio onClick={csvStorageRadioClick} />}
                                label={<Typography style={{ fontSize: VW(14) }}>履歴を含まない</Typography>} />
                            <FormControlLabel value="1" control={<Radio onClick={csvStorageRadioClick} />}
                                label={<Typography style={{ fontSize: VW(14) }}>履歴を含む</Typography>} />
                        </RadioGroup>
                    } />
                </div>

            </div>
            <div className={classes.itemRightKey}>
                <div style={{ width: VW(250) }}>
                    <FormControlLabel control={<Checkbox className={classes.itemRightKey} style={{ width: VW(24), height: VW(24) }} onChange={csvChecked3} />}
                        label={<Typography style={{ fontSize: VW(16) }}>故障端末データ</Typography>} />
                </div>

            </div>
            <div className={classes.buttonGroup} style={{ marginBottom: VH(5), paddingBottom: VH(5) }}>
                <div style={{ width: "50%", paddingLeft: VW(0) }}>
                    <Button className={classes.buttonGroup1} onClick={onClose}>閉じる</Button>
                </div>
                <div style={{ width: "50%", paddingRight: VW(0), display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <Button className={classes.buttonGroup2} onClick={csvOutput}>出力</Button>
                </div>
            </div>

        </Modal>
    )
}

TerminalCsvOutputDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TerminalCsvOutputDialog);