import { VW, VH } from "../../componets/function/sizeAdapter"
function AddIcon(props) {
    const {
        color
    } = props
    return (
        <svg width={VW(20)} height={VH(20)} viewBox='0 0 20 20' style={{ display: "block" }}>
            <path d="M9.00033 0.666504C4.40033 0.666504 0.666992 4.39984 0.666992 8.99984C0.666992 13.5998 4.40033 17.3332 9.00033 17.3332C13.6003 17.3332 17.3337 13.5998 17.3337 8.99984C17.3337 4.39984 13.6003 0.666504 9.00033 0.666504ZM13.167 9.83317H9.83366V13.1665H8.16699V9.83317H4.83366V8.1665H8.16699V4.83317H9.83366V8.1665H13.167V9.83317Z" fill={color ? color : '#0052CC'} />
        </svg>
    )
}

export default AddIcon;