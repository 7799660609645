import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { jaJP } from "@mui/material/locale";

// colors
const primary = "#2095D4";
const themeColor = '#E2E6EA';
const themeBorder = '#EBE2E2'
const tableTitleColor = '#44566C'
const buttonBLue = '#2095D4'



const theme = createTheme(
  {
    palette: {
      primary: { main: primary },
      themeColor: { main: themeColor },
      themeBorder: { main: themeBorder },
      tableTitleColor: { main: tableTitleColor },
      buttonBLue: { main: buttonBLue }
    },
    typography: {
      fontFamily: ['Noto Sans JP', 'sans-serif'].join(),  // `"Chilanka","Noto Sans JP", "Helvetica", "Arial", sans-serif`,
    },
    MuiInputBase: {
      root: {
        fontFamily: ['Noto Sans JP', 'sans-serif'].join(),
      }
    },
  },
  jaJP
)

export default responsiveFontSizes(theme, "breakpoints");
