

const routerNames = {
    Login: 'login',
    DashBoard: '/home/dashBoard',
    VisitRecordInput: '/home/visitRecordInput',
    VisitRecordConfirm: '/home/visitRecordConfirm',
    MonthlyVisitingRecord: '/home/monthlyVisitingRecord',
    MonthlyVisitingRecordDetail: '/home/monthlyVisitingRecordDetail',
    MonthlyVisitingRecordConfirmDetail: '/home/monthlyVisitingRecordConfirmDetail',
    MonthlyRecordConfirmSubmitted: '/home/monthlyRecordConfirmSubmitted',
    VisitSubmitted: '/home/visitSubmitted',
    SubmitConfirmCSVOutput: '/home/submitConfirmCSVOutput',
    TerminalManage: '/home/terminalManage',
    TerminalStateInquiry: '/home/terminalStateInquiry',
    TerminalDetail: '/home/terminalDetail',
    SupportContents: '/home/supportContents'
}


const localstorage = {
    access_token: 'access_token',
    refresh_token: 'refresh_token',
    id_token: 'id_token',
    authority_id1: 'authority_id1',
    authority_id2: 'authority_id2',
    authority_id3: 'authority_id3',
    authority_id4: 'authority_id4',
    authority_id5: 'authority_id5',
    preferred_username: 'preferred_username',
    email: 'email',
    username: 'username',
    visitData: "visitData",
    masterData: "masterData",
    businessDay: "businessDay",
    menuItem: "menuItem",
    loginDate: "loginDate"
}


const errorMessage = {
    namePasswordFormatError: 'メールアドレスもしくはパスワードが異なります',
    nameFormatError: 'メールアドレスを正しく入力してください。',
    nameEmptyError: 'メールアドレスを入力してください。',
    passwordFormatError: 'パスワードは8文字以上、英大文字、英小文字、数字をそれぞれ最低1つずつ含める必要があります。',
    passwordNull: 'パスワードを入力してください。',
    namePasswordDif: '新しいパスワード（確認用）が新しいパスワードと一致しません。',
    emailFormatError: 'メールアドレスを正しく入力してください。',
    zipCodeFormatError: '郵便番号は、000-0000の形式で入力してください。',
    phoneFormatError: '電話番号は、0から9の半角数字、半角ハイフン(-)を利用して入力してください。',
    E0003: 'メールアドレスまたはパスワードに誤りがあります。',
    E0004: '日報データまたは訪問実績チェックボックスを選択してください。',
    E0005: '全端末データまたは預かり端末データまたは故障端末データチェックボックスを選択してください。'

}



const formatCheck = {
    Email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    Code: /^\d{6}$/,
    Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/,
    Phone: /0\d{1,4}-\d{1,4}-\d{4}/,
    ZipCode: /^[0-9]{3}-[0-9]{4}$/
}



export {
    routerNames,
    errorMessage,
    formatCheck,
    localstorage
}