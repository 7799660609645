import React, { useEffect, useState } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton } from "@mui/material";
import { ApiSubmitCsvDownload } from "../../network/api";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Close } from "@mui/icons-material";
import { errorMessage } from '../../wordsFile/wordsFile'
import Common_errorAlert from "../../componets/common/Common_errorAlert"

dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(60),
        '& > Button': {
            borderRadius: "4px",
            width: VW(240),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup2: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16),
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        fontWeight: 'bold',
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    itemRightKey: {
        width: VW(400),
        height: VH(56),
        fontSize: VW(16),
        fontFamily: 'Noto Sans',
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginLeft: VW(8),
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
});


function SubmitCsvOutputDialog(props) {
    const {
        classes,
        open,
        onClose,
        homonMonth,
    } = props


    useEffect(() => {
        setRrrorOpen(false)
        setErrorContent("")

    }, [open])

    const [chkData, setChkData] = useState({
        chk1: false,
        chk2: false,
    });

    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')
    const csvChecked1 = (event) => {
        setChkData(prev => {
            let data = {
                chk1: event.target.checked,
                chk2: prev.chk2,
            }
            return data
        });
    };

    const csvChecked2 = (event) => {
        setChkData(prev => {
            let data = {
                chk1: prev.chk1,
                chk2: event.target.checked,
            }
            return data
        });
    };

    const getUrlname = (url) => {
        url = url.split('?')[0];
        var urlSlashCount = url.split('/').length;
        return url.split('/')[urlSlashCount - 1];
    };
    const csvOutput = (event) => {

        if (chkData.chk1 === false && chkData.chk2 === false) {
            setErrorContent(errorMessage.E0004)
            setRrrorOpen(true)
            return
        } else {
            setErrorContent("")
            setRrrorOpen(false)
        }
        let csv_flag = chkData.chk1 && chkData.chk2 ? '3' : chkData.chk1 ? "1" : "2"
        let params = {
            csv_flag: csv_flag,
            homon_month: homonMonth
        }

        log(params)
        ApiSubmitCsvDownload(params).then(res => {
            let resData = res.data
            log(resData.data)
            if (res.status === 200) {
                var downloadName = getUrlname(decodeURIComponent(resData.data))
                var errMsg = getUrlname(decodeURIComponent(resData.errMsg))

                if (errMsg != '') {
                    setErrorContent(errMsg)
                    setRrrorOpen(true)
                }

                log(downloadName)
                fetch(resData.data, {
                    method: 'get',
                    responseType: 'blob'
                }).then(res => {
                    return res.blob();
                }).then(blob => {
                    let a = document.createElement('a');
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = downloadName;
                    a.click();
                    a.remove();
                })
            } else if (res.status === 201) {

            }
        }).catch(err => {

        })
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(840)}
            height={VH(319)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>CSV出力</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>

            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(24)}
            />
            <div className={classes.itemRightKey}>
                出力するCSVを選択してください。
            </div>
            <div className={classes.itemRightKey}>
                <FormControlLabel control={<Checkbox onChange={csvChecked1} />} label="日報データ（訪問実績＋支援内容）" />

            </div>
            <div className={classes.itemRightKey}>
                <FormControlLabel control={<Checkbox onChange={csvChecked2} />} label="訪問実績（訪問実績＋移動内容）" />

            </div>
            <div className={classes.buttonGroup} style={{ marginBottom: VH(5), paddingBottom: VH(5) }}>
                <div style={{ width: "50%", paddingLeft: VW(0) }}>
                    <Button className={classes.buttonGroup1} onClick={onClose}>閉じる</Button>
                </div>
                <div style={{ width: "50%", paddingRight: VW(0), display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <Button className={classes.buttonGroup2} onClick={csvOutput}>出力</Button>
                </div>
            </div>

        </Modal>
    )
}

SubmitCsvOutputDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(SubmitCsvOutputDialog);