import React, { useState, useEffect } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, DatePicker, message } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import locale from "antd/es/date-picker/locale/ja_JP";
import { routerNames } from '../../wordsFile/wordsFile'
import { formatCheck, errorMessage, localstorage } from "../../wordsFile/wordsFile";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontWeight: 'bold',
        borderRadius: VW(5),
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup3: {
        color: '#FFF',
        borderRadius: VW(5),
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16)
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    tableBody: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(16),
        marginBottom: VH(5)
    },
});


function OffDialog2(props) {
    const {
        classes,
        open,
        onClose,
        OffDialog2Confirm
    } = props


    useEffect(() => {

    }, [open])

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}

            centered
            width={VW(840)}
            height={VW(247)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>休暇区分は変更できません。</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <div className={classes.tableBody}>
                <div style={{ marginLeft: VW(8) }}>訪問先情報が2つあるので、休暇区分を下記の値に変更することはできません。</div>
            </div>
            <div className={classes.tableBody}>
                <div style={{ marginLeft: VW(8) }}>“有給1日”、“特休1日”、“有給AM”、“有給PM”、“特休AM”、“特休PM”</div>
            </div>
            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>閉じる</Button>
            </div>
        </Modal>
    )
}

OffDialog2.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(OffDialog2);