import React, { useState, useEffect } from "react";
import {
    List,
    ListItemButton,
    Collapse
} from "@mui/material";
import { withStyles } from "@mui/styles";
import HeaderBarIcon from "../icon/HeaderBarIcon";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from 'antd'
import { upload } from "@testing-library/user-event/dist/upload";
import { useNavigate, useLocation } from 'react-router-dom'
import { VW, VH } from "../function/sizeAdapter"
import qs from 'qs'
import { localstorage } from "../../wordsFile/wordsFile";
import logo from "../../componets/icon/menuLogo.svg"
import { getUserPool } from "../../network/UserPool";
import log from "../function/log";
import { routerNames } from "../../wordsFile/wordsFile";

const styles = (theme) => ({
    headerBar: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    iconBlock: {
        height: '100%',
        borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.1s',
        overflow: 'hidden',
        fontWeight: 'bold',
        fontSize: VW(15),
        fontWeight: 700,
        color: "#ffffff"
    },
    menuBlock: {
        height: '100%',
        borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        transition: 'all 0.1s'
    },

    rightSelect: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: '2%',
        height: '50%',
        display: 'flex',
        alignItems: 'center',
    },
    selectIocn: {
        width: VH(40),
        height: VH(40),
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#D9D9D9',
        color: '#FFF',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    menuButton: {
        cursor: 'pointer',
        width: VW(22),
        height: VH(22),
        borderRadius: '50%',
        margin: VW(17),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    logoutButton: {
        position: 'absolute',
        right: '0',
        marginTop: VH(10),
        marginRight: VW(0),
        width: VW(173),
        height: VH(53),
        fontSize: VW(14),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#FFF',
        color: 'black',
        boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD'

    }
});

function HeaderBar(props) {
    const {
        classes,
        changeMenuStatus,
        menuFlag,
        setWindowCloseOpen,
        windowNextRoute,
        setWindowNextRoute,
        visitDataCompared,
        setVisitDataCompared,
        visitData,
        setVisitData,
        selectFlag
    } = props

    const navigate = useNavigate()
    const location = useLocation();
    // username  
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    }


    // to login
    const goToLogin = () => {
        handleClick()
        if (JSON.stringify(visitDataCompared) != JSON.stringify(visitData) && selectFlag == routerNames.VisitRecordInput && location.pathname != "/home/visitSubmitted") {
            setWindowNextRoute('logout')
            setWindowCloseOpen(true)
        } else {
            logout()
        }

    }

    //ログアウト
    function logout() {
        localStorage.removeItem(localstorage.access_token)
        localStorage.removeItem(localstorage.refresh_token)
        localStorage.removeItem(localstorage.id_token)
        localStorage.removeItem(localstorage.authority_id1)
        localStorage.removeItem(localstorage.authority_id2)
        localStorage.removeItem(localstorage.authority_id3)
        localStorage.removeItem(localstorage.authority_id4)
        localStorage.removeItem(localstorage.authority_id5)
        localStorage.removeItem(localstorage.preferred_username)
        localStorage.removeItem(localstorage.email)
        localStorage.removeItem(localstorage.username)
        const userPool = getUserPool();
        const user = userPool.getCurrentUser();
        if (user) {
            user.signOut();
            navigate('/')
        } else {
            navigate('/')
        }
    };

    //  change changeImage image
    const uploadImage = () => {

    }


    return (
        <div className={classes.headerBar}>
            <div className={classes.iconBlock} style={{ width: menuFlag ? VW(240) : '0', paddingLeft: menuFlag ? VW(16) : '0', backgroundColor: "#0A679A" }}>
                <img
                    src={logo}
                    style={{
                        width: VW(110),
                        height: VH(33),
                        color: "#ffffff"
                    }}
                />
                {/* 訪問実績入力システム */}
            </div>
            <div className={classes.menuBlock} style={{ width: menuFlag ? VW(893) : '100%' }}>
                <div className={classes.menuButton} onClick={changeMenuStatus}><HeaderBarIcon /></div>
                <div className={classes.rightSelect}>
                    <div>
                        <List
                        >
                            <ListItemButton style={{ display: "flex", justifyContent: 'center', position: 'relative' }} onClick={handleClick}>
                                <span style={{ fontSize: VW(15), color: "#333333", fontWeight: 400 }}>{localStorage.getItem(localstorage.preferred_username)}</span>
                                <div style={{ display: "flex", justifyContent: 'center', position: 'relative', marginLeft: VW(24) }}>
                                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </div>
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit style={{ position: 'absolute', width: '100%', zIndex: '9999' }}>
                                <List component="div" disablePadding>
                                    <Button
                                        // type="primary"
                                        className={classes.logoutButton}
                                        onClick={goToLogin}
                                    >ログアウト</Button>
                                </List>
                            </Collapse>
                        </List>
                    </div>
                </div>
            </div>
        </div>
    )
}

HeaderBar.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(HeaderBar);