import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { routerNames, localstorage } from '../../wordsFile/wordsFile'
import { getCurrSession } from "../../network/UserPool";
import { Input, DatePicker, message } from 'antd';
import { VW, VH } from "../../componets/function/sizeAdapter"

import { TextField } from "@mui/material";
import log from "../../componets/function/log";
import { CalendarToday, CloseRounded } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiTerminalStatusQueryList } from "../../network/api";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import pako from 'pako';
import Common_successAlert from "../../componets/common/Common_successAlert";
import Common_errorAlert from "../../componets/common/Common_errorAlert";

import {
    MenuItem, Select,
    List,
    ListItemButton,
    Collapse,
    Autocomplete
} from "@mui/material";
import { SearchRounded, FileDownloadRounded } from "@mui/icons-material";
import { ownerNo_Option, operating_Option, break_Option } from "../../wordsFile/selectItem";
import Pagination from "@mui/material/Pagination";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import iconv from 'iconv-lite';

const styles = (theme) => ({
    ground: {
        height: '100%',
        paddingTop: "8px",
        paddingLeft: "24px",
        paddingRight: "24px"
    },
    itemMonthlyHead: {
        color: "#27303D",
        fontSize: "24px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px"
    },
    itemInput: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
    page: {
        "& .MuiPaginationItem-root": {
            color: "#000000",
        }
    },
    autocomplete: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: "42px",
            padding: 0
        },
        '& .MuiInputBase-root': {
            height: "42px",
            backgroundColor: "#fff"
        },
    },
    scrollbar: {
        '& ::-webkit-scrollbar': {
            width: "20px",
            height: "20px"
        },
        '& ::-webkit-scrollbar-thumb': {
            borderRadius: "5px",
            backgroundColor: "#808080",
            height: "80px"
        }
    },
});


//Comparer Function 升順
function GetSortDownOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
//Comparer Function 降順
function GetSortUpOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}

function unzip(b64Data) {
    var strData = atob(b64Data);
    var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
    var binData = new Uint8Array(charData);
    // unzip
    var data = pako.inflate(binData);
    var strData = new TextDecoder("utf-8").decode(data);
    return decodeURIComponent(strData);
}

function TerminalStateInquiry(props) {
    const {
        classes,
        setSelectFlag,
        masterData
    } = props

    const location = useLocation();
    const navigate = useNavigate()

    const { state } = location;
    const [BulkRegistrationDialogOpen, setBulkRegistrationDialogOpen] = useState(false)
    const [gakoCode, setGakoCode] = useState(""); // 学校コード
    const [gakoName, setGakoName] = useState(""); // 学校名
    const [kairiNo, setKairiNo] = useState(""); // 管理番号
    const [azukariStatus, setAzukariStatus] = useState(true); // 預かり中のみ（中のみ場合、1を受け渡す）
    const [formDate, setFormDate] = useState(""); // 受付日_開始日
    const [toDate, setToDate] = useState(""); // 受付日_開始日

    const changeFormDateValue = (value) => {
        setFormDate(value)
    }

    const changeToDateValue = (value) => {
        setToDate(value)
    }
    const [listData, setListData] = useState();
    const [searchData, setSearchData] = useState();
    const [showData, setShowData] = useState();

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 500;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const setAzukariStatusInfo = (event) => {
        setAzukariStatus(event.target.checked)
        // if (event.target.checked == true) {
        //     setAzukariStatus('1')
        // } else {
        //     setAzukariStatus('0')
        // }
    }

    const getGakoList = () => {
        let list = []
        masterData.gako_info.map((item, index) => {
            if (item.tanmatsuKanriFuragu == "1") {
                list.push(item)
            }
        })
        return list;
    };
    const getSyojyoName = (code) => {
        let name = ""
        masterData.symptomclass_info.map((item, i) => {
            if (code == parseInt(item.no)) {
                name = item.symptomclass
            }
        })
        return name;
    };

    const [headerList, setHeaderList] = useState([
        {
            "name": "学校名",
            "value": null,
            "code": null,
            "data": getGakoList(),
            "width1": "180px",
            "key": "gako_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "受付日",
            "value": null,
            "code": null,
            "data": null,
            "width1": "105px",
            "key": "uketsuke_date",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "管理番号",
            "value": null,
            "code": null,
            "data": null,
            "width1": "150px",
            "key": "kanri_no",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "機器区分",
            "value": null,
            "code": null,
            "data": ownerNo_Option,
            "width1": "85px",
            "key": "kikikubun_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "症状",
            "value": null,
            "code": null,
            "data": null,
            "width1": "180px",
            "key": "syojyo",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "預かり作業状況",
            "value": null,
            "code": null,
            "data": operating_Option,
            "width1": "170px",
            "key": "azukari_work_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "破損届",
            "value": null,
            "code": null,
            "data": break_Option,
            "width1": "60px",
            "key": "funshitsu_check",
            "checkBox": true,
            "sortKey": "down"
        },
        {
            "name": "返却日",
            "value": null,
            "code": null,
            "data": null,
            "width1": "105px",
            "key": "henkyaku_date",
            "checkBox": false,
            "sortKey": "down"
        }
    ]);

    useEffect(() => {
        setSelectFlag(routerNames.TerminalStateInquiry)
        getCurrSession()
        if (localStorage.getItem(localstorage.authority_id4) != "1") {
            localStorage.clear()
            window.location.href = '/login'
        }
        getListData()
    }, [setSelectFlag])

    const [windowHeight, setWindowHeight] = useState(305);

    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    window.addEventListener('resize', () => {
        let height = 305
        let innerHeight = window.innerHeight
        if (innerHeight <= 720) {
            setWindowHeight(305);
        } else {
            while (innerHeight - 720 > 50) {
                height += 50;
                innerHeight -= 50;
            }
            setWindowHeight(height);
        }
    });

    const getListData = (date, changeDate) => {
        let params = {
            gako_code: !gakoCode ? '' : gakoCode,
            kairi_no: kairiNo,
            azukari_status: azukariStatus ? "1" : "0",
            form_date: formDate != '' ? formDate.format("YYYY-MM-DD") : '',
            to_date: toDate != '' ? toDate.format("YYYY-MM-DD") : ''
        }

        if (formDate != '' && toDate != '' && formDate > toDate) {
            setRrrorOpen(true)
            setErrorContent("終了日は開始日よりも先の日付に設定してください。")
            return
        }
        ApiTerminalStatusQueryList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                let resdatatemp = unzip(resData.terminal_info)
                resData.terminal_info = JSON.parse(resdatatemp)
                resData.terminal_info.map((item) => {
                    if (!item.henkyaku_date) {
                        item.henkyaku_date = ""
                    }
                })
                setListData(resData)
                setSearchData(resData)
                setCurrentPage(1)
                setShowData({ "terminal_info": resData.terminal_info.slice(startIndex, endIndex) })
            }
        }).catch(err => {
            setRrrorOpen(true)
            setErrorContent("検索処理は予期せぬエラーが発生しました。")
            return

        })
    }
    const [sortKey, setSortKey] = useState();
    //ソート順実現メソッド
    const onSortChange = (itemkey) => {
        if (showData?.terminal_info?.length > 0) {
            let tempSearchData = searchData
            let tempShowData = showData
            if (sortKey) {
                if (sortKey.key != itemkey) {
                    if (itemkey == "gako_code") {
                        tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                    } else {
                        tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                    }
                    setSortKey({ key: itemkey, sort: "up" })
                } else {
                    if (sortKey.sort == "up") {
                        if (itemkey == "gako_code") {
                            tempSearchData.terminal_info.sort((a, b) => b[`${itemkey}`] - a[`${itemkey}`]);
                        } else {
                            tempSearchData.terminal_info.sort((a, b) => b[`${itemkey}`].toString().localeCompare(a[`${itemkey}`]));
                        }
                        setSortKey({ key: itemkey, sort: "down" })
                    } else {
                        if (itemkey == "gako_code") {
                            tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                        } else {
                            tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                        }
                        setSortKey({ key: itemkey, sort: "up" })
                    }
                }
            } else {
                if (itemkey == "gako_code") {
                    tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                } else {
                    tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                }
                setSortKey({ key: itemkey, sort: "up" })
            }


            if (currentPage === 1) {
                tempShowData = {
                    "terminal_info": tempSearchData.
                        terminal_info.slice(0, endIndex)
                }
            } else {
                tempShowData = { "terminal_info": tempSearchData.terminal_info.slice(startIndex, endIndex) }
            }
            setSearchData({ ...tempSearchData })
            setShowData({ ...tempShowData })
        }
    };
    const handleChange = (e, p) => {
        setCurrentPage(p)
    };
    useEffect(() => {
        if (searchData) {
            if (currentPage === 1) {
                setShowData({ "terminal_info": searchData.terminal_info.slice(0, endIndex) })
            } else {
                setShowData({ "terminal_info": searchData.terminal_info.slice(startIndex, endIndex) })
            }
        }
    }, [currentPage])

    const getGakoAutoList = () => {
        let list = []
        masterData.gako_info.map((item, index) => {
            if (item.tanmatsuKanriFuragu == "1") {
                list.push({ "label": item.gakoMeisho, "value": item.gakoCd })
            }
        })
        return list;
    };
    const [divHeight, setHeight] = useState(100);
    function getDivHeightById(id) {
        const div = document.getElementById(id);
        if (div) {
            return div.clientHeight;
        } else {
            return null;
        }
    }
    useEffect(() => {
        const s1 = document.getElementById("s1");
        const s2 = document.getElementById("s2");
        const s3 = document.getElementById("s3");
        s1.addEventListener("scroll", () => {
            s2.scrollTop = s1.scrollTop;
        });

        s2.addEventListener("scroll", () => {
            s1.scrollTop = s2.scrollTop;
            s3.scrollLeft = s2.scrollLeft;
        });
        s3.addEventListener("scroll", () => {
            s2.scrollLeft = s3.scrollLeft;
        });
    }, [])

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { target, contentRect } = entry;
                setHeight(getDivHeightById('s2'))
            }
        });
        const targetDiv = document.getElementById('s2');
        observer.observe(targetDiv);
    }, [])

    const handleExport = () => {
        if (searchData.terminal_info.length > 0) {
            // let tempData = searchData.terminal_info
            // tempData.map(row => {
            //     if (row.key === "syojyo") { 
            //         tempData.syojyo = getSyojyoName()
            //     }
            //        .getSyojyoName
            // });
            const fields = headerList.map(header => header.key);
            const headers = headerList.map(header => header.name);

            const csvData = searchData.terminal_info.map(row => {
                let rowData = fields.map(
                    field => {
                        if (field === "syojyo") {
                            return getSyojyoName(row[field])
                        } else {
                            return row[field] !== undefined ? row[field] : ''
                        }
                    });
                return rowData;
            });

            const csv = Papa.unparse({
                fields: headers,
                data: csvData
            });
            const shiftJISData = iconv.encode(csv, 'Shift_JIS');

            const blob = new Blob([shiftJISData], { type: 'text/csv;charset=shift-jis;' });
            saveAs(blob, '端末状態問い合わせ結果.csv');
        }

    };

    return (
        <div className={classes.ground} style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.itemMonthlyHead} style={{ borderLeft: '5px solid #2195D3', fontWeight: 'bold' }}>
                {"端末状態 問い合わせ" + "（" + `${searchData ? searchData.terminal_info.length : "0"}` + "件 表示）"}
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={"24px"}
            />
            <div style={{ display: "flex", marginTop: "16px" }}>
                <div className={classes.itemInput} style={{ width: "264px", height: "42px" }}>
                    <Autocomplete
                        style={{ fontSize: "14px", width: "264px", height: "42px" }}
                        className={classes.autocomplete}
                        disablePortal
                        options={getGakoAutoList()}
                        value={gakoName || ""}
                        onChange={(event: any, newValue: string | null) => {
                            setGakoCode(newValue != null ? newValue.value : "");
                            setGakoName(newValue != null ? newValue.label : "");
                        }}
                        renderInput={(params) => <TextField {...params} size="small"
                            placeholder="学校名"
                        />}
                    />
                </div>

                <div className={classes.itemInput} style={{ marginLeft: "8px", width: "240px", height: "42px" }}>
                    <Input
                        style={{ fontSize: "14px", height: "42px", width: "240px" }}
                        value={kairiNo || ""}
                        onChange={(event) => setKairiNo(event.target.value)}
                        placeholder="管理番号"
                    />
                </div>
                <div className={classes.itemInput} style={{ marginLeft: "30px", width: "240px", height: "42px" }}>
                    <FormControlLabel control={<Checkbox style={{ fontSize: "14px", width: "24px", height: "24px" }} onChange={setAzukariStatusInfo} checked={azukariStatus} />} label="預かり中のみ" />
                </div>
            </div>
            <div style={{ display: "flex", marginTop: "16px" }}>
                <div>
                    <DatePicker
                        suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                        allowClear={true}
                        clearIcon={
                            <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                        }
                        style={{ fontSize: "14px", width: "240px", height: "42px" }}
                        onChange={changeFormDateValue}
                        value={formDate}
                        placeholder='受付日 絞り込み'
                        format="YYYY/M/D"

                    />
                </div>
                <div style={{ alignItems: 'center', textAlign: "center", width: "32px", height: "42px" }}>
                    <span style={{ fontSize: "30px" }}>~</span>
                </div>
                <DatePicker
                    suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                    allowClear={true}
                    clearIcon={
                        <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                    }
                    style={{ fontSize: "14px", width: "240px", height: "42px" }}
                    onChange={changeToDateValue}
                    value={toDate}
                    placeholder='受付日 絞り込み'
                    format="YYYY/M/D"
                />
                <div onClick={getListData} style={{ borderRadius: "0px 4px 4px 0px", marginLeft: "24px", width: "47px", height: "41px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1D3C70" }}>
                    <SearchRounded style={{ color: "#ffffff", width: "24px", height: "24px" }}></SearchRounded>
                </div>
                <div onClick={handleExport} style={{ borderRadius: "4px", width: "50px", height: "42px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffffff", border: "1px solid #2095D4", marginLeft: "8px", cursor: "pointer" }}>
                    <FileDownloadRounded style={{ color: "#2095D4", width: "24px", height: "24px" }}></FileDownloadRounded>
                </div>
            </div>

            <div className={classes.scrollbar} style={{ display: "flex", maxHeight: "100%", overflowY: "hidden", maxWidth: "100%", width: "100%" }}>
                <div id="left" style={{ maxWidth: "100%", borderLeft: "1px solid #E0E0E0", }}>
                    <div style={{ marginTop: "24px", backgroundColor: "#27303D", width: '40px', height: "40px", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontWeight: 700, fontSize: "14px", borderRight: "1px solid #E0E0E0", }}>
                        No.
                    </div>
                    <div id="s1" className="scroll_sync" style={{ overflow: "hidden", height: divHeight }}>
                        {showData && showData.terminal_info.map((item, listIndex) => {
                            let backgroundColor = (listIndex + 1) % 2 === 1 ? "#FFFFFF" : "#F4F5F9"
                            return (
                                <div key={listIndex} style={{ backgroundColor: backgroundColor, width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontWeight: 400, fontSize: "11px", paddingLeft: "2px", borderBottom: "1px solid #E0E0E0", borderRight: "1px solid #E0E0E0", }}>
                                    {listIndex + 1 + (currentPage - 1) * 500}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div id="right" style={{ display: "flex", flexDirection: "column", flex: 1, overflowY: "hidden", maxWidth: "100%", width: "100%" }}>
                    <div id="s3" style={{ marginRight: "20px", marginTop: "24px", display: "flex", overflowX: showData?.terminal_info?.length > 0 ? "hidden" : "auto", minHeight: "40px", paddingRight: 8 }}>
                        {headerList.map((item, headerIndex) => {
                            return (
                                <div key={headerIndex} style={{ minHeight: "40px" }}>
                                    <div onClick={() => onSortChange(item.key)} style={{ backgroundColor: "#27303D", minWidth: item.width1, width: item.width1, minHeight: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0", color: "#fff", fontWeight: 700, fontSize: "14px" }}>
                                        {item.name}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div id="s2" style={{ overflowX: "auto", }}>
                        {showData && showData.terminal_info.map((item, listIndex) => {
                            let backgroundColor = (listIndex + 1) % 2 === 1 ? "#FFFFFF" : "#F4F5F9"
                            return (
                                <div key={listIndex} style={{ display: "flex" }}>
                                    {headerList.map((row, headerIndex) => {
                                        let itemborderLeft = headerIndex === 0 ? "1px solid #E0E0E0" : "0px solid #E0E0E0"
                                        return (
                                            <div key={headerIndex}>
                                                <div style={{ backgroundColor: backgroundColor, minWidth: row.width1, width: row.width1, height: "40px", display: "flex", borderRight: "1px solid #E0E0E0", color: "#27303D", fontWeight: 400, fontSize: "14px", borderBottom: "1px solid #E0E0E0", paddingLeft: row.checkBox ? 0 : "8px" }}>
                                                    <div style={{
                                                        width: '100%', height: '100%', overflow: 'hidden',
                                                        textOverflow: 'ellipsis', display: headerIndex === headerList.length - 1 ? "block" : "flex", overflowWrap: 'break-word', alignItems: "center", justifyContent: row.checkBox ? "center" : ""
                                                    }}>
                                                        {row.checkBox ?
                                                            <Checkbox sx={{ color: "#E0E0E0", width: "24px", height: "24px", display: item[row.key] == false ? "none" : "" }} style={{ color: "#000000" }} checked={item[row.key] == false ? false : true} />
                                                            :
                                                            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                {headerIndex == 4 ? getSyojyoName(item[row.key]) : item[row.key]}
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div >
            <Pagination
                page={currentPage}
                className={classes.page}
                count={searchData ? Math.ceil(searchData?.terminal_info?.length / 500) : 0}
                color="buttonBLue"
                onChange={handleChange}
                style={{
                    display: "flex", justifyContent: "center", marginBottom: 8, marginTop: 8
                }}
            />
        </div >
    )
}

TerminalStateInquiry.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TerminalStateInquiry);