import { withStyles } from "@mui/styles";

const styles = theme => ({
  "@global": {
    body:{
      backgroundColor: '#ffffff',
      fontWeight: 500,
      fontFamily: 'Noto Sans',
    },
  }
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
