import React, { useState } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"
import sortIcon from "../../componets/icon/sortIcon.svg"
import supportIcon from "../icon/supportIcon.svg"
import deleteIcon from "../../componets/icon/deleteIcon.svg"
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
// import Input from "antd/es/input/Input";
// import TextArea from "antd/es/input/TextArea";
import { Input } from 'antd';
import { support_kubunOption, statusOption } from "../../wordsFile/selectItem";

const { TextArea } = Input;


const styles = (theme) => ({
    bar: {
        display: "flex",
        alignItems: "center",
        height: VH(64),
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #E0E0E0',
        justifyContent: 'space-between',
        paddingLeft: VW(16),
        paddingRight: VW(16),
    },
    icon: {
        width: VW(32),
        height: VH(32),
        marginRight: VW(24)
    },
    detailTable: {
        backgroundColor: "#ffffff",
        paddingTop: VH(24),
        paddingBottom: VH(24),

    },
    item: {
        display: "flex",
        alignItems: "center",
        marginLeft: VW(24),
        marginRight: VW(24)
    },
    itemText: {
        color: "#27303D",
        fontSize: VW(12),
        fontWeight: 700,
        width: VW(130),
        height: VH(88),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        borderTop: '1px solid #E0E0E0',
    },
    itemInput: {
        width: VW(235),
        height: VH(88),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        fontSize: VW(12),
        borderTop: '1px solid #E0E0E0',
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& > Button': {
            width: VW(219),
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    }
});

function SupportView(props) {
    const {
        classes,
        visitData,
        setVisitData,
        index,
        item,
        updateVisitData,
        masterData,
        supportItem,
        supportIndex,
        updateVisitSupportData,
        deleteSupportData

    } = props




    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#9E9E9E"
        }
    }));

    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };

    const setJigyo_kubunlabel = () => {
        let jigyo_kubunLable = ""
        if (visitData?.homon_jiseki[index]?.jigyo_kubun) {
            masterData.jigyoKubunOptionList.map((rows) => {
                if (rows.value == visitData?.homon_jiseki[index]?.jigyo_kubun) {
                    jigyo_kubunLable = rows.label;
                }
            })
        }
        return jigyo_kubunLable;
    };
    return (
        <div style={{ boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD' }}>
            <div style={{ marginTop: VH(24), width: "100%" }}>
                <div className={classes.bar} onClick={() => updateVisitSupportData(supportItem.expand === 0 ? 1 : 0, index, supportIndex, 'expand')}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <img
                            src={sortIcon}
                            className={classes.icon}
                        />
                        <img
                            src={supportIcon}
                            className={classes.icon} style={{ marginRight: VW(8) }}
                        />
                        <div
                            style={{
                                color: "#053550",
                                fontWeight: 700,
                                fontSize: VW(20)
                            }}> {"支援内容 " + (supportIndex + 1).toString()}</div>
                    </div>
                    {item.expand === 0 ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
                </div>
                <div className={classes.detailTable} style={{ display: supportItem.expand === 0 ? "" : "none", }}>
                    <div className={classes.item}>
                        <div className={classes.itemText}>支援区分</div>
                        <div className={classes.itemInput}>
                            <Select
                                className={classes.select}
                                style={{
                                    width: VW(219), height: VH(56), borderRadius: VW(6)

                                }}
                                value={Object.keys(visitData) !== 0 ? supportItem.sienkubun_code || "" : ""}
                                label=""
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'sienkubun_code')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) !== 0) &&
                                        supportItem.sienkubun_code !== null &&
                                        supportItem.sienkubun_code !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {support_kubunOption.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className={classes.itemText}>教科</div>
                        <div className={classes.itemInput}>
                            <Select
                                className={classes.select}
                                style={{
                                    width: VW(219), height: VH(56), borderRadius: VW(6)

                                }}
                                value={Object.keys(visitData) !== 0 ? supportItem.kyoka_code || "" : ""}
                                label=""
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'kyoka_code')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) !== 0) &&
                                        supportItem.kyoka_code !== null &&
                                        supportItem.kyoka_code !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {
                                    masterData.subject_info.map((item, index) => {
                                        if (setJigyo_kubunlabel() == item.jigyoKubun) {
                                            return (
                                                <MenuItem key={index} value={item.no}>{item.kyoka}</MenuItem>
                                            )

                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </Select>
                        </div>
                    </div>

                    <div className={classes.item}>
                        <div className={classes.itemText}>学年</div>
                        <div className={classes.itemInput}>
                            <Select
                                className={classes.select}
                                style={{
                                    width: VW(219), height: VH(56), borderRadius: VW(6)

                                }}
                                value={Object.keys(visitData) !== 0 ? supportItem.gakunen_code || "" : ""}
                                label=""
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'gakunen_code')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) !== 0) &&
                                        supportItem.gakunen_code !== null &&
                                        supportItem.gakunen_code !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {masterData.gakunen_info.map((item, index) => {
                                    if (setJigyo_kubunlabel() == item.jigyoKubun) {
                                        return (
                                            <MenuItem key={index} value={item.no}>{item.gakunen}</MenuItem>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </Select>
                        </div>
                        <div className={classes.itemText}>依頼者</div>
                        <div className={classes.itemInput}>
                            <Input
                                style={{ height: VH(56), marginTop: VH(8), width: VW(219), fontSize: VW(12) }}
                                value={supportItem.requester || ""}
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'requester')}
                                maxLength={60}
                                placeholder="依頼者を入力してください"
                            />
                        </div>
                    </div>

                    <div className={classes.item}>
                        <div className={classes.itemText}>ICT環境1</div>
                        <div className={classes.itemInput}>
                            <Select
                                className={classes.select}
                                style={{
                                    width: VW(219), height: VH(56), borderRadius: VW(6)

                                }}
                                value={Object.keys(visitData) !== 0 ? supportItem.ict_kankyo_code1 || "" : ""}
                                label=""
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'ict_kankyo_code1')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) !== 0) &&
                                        supportItem.ict_kankyo_code1 !== null &&
                                        supportItem.ict_kankyo_code1 !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {masterData.ictEnvironment_info.map((item, index) => {
                                    if ((setJigyo_kubunlabel() == item.jigyoKubun)) {

                                        return (
                                            <MenuItem key={index} value={item.no}>{item.ictKankyou}</MenuItem>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </Select>
                        </div>
                        <div className={classes.itemText}>ICT環境2</div>
                        <div className={classes.itemInput}>
                            <Select
                                className={classes.select}
                                style={{
                                    width: VW(219), height: VH(56), borderRadius: VW(6)

                                }}
                                value={Object.keys(visitData) !== 0 ? supportItem.ict_kankyo_code2 || "" : ""}
                                label=""
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'ict_kankyo_code2')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) !== 0) &&
                                        supportItem.ict_kankyo_code2 !== null &&
                                        supportItem.ict_kankyo_code2 !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {masterData.ictEnvironment_info.map((item, index) => {

                                    if ((setJigyo_kubunlabel() == item.jigyoKubun)) {

                                        return (
                                            <MenuItem key={index} value={item.no}>{item.ictKankyou}</MenuItem>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </Select>
                        </div>
                    </div>

                    <div className={classes.item}>
                        <div className={classes.itemText}>ソフトウェア1</div>
                        <div className={classes.itemInput}>
                            <Select
                                className={classes.select}
                                style={{
                                    width: VW(219), height: VH(56), borderRadius: VW(6)

                                }}
                                value={Object.keys(visitData) !== 0 ? supportItem.software_code1 || "" : ""}
                                label=""
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'software_code1')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) != 0) &&
                                        supportItem.software_code1 !== null &&
                                        supportItem.software_code1 !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {masterData.software_info.map((item, index) => {

                                    if ((setJigyo_kubunlabel() == item.jigyoKubun)) {

                                        return (
                                            <MenuItem key={index} value={item.no}>{item.software}</MenuItem>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </Select>
                        </div>
                        <div className={classes.itemText}>ソフトウェア2</div>
                        <div className={classes.itemInput}>
                            <Select
                                className={classes.select}
                                style={{
                                    width: VW(219), height: VH(56), borderRadius: VW(6)

                                }}
                                value={Object.keys(visitData) !== 0 ? supportItem.software_code2 || "" : ""}
                                label=""
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'software_code2')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) != 0) &&
                                        supportItem.software_code2 !== null &&
                                        supportItem.software_code2 !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {masterData.software_info.map((item, index) => {
                                    if ((setJigyo_kubunlabel() == item.jigyoKubun)) {

                                        return (
                                            <MenuItem key={index} value={item.no}>{item.software}</MenuItem>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </Select>
                        </div>
                    </div>

                    <div className={classes.item}>
                        <div className={classes.itemText} style={{ height: VH(114) }}>具体的内容</div>
                        <div className={classes.itemInput} style={{ height: VH(114), width: VW(596) }}>
                            <TextArea
                                style={{ height: VH(84), marginTop: VH(8), width: VW(564), fontSize: VW(12) }}
                                value={supportItem.support || ""}
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'support')}
                                maxLength={100}
                                placeholder="具体的内容を入力してください"
                            />
                        </div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.itemText} style={{ borderBottom: '1px solid #E0E0E0', }}>状況</div>
                        <div className={classes.itemInput} style={{ borderBottom: '1px solid #E0E0E0', }}>
                            <Select
                                className={classes.select}
                                style={{
                                    width: VW(219), height: VH(56), borderRadius: VW(6)

                                }}
                                value={Object.keys(visitData) !== 0 ? supportItem.status || "" : ""}
                                label=""
                                onChange={(event) => updateVisitSupportData(event.target.value, index, supportIndex, 'status')}
                                displayEmpty
                                renderValue={
                                    (Object.keys(visitData) != 0) &&
                                        supportItem.status !== null &&
                                        supportItem.status !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                }
                            >
                                {statusOption.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <div style={{ marginTop: VH(24), width: "100%", borderBottom: "1px solid #E0E0E0" }}></div>
                    <div style={{ marginTop: VH(24), width: "100%", display: "flex", alignItems: "center", justifyContent: "center", fontSize: VW(18), fontWeight: 400, color: "#D42020" }}
                        onClick={() => { deleteSupportData(index, supportIndex) }}>
                        支援内容の削除
                        <img
                            style={{ width: VW(24), height: VH(24), marginLeft: VW(8) }}
                            src={deleteIcon}

                        />
                    </div>

                </div>

            </div>
        </div >
    )
}

SupportView.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(SupportView);