const timeFormat = {

    // HH:MM:SS  =>  s
    time_to_sec(time) {
        if (time.split(':')[2]) {
            var hour = time.split(':')[0];
            var min = time.split(':')[1];
            var sec = time.split(':')[2];
            var s = Number(hour * 3600) + Number(min * 60) + Number(sec);
            return s
        } else {
            var min = time.split(':')[0];
            var sec = time.split(':')[1];
            var s = Number(min * 60) + Number(sec);
            return s
        }
    }
    ,
    // yyyy-MM-dd  =>  yyyy年MM月dd日
    strDay_to_strDay(strTime) {
        if (strTime.split('-')[2]) {
            var yyyy = strTime.split('-')[0];
            var mm = strTime.split('-')[1];
            var dd = strTime.split('-')[2];
            var outStrDay = yyyy + "年" + mm + "月" + dd + "日";
            return outStrDay
        } else {
            if (strTime.length == 6) {
                var yyyy = strTime.slice(0, 4);
                var mm = strTime.slice(-2);
                var outStrDay = yyyy + "年" + mm + "月";
                return outStrDay

            } else {
                var yyyy = strTime.split('-')[0];
                var mm = strTime.split('-')[1];
                var outStrDay = yyyy + "年" + mm + "月";
                return outStrDay
            }
        }
    }
    ,
    // yyyy-MM-dd  =>  yyyy/MM
    strDay_to_strYearMonth(strTime, formatStr) {
        if (strTime.split('-')[2]) {
            var yyyy = strTime.split('-')[0];
            var mm = strTime.split('-')[1];
            var dd = strTime.split('-')[2];
            var outStrDay = mm + formatStr + dd;
            return outStrDay
        }
    }


}
export default timeFormat