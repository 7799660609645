import React, { useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, DatePicker, Input } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter";
import { IconButton, Autocomplete } from "@mui/material";
import { Close, ContentPasteGoSharp } from "@mui/icons-material";
import sortIcon from "../../componets/icon/sortIcon.svg"
import deleteIcon from "../../componets/icon/deleteIcon.svg"
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { MenuItem, Select, TextField, Checkbox } from "@mui/material";
import log from "../../componets/function/log";
import { TabletMacRounded } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmDialog from "./ConfirmDialog";
import StatusToLostDialog from "./StatusToLostDialog";
import LostToStatusDialog from "./LostToStatusDialog";
import Common_successAlert from "../../componets/common/Common_successAlert";
import { getSession, getUserPool, getCurrSession } from "../../network/UserPool";
import AddIcon from "../../componets/icon/AddIcon";
import {
    type_Option,
    ownerNo_Option,
    status_Option,
    kikikubun_code_Option,
    azukari_code_Option,
    operating_Option,
    shuri_ipad_code_Option,
    funshitsu_mode_Option,
    icchi_info_Option,
    mitekiyo_idou_Option
} from "../../wordsFile/selectItem";
import { CalendarToday, CloseRounded } from "@mui/icons-material";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import { routerNames } from "../../wordsFile/wordsFile";
import Pagination from "@mui/material/Pagination";
import arrowLeft from "../../componets/icon/arrowLeft.svg";
import arrowRight from "../../componets/icon/arrowRight.svg";
import { ApiTerminalDetailInfo, ApiTerminalDetailAddInfo, ApiTerminalDetailUpdateInfo } from "../../network/api";
const { TextArea } = Input;
dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: VH(40),
        paddingBottom: VH(5),
        '& > Button': {
            width: VW(400),
            backgroundColor: "#2095D4",
            color: "#fff",
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            border: `1px solid #2195D3`,
        }
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24),
        marginBottom: VH(36),
        '& > Button': {
            width: VW(533),
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    },
    buttonGroup3: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16)
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    bar: {
        display: "flex",
        alignItems: "center",
        height: VH(64),
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #E0E0E0',
        justifyContent: 'space-between',
        paddingLeft: VW(16),
        paddingRight: VW(16),
    },
    icon: {
        width: VW(32),
        height: VH(32),
        marginRight: VW(24)
    },
    detailTable: {
        backgroundColor: "#ffffff",
        paddingBottom: VH(24),

    },
    item: {
        display: "flex",
        alignItems: "center",
        marginLeft: VW(24),
        marginRight: VW(24)
    },
    itemText: {
        color: "#27303D",
        fontSize: VW(12),
        fontWeight: 700,
        width: VW(130),
        height: VH(88),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        borderTop: '1px solid #E0E0E0',
    },
    itemInput: {
        width: VW(235),
        height: VH(88),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        fontSize: VW(12),
        borderTop: '1px solid #E0E0E0',
    },
    itemInputInner: {
        height: VH(56),
        width: VW(219),
        fontSize: VW(12)
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
    header: {
        width: VW(845),
        color: "#27303D",
        fontSize: VW(24),
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        paddingLeft: VW(10),
        margin: VW(24),
        // marginRight: VW(24)
    },
    itemLeftKey: {
        width: VW(56),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginRight: VW(8),
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
    itemRightKey: {
        width: VW(56),
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginLeft: VW(8),
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
    autocomplete: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(56),
            padding: 0
        },
        // '& .MuiInputBase-input': {
        //     height: "26px",
        // },
        '& .MuiInputBase-root': {
            height: VH(56),
            backgroundColor: "#fff"
        },
        // '& .MuiAutocomplete-input': {
        //     fontSize: "16px",
        //     height: "26px",
        //     border: '0',
        // },
        // '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
        //     backgroundColor: "rgba(0, 0, 0, 0.04)",
        // },
    },
});


function TerminalDetail(props) {
    const {
        classes,
        setSelectFlag,
        masterData

    } = props
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [stateData, setStateData] = useState();
    const [terminalId, setTerminalId] = useState();
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    const [expand1, setExpand1] = useState(false);
    const [expand2, setExpand2] = useState(false);
    const [expand3, setExpand3] = useState(false);
    const [expand4, setExpand4] = useState(false);
    const [expandList, setExpandList] = useState([]);
    const [deletedList, setDeletedList] = useState([]);
    const [comparedData, setComparedData] = useState(null)
    const [data, setData] = useState(null)
    const [isSame, setIsSame] = useState(true)

    const [isTerminalInfoDataSame, setIsTerminalInfoDataSame] = useState(true)

    const [isData1DateSame, setIsData1DateSame] = useState(true)
    const [isData2DateSame, setIsData2DateSame] = useState(true)
    const [isData3DateSame, setIsData3DateSame] = useState(true)

    const [isData1Same, setIsData1Same] = useState(true)
    const [isData2Same, setIsData2Same] = useState(true)
    const [isData3Same, setIsData3Same] = useState(true)

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [dialogVal, setDialogVal] = useState()

    const [statusToLostDialogOpen, setStatusToLostDialogOpen] = useState(false)
    const [statusToLostDialogVal, setStatusToLostDialogVal] = useState()

    const [lostToStatusDialogOpen, setLostToStatusDialogOpen] = useState(false)
    const [lostToStatusDialogVal, setLostToStatusDialogVal] = useState()

    // statusToLostDialog
    const statusToLostDialogOnClose = () => {
        setStatusToLostDialogVal("")
        setStatusToLostDialogOpen(false)
    }
    const statusToLostDialogOnOpen = () => {
        setStatusToLostDialogOpen(true)
    }
    const statusToLostDialogOnDisable = () => {
        data.terminal_info["jyotai_no"] = statusToLostDialogVal
        setData({ ...data })
        setStatusToLostDialogOpen(false)
    }
    const statusToLostDialogOnConfirm = () => {
        if (statusToLostDialogVal === "3") {
            data.terminal_info["jyotai_no"] = statusToLostDialogVal
            data.funshitsu_info["funshitsu_check"] = true
            data.funshitsu_info["newflag"] = 0
            setData({ ...data })
        } else {
            data.terminal_info["jyotai_no"] = statusToLostDialogVal
            data.funshitsu_info["funshitsu_check"] = false
            // data.funshitsu_info["newflag"] = 0
            setData({ ...data })
        }

        setStatusToLostDialogOpen(false)
    }

    // lostToStatusDialog
    const lostToStatusDialogOnClose = () => {
        setLostToStatusDialogVal("")
        setLostToStatusDialogOpen(false)
    }
    const lostToStatusDialogOnOpen = () => {
        setLostToStatusDialogOpen(true)
    }
    const lostToStatusDialogOnDisable = () => {
        if (lostToStatusDialogVal === true) {
            data.funshitsu_info["funshitsu_check"] = true
            data.funshitsu_info.funshitsu_date = dayjs().format("YYYY-MM-DD")
            data.funshitsu_info.terminal_id = data.terminal_info.terminal_id
            data.funshitsu_info.newflag = 0
            setData({ ...data })
        } else {
            data.funshitsu_info["funshitsu_check"] = false
            data.funshitsu_info.renraku_date = null
            data.funshitsu_info.tantosha = null
            data.funshitsu_info.kyodeji_tantosha = null
            data.funshitsu_info.help_tantosha = null
            data.funshitsu_info.funshitsu_date = null
            data.funshitsu_info.hakken_date = null
            data.funshitsu_info.funshitsu_jyokyo = null
            data.funshitsu_info.funshitsu_mode = null
            data.funshitsu_info.icchi_info = null
            data.funshitsu_info.mitekiyo_idou = null
            data.funshitsu_info.saishu_jyokyo = null
            data.funshitsu_info.biko = null
            data.funshitsu_info.terminal_id = comparedData.funshitsu_info.terminal_id
            data.funshitsu_info.newflag = comparedData.funshitsu_info.newflag
            setData({ ...data })
        }
        setLostToStatusDialogOpen(false)
    }
    const lostToStatusDialogOnConfirm = () => {
        if (lostToStatusDialogVal === true) {
            data.terminal_info["jyotai_no"] = "3"
            data.funshitsu_info["funshitsu_check"] = true
            data.funshitsu_info.funshitsu_date = dayjs().format("YYYY-MM-DD")
            data.funshitsu_info.terminal_id = data.terminal_info.terminal_id
            data.funshitsu_info.newflag = 0
            setData({ ...data })
        } else {
            data.terminal_info["jyotai_no"] = "2"
            data.funshitsu_info["funshitsu_check"] = false
            data.funshitsu_info.renraku_date = null
            data.funshitsu_info.tantosha = null
            data.funshitsu_info.kyodeji_tantosha = null
            data.funshitsu_info.help_tantosha = null
            data.funshitsu_info.funshitsu_date = null
            data.funshitsu_info.hakken_date = null
            data.funshitsu_info.funshitsu_jyokyo = null
            data.funshitsu_info.funshitsu_mode = null
            data.funshitsu_info.icchi_info = null
            data.funshitsu_info.mitekiyo_idou = null
            data.funshitsu_info.saishu_jyokyo = null
            data.funshitsu_info.biko = null
            data.funshitsu_info.terminal_id = comparedData.funshitsu_info.terminal_id
            data.funshitsu_info.newflag = comparedData.funshitsu_info.newflag
            setData({ ...data })
        }

        setLostToStatusDialogOpen(false)
    }

    const confirmDialogOnClose = () => {
        setConfirmDialogOpen(false)
    }
    const confirmDialogOnOpen = (value) => {
        setDialogVal(value)
        setConfirmDialogOpen(true)
    }
    const confirmDialogOnDisable = () => {
        nextPage(dialogVal)
        setConfirmDialogOpen(false)
    }
    const confirmDialogOnConfirm = (next) => {
        setConfirmDialogOpen(false)
        if (checkTerminalData() && checkStoragetanmatsuData() && checkFunshitsuData() && checkIncidentData() && checkKikikubunCode()) {
            let callAdd = false;
            let callUpdate = true;

            if (!isTerminalInfoDataSame) {
                callAdd = true
            } else {
                // callAdd = false
                if (!isData1DateSame) {
                    callAdd = true;
                } else if (!isData1Same) {
                    callUpdate = true;
                }

                if (!isData2DateSame) {
                    callAdd = true;
                } else if (!isData2Same) {
                    callUpdate = true;
                }

                if (!isData3DateSame) {
                    callAdd = true;
                } else if (!isData3Same) {
                    callUpdate = true;
                }

            }


            if (callAdd) {
                addInfo(next)
            } else {
                updateInfo(next);
            }
        } else {
            document.getElementById('father_Block').scrollTop = 0;
            setRrrorOpen(true)
            if (checkKikikubunCode()) {
                setErrorContent("*のつく項目は必須入力項目です。")
            } else {
                setErrorContent("機器区分が重複しているため登録できません。")
            }
        }
    }
    const addInfo = (next) => {
        let temp = data
        data.storagetanmatsu_info.map((item, index) => {
            if (item.storage_check == false) {
                temp.storagetanmatsu_info.splice(index, 1)
            }
        })
        let temp2 = temp.storagetanmatsu_info.concat(deletedList);
        temp.storagetanmatsu_info = temp2

        ApiTerminalDetailAddInfo(temp).then(res => {
            let resData = res.data
            if (res.status === 200) {
                setSuccessOpen(true)
                setSuccessContent("端末情報を登録しました。")
                document.getElementById('father_Block').scrollTop = 0;
                let tempState = stateData
                tempState.idList[currentPage - 1].terminalId = resData.terminal_id

                // const queryParams = new URLSearchParams(window.location.search);
                // const paramValue = queryParams.get('id');
                // if (paramValue !== null) {
                //     const decodedUrlEncodedData = decodeURIComponent(paramValue);
                //     const decodedOriginalData = decodeURIComponent(escape(atob(decodedUrlEncodedData)));
                //     let decodedData = JSON.parse(decodedOriginalData);
                //     decodedData.idList[currentPage - 1].terminalId = resData.terminal_id
                //     const jsonString = JSON.stringify(decodedData);
                //     const base64EncodedData = btoa(unescape(encodeURIComponent(jsonString)));
                //     const urlEncodedData = encodeURIComponent(base64EncodedData);
                //     window.location.href(`${window.location.origin}/home/terminalDetail?id=${urlEncodedData}`, '_blank');
                // } else if (state) {
                //     navigate(routerNames.TerminalDetail, { state: { idList: terminalId, from: "single" } })
                // }
                if (next === "true") {
                    nextPage(dialogVal)
                } else {
                    getData(tempState?.idList[currentPage - 1].terminalId)
                }
                setStateData({ ...tempState })
            } else {
                document.getElementById('father_Block').scrollTop = 0;
                setRrrorOpen(true)
                setErrorContent(resData.detail)
            }
        }).catch(err => {

        })
    }
    const updateInfo = (next) => {
        let temp = data
        data.storagetanmatsu_info.map((item, index) => {
            if (item.storage_check == false) {
                temp.storagetanmatsu_info.splice(index, 1)
            }
        })
        let temp2 = temp.storagetanmatsu_info.concat(deletedList);
        temp.storagetanmatsu_info = temp2
        ApiTerminalDetailUpdateInfo(temp).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                setSuccessOpen(true)
                setSuccessContent("端末情報を登録しました。")
                document.getElementById('father_Block').scrollTop = 0;
                let tempState = stateData
                if (resData.terminal_id != 0) {
                    tempState.idList[currentPage - 1].terminalId = resData.terminal_id
                    setStateData({ ...tempState })
                    // const queryParams = new URLSearchParams(window.location.search);
                    // const paramValue = queryParams.get('id');
                    // if (paramValue !== null) {
                    //     const decodedUrlEncodedData = decodeURIComponent(paramValue);
                    //     const decodedOriginalData = decodeURIComponent(escape(atob(decodedUrlEncodedData)));
                    //     let decodedData = JSON.parse(decodedOriginalData);
                    //     decodedData.idList[currentPage - 1].terminalId = resData.terminal_id
                    //     const jsonString = JSON.stringify(decodedData);
                    //     const base64EncodedData = btoa(unescape(encodeURIComponent(jsonString)));
                    //     const urlEncodedData = encodeURIComponent(base64EncodedData);
                    //     window.location.href(`${window.location.origin}/home/terminalDetail?id=${urlEncodedData}`, '_blank');
                    // } else if (state) {
                    //     navigate(routerNames.TerminalDetail, { state: { idList: terminalId, from: "single" } })
                    // }
                }

                if (next === "true") {
                    nextPage(dialogVal)
                } else {
                    getData(stateData?.idList[currentPage - 1].terminalId)
                }
            }
        }).catch(err => {

        })
    }
    let storageEmptyData = {
        "storage_terminal_id": null,
        "terminal_id": null,
        "storage_check": true,
        "uketsuke_date": null,
        "kikikubun_code": null,
        "syojyobunrui_code": null,
        "syojyo": null,
        "tantosha": null,
        "kairi_no_before": null,
        "mac_address_before": null,
        "serial_num_before": null,
        "mochikomi_date": null,
        "azukari_code": null,
        "azukari_work_status": null,
        "funshitsu_check": false,
        "henkyaku_taiousha": null,
        "henkyakuyotei_date": null,
        "henkyaku_date": null,
        "mikasa_hikiwatashi_date": null,
        "mikasa_henkyaku_date": null,
        "mobi_iraiidou_date": null,
        "vpp_gako_date": null,
        "vpp_shoki_date": null,
        "asm_mobi_kaijo_date": null,
        "mobi_sakujo_date": null,
        "kairi_no_after": null,
        "mac_address_after": null,
        "serial_num_after": null,
        "mac_irai_date": null,
        "ipad_daicho_date": null,
        "ipad_mobi_date": null,
        "shoki_date": null,
        "mobi_gakoidou_date": null,
        "shuri_kanri_no": null,
        "shuri_passcode": null,
        "shuri_ipad_code": null,
        "biko": null,
        "gako_renraku_check": false,
        "newflag": null,
        "create_id": null,
        "update_id": null,
        "delete_flag": false
    }
    useEffect(() => {
        getCurrSession()
        const queryParams = new URLSearchParams(window.location.search);
        const paramValue = queryParams.get('id');
        if (paramValue !== null) {
            // const decodedString = atob(paramValue);
            const decodedUrlEncodedData = decodeURIComponent(paramValue);
            const decodedOriginalData = decodeURIComponent(escape(atob(decodedUrlEncodedData)));
            const decodedData = JSON.parse(decodedOriginalData);
            setTerminalId(decodedData?.idList[0].terminalId)
            setStateData(decodedData)
            getData(decodedData?.idList[currentPage - 1].terminalId)
        } else if (state) {
            setTerminalId(state?.idList[0].terminalId)
            setStateData(state)
            getData(state?.idList[currentPage - 1].terminalId)
        } else {
            navigate(routerNames.TerminalManage)
        }

        setSelectFlag(routerNames.TerminalManage)
    }, [setSelectFlag])
    useEffect(() => {
        if (!masterData) {
            localStorage.clear()
            window.location.href = '/login'
        }
    }, [])

    useEffect(() => {
    }, [data])
    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#9E9E9E"
        }
    }));
    useEffect(() => {
        if (data && data.terminal_info && data.storagetanmatsu_info.length === 0) {
            // 初期表示時に預かり情報を1件追加
            let temp = data;
            let temp2 = { ...storageEmptyData };
            temp2.storage_check = false; // 預かり中はfalseに設定
            temp.storagetanmatsu_info.push(temp2);
            setData({ ...temp });
            setComparedData(JSON.parse(JSON.stringify(temp)));
        }
    }, [data]);

    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };
    const getData = (terminalId) => {
        let params = {
            terminal_id: terminalId,
        }
        ApiTerminalDetailInfo(params).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                let temp = {
                    "terminal_info": resData.terminal_info,
                    "storagetanmatsu_info": resData.storagetanmatsu_info,
                    "funshitsu_info": resData.funshitsu_info,
                    "incident_info": resData.incident_info
                }
                let list = []
                resData.storagetanmatsu_info.map((item, index) => {
                    list.push(false)
                })
                setExpandList(list)
                setComparedData(JSON.parse(JSON.stringify(temp)));
                setData(JSON.parse(JSON.stringify(temp)));
                if (!resData?.terminal_info?.terminal_id) {
                    setRrrorOpen(true)
                    setErrorContent("端末情報が削除済みのため参照できません。")
                }
            }
        }).catch(err => {

        })
    }
    useEffect(() => {
        if (data && comparedData) {
            if (JSON.stringify(comparedData) === JSON.stringify(data)) {
                setIsSame(true)
            } else {
                setIsSame(false)
            }
            //termianl
            if (JSON.stringify(comparedData.terminal_info) === JSON.stringify(data.terminal_info)) {
                setIsTerminalInfoDataSame(true)
            } else {
                setIsTerminalInfoDataSame(false)
            }
            //storagetanmatsu_info
            if (JSON.stringify(comparedData?.storagetanmatsu_info) === JSON.stringify(data?.storagetanmatsu_info)) {
                setIsData1Same(true)
                setIsData1DateSame(true)
            } else {
                setIsData1Same(false)
                if (data?.storagetanmatsu_info?.length === comparedData?.storagetanmatsu_info?.length) {
                    // date
                    let bool = true
                    data?.storagetanmatsu_info.map((item, index) => {
                        if (JSON.stringify(comparedData?.storagetanmatsu_info[index]?.uketsuke_date) !== JSON.stringify(data?.storagetanmatsu_info[index].uketsuke_date)) {
                            bool = false
                        }
                    })
                    if (bool) {
                        setIsData1DateSame(true)
                    } else {
                        setIsData1DateSame(false)
                    }
                } else {
                    setIsData1DateSame(false)
                }
            }

            //funshitsu_info
            if (JSON.stringify(comparedData?.funshitsu_info) === JSON.stringify(data?.funshitsu_info)) {
                setIsData2Same(true)
            } else {
                setIsData2Same(false)
            }
            //date
            if (JSON.stringify(comparedData?.funshitsu_info?.funshitsu_date) === JSON.stringify(data?.funshitsu_info?.funshitsu_date)) {
                setIsData2DateSame(true)
            } else {
                setIsData2DateSame(false)
            }
            //incident_info
            if (JSON.stringify(comparedData?.incident_info) === JSON.stringify(data?.incident_info)) {
                setIsData3Same(true)
            } else {
                setIsData3Same(false)
            }
            //date
            if (JSON.stringify(comparedData?.incident_info?.incident_date) === JSON.stringify(data?.incident_info?.incident_date)) {
                setIsData3DateSame(true)
            } else {
                setIsData3DateSame(false)
            }
        }
    }, [comparedData, data]);
    const changeTerminalInfoData = (value, key) => {
        if (key === "jyotai_no") {
            if (value === "3") {
                setStatusToLostDialogVal(value)
                statusToLostDialogOnOpen()
            } else if (data.terminal_info["jyotai_no"] === "3") {
                setStatusToLostDialogVal(value)
                statusToLostDialogOnOpen()
            } else {
                data.terminal_info[key] = value
                setData({ ...data })
            }
        } else {
            data.terminal_info[key] = value
            if (key === "gako_name") {
                if (!value) {
                    data.terminal_info.gako_code = null
                }
                masterData.gako_info.map((item, index) => {
                    if (value == item.gakoMeisho) {
                        data.terminal_info.gako_code = item.gakoCd
                    }
                })
            }
            setData({ ...data })
        }

    }
    const changeStoragetanmatsuInfoData = (index, value, key) => {
        data.storagetanmatsu_info[index][key] = value
        if (key === "storage_check") {
            if (!value) {
                data.storagetanmatsu_info[index].uketsuke_date = null
                data.storagetanmatsu_info[index].kikikubun_code = null
                data.storagetanmatsu_info[index].syojyobunrui_code = null
                data.storagetanmatsu_info[index].syojyo = null
                data.storagetanmatsu_info[index].tantosha = null
                data.storagetanmatsu_info[index].kairi_no_before = null
                data.storagetanmatsu_info[index].mac_address_before = null
                data.storagetanmatsu_info[index].serial_num_before = null
                data.storagetanmatsu_info[index].mochikomi_date = null
                data.storagetanmatsu_info[index].azukari_code = null
                data.storagetanmatsu_info[index].azukari_work_status = null
                data.storagetanmatsu_info[index].funshitsu_check = false
                data.storagetanmatsu_info[index].henkyaku_taiousha = null
                data.storagetanmatsu_info[index].henkyakuyotei_date = null
                data.storagetanmatsu_info[index].henkyaku_date = null
                data.storagetanmatsu_info[index].mikasa_hikiwatashi_date = null
                data.storagetanmatsu_info[index].mikasa_henkyaku_date = null
                data.storagetanmatsu_info[index].mobi_iraiidou_date = null
                data.storagetanmatsu_info[index].vpp_gako_date = null
                data.storagetanmatsu_info[index].vpp_shoki_date = null
                data.storagetanmatsu_info[index].asm_mobi_kaijo_date = null
                data.storagetanmatsu_info[index].mobi_sakujo_date = null
                data.storagetanmatsu_info[index].kairi_no_after = null
                data.storagetanmatsu_info[index].mac_address_after = null
                data.storagetanmatsu_info[index].serial_num_after = null
                data.storagetanmatsu_info[index].mac_irai_date = null
                data.storagetanmatsu_info[index].ipad_daicho_date = null
                data.storagetanmatsu_info[index].ipad_mobi_date = null
                data.storagetanmatsu_info[index].shoki_date = null
                data.storagetanmatsu_info[index].mobi_gakoidou_date = null
                data.storagetanmatsu_info[index].shuri_kanri_no = null
                data.storagetanmatsu_info[index].shuri_passcode = null
                data.storagetanmatsu_info[index].shuri_ipad_code = null
                data.storagetanmatsu_info[index].biko = null
                data.storagetanmatsu_info[index].gako_renraku_check = false
                data.storagetanmatsu_info[index].terminal_id = data.terminal_info.terminal_id
                data.storagetanmatsu_info[index].newflag = 0
            } else {
                data.storagetanmatsu_info[index].uketsuke_date = dayjs().format("YYYY-MM-DD")
                data.storagetanmatsu_info[index].terminal_id = data.terminal_info.terminal_id
                data.storagetanmatsu_info[index].newflag = 0
                data.storagetanmatsu_info[index].kairi_no_before = data.terminal_info.kanri_no
                data.storagetanmatsu_info[index].mac_address_before = data.terminal_info.mac_address
                data.storagetanmatsu_info[index].serial_num_before = data.terminal_info.serial_num
                data.storagetanmatsu_info[index].azukari_work_status = "1"
            }
        }
        setData({ ...data })
    }
    const changeFunshitsuInfoData = (value, key) => {
        if (key === "funshitsu_check") {
            setLostToStatusDialogVal(value)
            lostToStatusDialogOnOpen()
        } else {
            data.funshitsu_info[key] = value
            setData({ ...data })
        }
        // data.funshitsu_info[key] = value
        // if (key === "funshitsu_check") {
        //     if (!value) {
        //         data.funshitsu_info.renraku_date = null
        //         data.funshitsu_info.tantosha = null
        //         data.funshitsu_info.kyodeji_tantosha = null
        //         data.funshitsu_info.help_tantosha = null
        //         data.funshitsu_info.funshitsu_date = null
        //         data.funshitsu_info.hakken_date = null
        //         data.funshitsu_info.funshitsu_jyokyo = null
        //         data.funshitsu_info.funshitsu_mode = null
        //         data.funshitsu_info.icchi_info = null
        //         data.funshitsu_info.mitekiyo_idou = null
        //         data.funshitsu_info.saishu_jyokyo = null
        //         data.funshitsu_info.biko = null
        //         data.funshitsu_info.terminal_id = comparedData.funshitsu_info.terminal_id
        //         data.funshitsu_info.newflag = comparedData.funshitsu_info.newflag

        //     } else {
        //         data.funshitsu_info.funshitsu_date = dayjs().format("YYYY-MM-DD")
        //         data.funshitsu_info.terminal_id = data.terminal_info.terminal_id
        //         data.funshitsu_info.newflag = 0
        //     }
        // }
        // setData({ ...data })
    }
    const changeIncidentInfoData = (value, key) => {
        data.incident_info[key] = value
        if (key === "incident_check") {
            if (!value) {
                data.incident_info.incident_date = null
                data.incident_info.kakuri_url = null
                data.incident_info.chokkin_site_url = null
                data.incident_info.site_content = null
                data.incident_info.kakuri_kaijo_date = null
                data.incident_info.terminal_id = comparedData.incident_info.terminal_id
                data.incident_info.newflag = comparedData.incident_info.newflag
            } else {
                data.incident_info.incident_date = dayjs().format("YYYY-MM-DD")
                data.incident_info.terminal_id = data.terminal_info.terminal_id
                data.incident_info.newflag = 0
            }
        }
        setData({ ...data })
    }



    const handleChange = (e, key, type, index) => {
        const { value: inputValue } = e.target;
        const reg = /^[A-Za-z0-9!@#$%^&*()_+{}|:<>?=\-\[\]\\;'\",./~]+$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            if (type === 1) {
                changeTerminalInfoData(inputValue, key);
            } else if (type === 2) {
                changeStoragetanmatsuInfoData(index, inputValue, key);
            } else if (type === 3) {
                changeFunshitsuInfoData(inputValue, key);
            } else {
                changeIncidentInfoData(inputValue, key);
            }
        }
    };

    const checkTerminalData = () => {
        if (data.terminal_info.syubetsu_code &&
            data.terminal_info.gako_code &&
            data.terminal_info.gako_name &&
            data.terminal_info.kanri_no &&
            data.terminal_info.jyotai_no) {
            return true
        } else {
            return false
        }
    }
    const checkStoragetanmatsuData = () => {
        let check = true
        data.storagetanmatsu_info.map((item) => {
            if (item.storage_check) {
                if (item.uketsuke_date && item.kikikubun_code && item.azukari_code) {
                } else {
                    check = false
                }
            } else {
            }
        })
        if (check) {
            return true
        } else {
            return false
        }
    }
    const checkFunshitsuData = () => {
        if (data.funshitsu_info.funshitsu_check) {
            if (data.funshitsu_info.funshitsu_date) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    const checkIncidentData = () => {
        if (data.incident_info.incident_check) {
            if (data.incident_info.incident_date) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    const checkKikikubunCode = () => {
        let list = []
        data.storagetanmatsu_info.map((item, index) => {
            if (item.storage_check) {
                list.push(item.kikikubun_code)
            }
        })
        const set = new Set(list);
        if (set.size !== list.length) {
            return false
        } else {
            return true
        }
    }

    const modalRef = useRef(null);

    const goBack = () => {
        if (stateData?.from === "single") {
            window.history.back()
        } else {
            navigate(routerNames.TerminalManage)
        }
    }
    const setPage = (flag) => {
        setRrrorOpen(false)
        if (isSame || data?.terminal_info?.newflag != 0) {
            nextPage(flag)
        } else {
            if (flag === 0) {
                if (currentPage > 1) {
                    confirmDialogOnOpen(flag)
                }
            } else if (flag === 1) {
                if (currentPage < stateData?.idList.length) {
                    confirmDialogOnOpen(flag)
                }
            }
        }
    };
    const nextPage = (flag) => {
        let page = currentPage
        if (flag === 0) {
            if (currentPage > 1) {
                page = page - 1
                getData(stateData?.idList[page - 1].terminalId)
                setCurrentPage(page)
            }
        } else if (flag === 1) {
            if (currentPage < stateData?.idList.length) {
                page = page + 1
                getData(stateData?.idList[page - 1].terminalId)
                setCurrentPage(page)
            }
        }
    }
    const getGakoAutoList = () => {
        let list = []
        masterData.gako_info.map((item, index) => {
            if (item.tanmatsuKanriFuragu == "1") {
                list.push({ "label": item.gakoMeisho })
            }
        })
        return list;
    };
    return (
        <div ref={modalRef} id="father_Block">
            <div className={classes.header} style={{ borderLeft: '5px solid #0A679A', fontWeight: 'bold' }}>
                <div>  {data?.terminal_info?.newflag != 0 ? `${stateData?.idList[currentPage - 1].name} 端末詳細の履歴` : `${stateData?.idList[currentPage - 1].name} 端末詳細`}</div>
                <div style={{ display: stateData?.from === "single" ? "none" : "" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <div className={classes.itemLeftKey}> <img src={arrowLeft} style={{ width: VW(24), height: VW(24) }} onClick={() => { setPage(0) }} /></div>
                        <div style={{ color: "#27303D", fontWeight: 400, fontSize: VW(16) }}>
                            {`${currentPage}/${stateData?.idList.length}`}
                        </div>
                        <div className={classes.itemRightKey}><img src={arrowRight} style={{ width: VW(24), height: VW(24) }} onClick={() => { setPage(1) }} /></div>

                    </div>
                </div>
            </div>
            <div style={{ paddingLeft: VW(24), paddingRight: VW(24) }}>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                    marginTop={VH(12)}
                    marginBottom={VH(12)}
                />
                <Common_successAlert
                    open={successOpen}
                    setSuccessOpen={setSuccessOpen}
                    successContent={successContent}
                    border
                    marginTop={VH(12)}
                    marginBottom={VH(12)}
                />
            </div>
            <div style={{ boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD', width: VW(845), marginLeft: VW(24) }}>
                <div style={{ width: "100%" }}>
                    <div className={classes.bar} style={{ cursor: "pointer" }} onClick={() => setExpand1(!expand1)} >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <TabletMacRounded
                                style={{ marginRight: VW(8), color: "#2095D4", width: VW(25.33), height: VH(32) }}
                            />
                            <div
                                style={{
                                    color: "#053550",
                                    fontWeight: 700,
                                    fontSize: VW(20)
                                }}> {"基本情報 "}</div>
                        </div>
                        {expand1 ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
                    </div>

                    <div className={classes.detailTable} style={{ display: expand1 ? "" : "none", }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", color: "#E60024", fontSize: VW(14), marginRight: VW(24) }}>* 必須入力項目</div>

                        <div className={classes.item}>
                            <div className={classes.itemText} >種別 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                            <div className={classes.itemInput} >
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: VW(219), height: VH(56), borderRadius: VW(6)

                                    }}
                                    value={data?.terminal_info?.syubetsu_code || ""}
                                    label=""
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'syubetsu_code')}
                                    displayEmpty
                                    renderValue={
                                        data?.terminal_info?.syubetsu_code !== null &&
                                            data?.terminal_info?.syubetsu_code !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                    }
                                >
                                    {type_Option.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={parseInt(item.value)}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className={classes.itemText} style={{ marginLeft: VW(16) }} >更新日</div>
                            <div className={classes.itemInput} >
                                {data?.terminal_info?.update_date ?
                                    dayjs(data?.terminal_info?.update_date).format("YYYY/MM/DD") :
                                    ""}
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >学校CD <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                            <div className={classes.itemInput} >
                                <div className={classes.itemInput} >
                                    {data?.terminal_info?.gako_code ? data?.terminal_info?.gako_code : ""}
                                </div>

                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>学校名 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                            <div className={classes.itemInput} >
                                <Autocomplete
                                    className={classes.autocomplete}
                                    disablePortal
                                    options={getGakoAutoList()}
                                    sx={{ width: VW(219), }}
                                    value={data?.terminal_info?.gako_name || ""}
                                    onChange={(event: any, newValue: string | null) => {
                                        changeTerminalInfoData(newValue != null ? newValue.label : "", 'gako_name')
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small"
                                    />}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >管理番号 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                            <div className={classes.itemInput} >
                                <div className={classes.itemInput} >
                                    {data?.terminal_info?.kanri_no ? data?.terminal_info?.kanri_no : ""}
                                </div>

                                {/* <Input
                                    className={classes.itemInputInner}
                                    value={data?.terminal_info?.kanri_no || ""}
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'kanri_no')}
                                    placeholder="入力してください"
                                    maxLength={50}
                                /> */}
                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>利用者</div>
                            <div className={classes.itemInput} >
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: VW(219), height: VH(56), borderRadius: VW(6)

                                    }}
                                    sx={{ "& .MuiSelect-iconOutlined": { display: data?.terminal_info?.owner ? 'none' : '' } }}
                                    endAdornment={<ClearIcon sx={{ display: data?.terminal_info?.owner ? "" : "none", width: VW(15), height: VW(15) }} onClick={() => changeTerminalInfoData("", 'owner')}></ClearIcon>}
                                    value={data?.terminal_info?.owner || ""}
                                    label=""
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'owner')}
                                    displayEmpty
                                    renderValue={
                                        data?.terminal_info?.owner !== null &&
                                            data?.terminal_info?.owner !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                    }
                                >
                                    {ownerNo_Option.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={parseInt(item.value)}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >シリアル番号</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.terminal_info?.serial_num || ""}
                                    onChange={(event) => handleChange(event, 'serial_num', 1)}
                                    placeholder=" 半角英数字で入力してください"
                                    maxLength={50}
                                />
                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>MACアドレス</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.terminal_info?.mac_address || ""}
                                    onChange={(event) => handleChange(event, 'mac_address', 1)}
                                    placeholder="00:00:00:00:00:00"
                                    maxLength={50}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >ステータス <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                            <div className={classes.itemInput} >
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: VW(219), height: VH(56), borderRadius: VW(6)

                                    }}
                                    value={data?.terminal_info?.jyotai_no || ""}
                                    label=""
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'jyotai_no')}
                                    displayEmpty
                                    renderValue={
                                        data?.terminal_info?.jyotai_no !== null &&
                                            data?.terminal_info?.jyotai_no !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                    }
                                >
                                    {status_Option.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>目視</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.terminal_info?.mokushi || ""}
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'mokushi')}
                                    placeholder="入力してください"
                                    maxLength={50}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >接続NW</div>
                            <div className={classes.itemInput} >
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: VW(219), height: VH(56), borderRadius: VW(6)

                                    }}
                                    value={data?.terminal_info?.setsuzokunwmei || ""}
                                    label=""
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'setsuzokunwmei')}
                                    displayEmpty
                                    renderValue={
                                        data?.terminal_info?.setsuzokunwmei !== null &&
                                            data?.terminal_info?.setsuzokunwmei !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                    }
                                >
                                    {masterData.terminalnw_info.map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={parseInt(item.no)}>{item.terminalnw}</MenuItem>
                                        )

                                    })}
                                </Select>
                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>MDM</div>
                            <div className={classes.itemInput} >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF', color: "#000000" }} sx={{ color: "#E0E0E0" }} key={data?.terminal_info?.mdm} checked={data?.terminal_info?.mdm} onChange={(event) => changeTerminalInfoData(event.target.checked, 'mdm')} />
                                    <div style={{ color: "#27303D", fontSize: VW(16), marginLeft: VW(8) }}>
                                        あり
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >iFilter</div>
                            <div className={classes.itemInput} >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF', color: "#000000" }} sx={{ color: "#E0E0E0" }} key={data?.terminal_info?.ifilter} checked={data?.terminal_info?.ifilter} onChange={(event) => changeTerminalInfoData(event.target.checked, 'ifilter')} />
                                    <div style={{ color: "#27303D", fontSize: VW(16), marginLeft: VW(8) }}>
                                        あり
                                    </div>
                                </div>
                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>ASM</div>
                            <div className={classes.itemInput} >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF', color: "#000000" }} sx={{ color: "#E0E0E0" }} key={data?.terminal_info?.asm} checked={data?.terminal_info?.asm} onChange={(event) => changeTerminalInfoData(event.target.checked, 'asm')} />
                                    <div style={{ color: "#27303D", fontSize: VW(16), marginLeft: VW(8) }}>
                                        あり
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >AppleID</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.terminal_info?.appleid || ""}
                                    onChange={(event) => handleChange(event, 'appleid', 1)}
                                    placeholder="半角英数字で入力してください"
                                    maxLength={100}
                                />
                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                <div>
                                    <div>AppleIDの</div>
                                    <div>パスワード</div>
                                </div>
                            </div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.terminal_info?.appleidpassword || ""}
                                    onChange={(event) => handleChange(event, 'appleidpassword', 1)}
                                    placeholder="半角英数字で入力してください"
                                    maxLength={50}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >導入年度</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.terminal_info?.donyu_nendo || ""}
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'donyu_nendo')}
                                    placeholder="R1"
                                    maxLength={10}
                                />
                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>導入年月日</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.terminal_info?.donyu_nengappi || ""}
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'donyu_nengappi')}
                                    placeholder="R1.1.1"
                                    maxLength={10}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} style={{ height: VH(188) }}>Memo</div>
                            <div className={classes.itemInput} style={{ height: VH(188), width: VW(616), alignItems: "center", justifyContent: "center" }}>
                                <TextArea
                                    style={{ height: VH(156), width: VW(600), fontSize: VW(12), padding: 16 }}
                                    value={data?.terminal_info?.memo || ""}
                                    onChange={(event) => changeTerminalInfoData(event.target.value, 'memo')}
                                    maxLength={200}
                                    placeholder="Memoを入力してください"
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </div >
            {data?.storagetanmatsu_info?.map((storagetanmatsu_info, index) => {
                return (
                    <div key={index} style={{ boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD', width: VW(845), marginLeft: VW(24), marginTop: VH(16) }}>
                        <div style={{ width: "100%" }}>
                            <div className={classes.bar} style={{ justifyContent: "start", cursor: "pointer" }} onClick={() => {
                                if (index == 0) {
                                    setExpand2(!expand2)
                                } else {
                                    let list = expandList
                                    list[index] = !list[index]
                                    setExpandList([...list])
                                }
                            }
                            }  >
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <TabletMacRounded
                                        style={{ marginRight: VW(8), color: "#2095D4", width: VW(25.33), height: VH(32) }}
                                    />
                                    <div
                                        style={{
                                            color: "#053550",
                                            fontWeight: 700,
                                            fontSize: VW(20),
                                            width: "300px"
                                        }}> {"預かり情報 "}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
                                    <img
                                        style={{ width: VW(24), height: VH(24), marginRight: "16px", cursor: "pointer" }}
                                        src={deleteIcon}
                                        onClick={() => {
                                            let temp1 = data
                                            let temp2 = expandList
                                            let temp3 = data.storagetanmatsu_info[index]
                                            if (temp3.storage_terminal_id != null) {
                                                temp3.delete_flag = true
                                                deletedList.push(temp3)
                                                setDeletedList([...deletedList])
                                            }

                                            temp1.storagetanmatsu_info.splice(index, 1)
                                            temp2.splice(index, 1)
                                            setData({ ...temp1 })
                                            setExpandList([...temp2])

                                        }}
                                    />
                                    <div >
                                        {(index == 0 && expand2) ? <KeyboardArrowDownRounded /> : (index > 0 && expandList[index]) ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
                                    </div>
                                </div>

                            </div>

                            <div className={classes.detailTable} style={{ display: (index == 0 && expand2) ? "" : (index > 0 && expandList[index]) ? "" : "none", paddingTop: VH(24) }}>

                                <div className={classes.item}>
                                    <div className={classes.itemText} >状況 </div>
                                    <div className={classes.itemInput} >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF', color: "#000000" }} sx={{ color: "#E0E0E0" }} key={storagetanmatsu_info.storage_check} checked={storagetanmatsu_info.storage_check} onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.checked, 'storage_check')} />
                                            <div style={{ color: "#27303D", fontSize: VW(16), marginLeft: VW(8) }}>
                                                預かり中
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }} >受付日 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'uketsuke_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.uketsuke_date ?
                                                dayjs(storagetanmatsu_info.uketsuke_date) :
                                                storagetanmatsu_info.uketsuke_date}
                                            locale={locale}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >機器区分 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                                    <div className={classes.itemInput} >
                                        <Select
                                            className={classes.select}
                                            style={{
                                                width: VW(219), height: VH(56), borderRadius: VW(6),
                                                backgroundColor: storagetanmatsu_info.storage_check ? "#fff" : "rgba(0, 0, 0, 0.04)"

                                            }}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            value={storagetanmatsu_info.kikikubun_code || ""}
                                            label=""
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'kikikubun_code')}
                                            displayEmpty
                                            renderValue={
                                                storagetanmatsu_info.kikikubun_code !== null &&
                                                    storagetanmatsu_info.kikikubun_code !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                            }
                                        >
                                            {kikikubun_code_Option.map((item, i) => {
                                                return (
                                                    <MenuItem key={i} value={parseInt(item.value)}>{item.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>症状分類 </div>
                                    <div className={classes.itemInput} >
                                        <Select
                                            className={classes.select}
                                            style={{
                                                width: VW(219), height: VH(56), borderRadius: VW(6),
                                                backgroundColor: storagetanmatsu_info.storage_check ? "#fff" : "rgba(0, 0, 0, 0.04)"
                                            }}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            value={storagetanmatsu_info.syojyobunrui_code || ""}
                                            label=""
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'syojyobunrui_code')}
                                            displayEmpty
                                            renderValue={
                                                storagetanmatsu_info.syojyobunrui_code !== null &&
                                                    storagetanmatsu_info.syojyobunrui_code !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                            }
                                        >
                                            {masterData.symptomclass_info.map((item, i) => {
                                                return (
                                                    <MenuItem key={i} value={parseInt(item.no)}>{item.symptomclass}</MenuItem>
                                                )

                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >症状</div>
                                    <div className={classes.itemInput} style={{ width: VW(615) }} >
                                        <Input
                                            className={classes.itemInputInner}
                                            style={{ width: VW(615) }}
                                            value={storagetanmatsu_info.syojyo || ""}
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'syojyo')}
                                            placeholder="症状の詳細を入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >学校担当者</div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.tantosha || ""}
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'tantosha')}
                                            placeholder=" 入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        <div>
                                            <div>管理番号</div>
                                            <div>{"(変更前)"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.kairi_no_before || ""}
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'kairi_no_before')}
                                            placeholder="入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div>シリアル番号</div>
                                            <div>{"(変更前)"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.serial_num_before || ""}
                                            onChange={(event) => handleChange(event, 'serial_num_before', 2, index)}
                                            placeholder="半角英数字で入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        <div>
                                            <div>MACアドレス</div>
                                            <div>{"(変更前)"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.mac_address_before || ""}
                                            onChange={(event) => handleChange(event, 'mac_address_before', 2, index)}
                                            placeholder="半角英数字で入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >持ち込み日</div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'mochikomi_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.mochikomi_date ?
                                                dayjs(storagetanmatsu_info.mochikomi_date) :
                                                storagetanmatsu_info.mochikomi_date}

                                            locale={locale}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>預かり区分 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span> </div>
                                    <div className={classes.itemInput} >
                                        <Select
                                            className={classes.select}
                                            style={{
                                                width: VW(219), height: VH(56), borderRadius: VW(6),
                                                backgroundColor: storagetanmatsu_info.storage_check ? "#fff" : "rgba(0, 0, 0, 0.04)"

                                            }}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            value={storagetanmatsu_info.azukari_code || ""}
                                            label=""
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'azukari_code')}
                                            displayEmpty
                                            renderValue={
                                                storagetanmatsu_info.azukari_code !== null &&
                                                    storagetanmatsu_info.azukari_code !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                            }
                                        >
                                            {azukari_code_Option.map((item, i) => {
                                                return (
                                                    <MenuItem key={i} value={parseInt(item.value)}>{item.label}</MenuItem>
                                                )

                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >作業状況</div>
                                    <div className={classes.itemInput} style={{ width: VW(615) }} >
                                        <Select
                                            className={classes.select}
                                            style={{
                                                width: VW(615), height: VH(56), borderRadius: VW(6),
                                                backgroundColor: storagetanmatsu_info.storage_check ? "#fff" : "rgba(0, 0, 0, 0.04)"

                                            }}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            value={storagetanmatsu_info.azukari_work_status || ""}
                                            label=""
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'azukari_work_status')}
                                            displayEmpty
                                            renderValue={
                                                storagetanmatsu_info.azukari_work_status !== null &&
                                                    storagetanmatsu_info.azukari_work_status !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                            }
                                        >
                                            {operating_Option.map((item, i) => {
                                                return (
                                                    <MenuItem key={i} value={parseInt(item.value)}>{item.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >破損・紛失届</div>
                                    <div className={classes.itemInput} >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF', color: "#000000" }} sx={{ color: "#E0E0E0" }}
                                                key={storagetanmatsu_info.funshitsu_check}
                                                checked={storagetanmatsu_info.funshitsu_check}
                                                onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.checked, 'funshitsu_check')}
                                                disabled={storagetanmatsu_info.storage_check ? false : true}
                                            />
                                            <div style={{ color: "#27303D", fontSize: VW(16), marginLeft: VW(8) }}>
                                                提出済
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>返却対応者</div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.henkyaku_taiousha || ""}
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'henkyaku_taiousha')}
                                            placeholder="入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >返却予定日</div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'henkyakuyotei_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.henkyakuyotei_date ?
                                                dayjs(storagetanmatsu_info.henkyakuyotei_date) :
                                                storagetanmatsu_info.henkyakuyotei_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        返却日
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'henkyaku_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.henkyaku_date ?
                                                dayjs(storagetanmatsu_info.henkyaku_date) :
                                                storagetanmatsu_info.henkyaku_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div>ミカサ</div>
                                            <div> 引き渡し日時</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'mikasa_hikiwatashi_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.mikasa_hikiwatashi_date ?
                                                dayjs(storagetanmatsu_info.mikasa_hikiwatashi_date) :
                                                storagetanmatsu_info.mikasa_hikiwatashi_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        <div>
                                            <div>ミカサ</div>
                                            <div> 返却日時</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'mikasa_henkyaku_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.mikasa_henkyaku_date ?
                                                dayjs(storagetanmatsu_info.mikasa_henkyaku_date) :
                                                storagetanmatsu_info.mikasa_henkyaku_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div> Mobiグループ</div>
                                            <div>  {"修理依頼分 移動"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'mobi_iraiidou_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.mobi_iraiidou_date ?
                                                dayjs(storagetanmatsu_info.mobi_iraiidou_date) :
                                                storagetanmatsu_info.mobi_iraiidou_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        <div>
                                            <div>VPPライセンス</div>
                                            <div> {" 回収（学校アプリ）"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'vpp_gako_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.vpp_gako_date ?
                                                dayjs(storagetanmatsu_info.vpp_gako_date) :
                                                storagetanmatsu_info.vpp_gako_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div> VPPライセンス</div>
                                            <div>  {"回収（初期アプリ）"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'vpp_shoki_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.vpp_shoki_date ?
                                                dayjs(storagetanmatsu_info.vpp_shoki_date) :
                                                storagetanmatsu_info.vpp_shoki_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        <div>
                                            <div>ASMデバイスと</div>
                                            <div> {"Mobi紐付け解除"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'asm_mobi_kaijo_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.asm_mobi_kaijo_date ?
                                                dayjs(storagetanmatsu_info.asm_mobi_kaijo_date) :
                                                storagetanmatsu_info.asm_mobi_kaijo_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div> Mobi登録情報</div>
                                            <div>  {"削除"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'mobi_sakujo_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.mobi_sakujo_date ?
                                                dayjs(storagetanmatsu_info.mobi_sakujo_date) :
                                                storagetanmatsu_info.mobi_sakujo_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        <div>
                                            <div>管理番号</div>
                                            <div> {"(変更後)"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.kairi_no_after || ""}
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'kairi_no_after')}
                                            placeholder="入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div> シリアル番号</div>
                                            <div>  {" (変更後)"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.serial_num_after || ""}
                                            onChange={(event) => handleChange(event, 'serial_num_after', 2, index)}
                                            placeholder="半角英数字で入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        <div>
                                            <div>MACアドレス</div>
                                            <div> {"(変更後)"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.mac_address_after || ""}
                                            onChange={(event) => handleChange(event, 'mac_address_after', 2, index)}
                                            placeholder="半角英数字で入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div> Mac認証</div>
                                            <div>  {"変更依頼"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'mac_irai_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.mac_irai_date ?
                                                dayjs(storagetanmatsu_info.mac_irai_date) :
                                                storagetanmatsu_info.mac_irai_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        iPad台帳 更新
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'ipad_daicho_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.ipad_daicho_date ?
                                                dayjs(storagetanmatsu_info.ipad_daicho_date) :
                                                storagetanmatsu_info.ipad_daicho_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div> iPadとMobiの</div>
                                            <div>  {"関連付け"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'ipad_mobi_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.ipad_mobi_date ?
                                                dayjs(storagetanmatsu_info.ipad_mobi_date) :
                                                storagetanmatsu_info.ipad_mobi_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        初期設定
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'shoki_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.shoki_date ?
                                                dayjs(storagetanmatsu_info.shoki_date) :
                                                storagetanmatsu_info.shoki_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div> Mobiグループ</div>
                                            <div>  {"各学校へ移動"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <DatePicker
                                            className={classes.itemInputInner}
                                            suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                            allowClear={true}
                                            clearIcon={
                                                <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                            }
                                            onChange={(value, dateString) => changeStoragetanmatsuInfoData(index, value?.format("YYYY-MM-DD"), 'mobi_gakoidou_date')}
                                            placeholder='---- / -- / --'
                                            format="YYYY/MM/DD"
                                            value={storagetanmatsu_info.mobi_gakoidou_date ?
                                                dayjs(storagetanmatsu_info.mobi_gakoidou_date) :
                                                storagetanmatsu_info.mobi_gakoidou_date}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            locale={locale}
                                        />
                                    </div>
                                </div>
                                <div style={{ fontSize: VW(18), color: "#053550", marginLeft: VW(24), marginTop: VH(16), marginBottom: VH(16), fontWeight: 700 }}>修理 引渡し前確認</div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >
                                        <div>
                                            <div> 管理番号</div>
                                            <div style={{ fontSize: VW(8) }}>  {"(iPad背面)"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} style={{ width: VW(615) }} >
                                        <Input
                                            className={classes.itemInputInner}
                                            style={{ width: VW(615) }}
                                            value={storagetanmatsu_info.shuri_kanri_no || ""}
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'shuri_kanri_no')}
                                            placeholder="管理番号(iPad背面) ※第7世代のみ"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} >パスコード</div>
                                    <div className={classes.itemInput} >
                                        <Input
                                            className={classes.itemInputInner}
                                            value={storagetanmatsu_info.shuri_passcode || ""}
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'shuri_passcode')}
                                            placeholder="123456"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                        <div>
                                            <div> iPadを探すオフ</div>
                                            <div>  {"(MDM管理外のみ)"}</div>
                                        </div>
                                    </div>
                                    <div className={classes.itemInput} >
                                        <Select
                                            className={classes.select}
                                            style={{
                                                width: VW(219), height: VH(56), borderRadius: VW(6),
                                                backgroundColor: storagetanmatsu_info.storage_check ? "#fff" : "rgba(0, 0, 0, 0.04)"

                                            }}
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                            value={storagetanmatsu_info.shuri_ipad_code || ""}
                                            label=""
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'shuri_ipad_code')}
                                            displayEmpty
                                            renderValue={
                                                storagetanmatsu_info.shuri_ipad_code !== null &&
                                                    storagetanmatsu_info.shuri_ipad_code !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                            }
                                        >
                                            {shuri_ipad_code_Option.map((item, i) => {
                                                return (
                                                    <MenuItem key={i} value={parseInt(item.value)}>{item.label}</MenuItem>
                                                )

                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.itemText} style={{ height: VH(188), borderBottom: '1px solid #E0E0E0' }}>備考</div>
                                    <div className={classes.itemInput} style={{ height: VH(188), width: VW(616), borderBottom: '1px solid #E0E0E0', alignItems: "center", justifyContent: "center" }}>
                                        <TextArea
                                            style={{ height: VH(156), width: VW(600), fontSize: VW(12), padding: 16 }}
                                            value={storagetanmatsu_info.biko || ""}
                                            onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.value, 'biko')}
                                            maxLength={200}
                                            placeholder="備考を入力してください"
                                            disabled={storagetanmatsu_info.storage_check ? false : true}
                                        />
                                    </div>
                                </div>
                                <div className={classes.item} >
                                    <div className={classes.itemText} style={{ borderTop: '0px solid #E0E0E0', borderBottom: '1px solid #E0E0E0' }} >学校連絡</div>
                                    <div className={classes.itemInput} style={{ borderTop: '0px solid #E0E0E0', borderBottom: '1px solid #E0E0E0' }} >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF', color: "#000000" }} sx={{ color: "#E0E0E0" }} key={storagetanmatsu_info.gako_renraku_check} checked={storagetanmatsu_info.gako_renraku_check} onChange={(event) => changeStoragetanmatsuInfoData(index, event.target.checked, 'gako_renraku_check')} disabled={storagetanmatsu_info.storage_check ? false : true} />
                                            <div style={{ color: "#27303D", fontSize: VW(16), marginLeft: VW(8) }}>
                                                完了
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )
            })}
            <div style={{
                cursor: "pointer",
                display: "flex", alignItems: "center", width: VW(845), marginLeft: VW(24), marginTop: "16px", justifyContent: "flex-end", color: "#2095D4", fontSize: "14px",
            }}
                onClick={() => {
                    let temp = data
                    let temp2 = storageEmptyData
                    temp2.terminal_id = data.terminal_info.terminal_id
                    temp2.uketsuke_date = dayjs().format("YYYY-MM-DD")
                    temp2.newflag = 0
                    temp2.kairi_no_before = data.terminal_info.kanri_no
                    temp2.mac_address_before = data.terminal_info.mac_address
                    temp2.serial_num_before = data.terminal_info.serial_num
                    temp2.azukari_work_status = "1"
                    temp.storagetanmatsu_info.push(temp2)
                    let list = expandList
                    list.push(true
                    )
                    setExpandList([...list])
                    setData({ ...temp })
                }}>
                <div style={{ cursor: 'pointer', marginTop: "3px", marginRight: "8px" }}>
                    <AddIcon color='#2095D4' />
                </div>
                預かり情報を追加する
            </div>
            <div style={{ boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD', width: VW(845), marginLeft: VW(24), marginTop: VH(16) }}>
                <div style={{ width: "100%" }}>
                    <div className={classes.bar} style={{ cursor: "pointer" }} onClick={() => setExpand3(!expand3)} >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <TabletMacRounded
                                style={{ marginRight: VW(8), color: "#2095D4", width: VW(25.33), height: VH(32) }}
                            />
                            <div
                                style={{
                                    color: "#053550",
                                    fontWeight: 700,
                                    fontSize: VW(20)
                                }}> {"紛失情報 "}</div>
                        </div>
                        {expand3 ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
                    </div>

                    <div className={classes.detailTable} style={{ display: expand3 ? "" : "none", paddingTop: VH(24) }}>

                        <div className={classes.item}>
                            <div className={classes.itemText} >状況 </div>
                            <div className={classes.itemInput} >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF', color: "#000000" }} sx={{ color: "#E0E0E0" }} key={data?.funshitsu_info?.funshitsu_check} checked={data?.funshitsu_info?.funshitsu_check} onChange={(event) => changeFunshitsuInfoData(event.target.checked, 'funshitsu_check')} />
                                    <div style={{ color: "#27303D", fontSize: VW(16), marginLeft: VW(8) }}>
                                        紛失あり
                                    </div>
                                </div>
                            </div>
                            <div className={classes.itemText} style={{ marginLeft: VW(16) }} >連絡日時</div>
                            <div className={classes.itemInput} >
                                <DatePicker
                                    className={classes.itemInputInner}
                                    suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                    allowClear={true}
                                    clearIcon={
                                        <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                    }
                                    onChange={(value, dateString) => changeFunshitsuInfoData(value?.format("YYYY-MM-DD"), 'renraku_date')}
                                    placeholder='---- / -- / --'
                                    format="YYYY/MM/DD"
                                    value={data?.funshitsu_info?.renraku_date ?
                                        dayjs(data?.funshitsu_info?.renraku_date) :
                                        data?.funshitsu_info?.renraku_date}
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    locale={locale}
                                />
                            </div>
                        </div>

                        <div className={classes.item}>
                            <div className={classes.itemText} >学校担当者</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.funshitsu_info?.tantosha || ""}
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'tantosha')}
                                    placeholder="入力してください"
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    maxLength={50}
                                />
                            </div>
                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}> 教デジ担当者</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.funshitsu_info?.kyodeji_tantosha || ""}
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'kyodeji_tantosha')}
                                    placeholder=" 入力してください"
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >ヘルプ担当者</div>
                            <div className={classes.itemInput} >
                                <Input
                                    className={classes.itemInputInner}
                                    value={data?.funshitsu_info?.help_tantosha || ""}
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'help_tantosha')}
                                    placeholder="入力してください"
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >紛失日時<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                            <div className={classes.itemInput} >
                                <DatePicker
                                    className={classes.itemInputInner}
                                    suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                    allowClear={true}
                                    clearIcon={
                                        <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                    }
                                    onChange={(value, dateString) => changeFunshitsuInfoData(value?.format("YYYY-MM-DD"), 'funshitsu_date')}
                                    placeholder='---- / -- / --'
                                    format="YYYY/MM/DD"
                                    value={data?.funshitsu_info?.funshitsu_date ?
                                        dayjs(data?.funshitsu_info?.funshitsu_date) :
                                        data?.funshitsu_info?.funshitsu_date}
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    locale={locale}
                                />
                            </div>

                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}>
                                発見日時
                            </div>
                            <div className={classes.itemInput} >
                                <DatePicker
                                    className={classes.itemInputInner}
                                    suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                    allowClear={true}
                                    clearIcon={
                                        <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                    }
                                    onChange={(value, dateString) => changeFunshitsuInfoData(value?.format("YYYY-MM-DD"), 'hakken_date')}
                                    placeholder='---- / -- / --'
                                    format="YYYY/MM/DD"
                                    value={data?.funshitsu_info?.hakken_date ?
                                        dayjs(data?.funshitsu_info?.hakken_date) :
                                        data?.funshitsu_info?.hakken_date}
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    locale={locale}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >
                                紛失時の状況
                            </div>
                            <div className={classes.itemInput} style={{ width: VW(615) }} >
                                <Input
                                    className={classes.itemInputInner}
                                    style={{ width: VW(615) }}
                                    value={data?.funshitsu_info?.funshitsu_jyokyo || ""}
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'funshitsu_jyokyo')}
                                    placeholder="紛失時の状況を入力してください"
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    maxLength={100}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >紛失モード</div>
                            <div className={classes.itemInput} >
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: VW(219), height: VH(56), borderRadius: VW(6),
                                        backgroundColor: data?.funshitsu_info?.funshitsu_check ? "#fff" : "rgba(0, 0, 0, 0.04)"

                                    }}
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    value={data?.funshitsu_info?.funshitsu_mode || ""}
                                    label=""
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'funshitsu_mode')}
                                    displayEmpty
                                    renderValue={
                                        data?.funshitsu_info?.funshitsu_mode !== null &&
                                            data?.funshitsu_info?.funshitsu_mode !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                    }
                                >
                                    {funshitsu_mode_Option.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={parseInt(item.value)}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className={classes.itemText} style={{ marginLeft: VW(16) }}> 位置情報取得</div>
                            <div className={classes.itemInput} >
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: VW(219), height: VH(56), borderRadius: VW(6),
                                        backgroundColor: data?.funshitsu_info?.funshitsu_check ? "#fff" : "rgba(0, 0, 0, 0.04)"

                                    }}
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    value={data?.funshitsu_info?.icchi_info || ""}
                                    label=""
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'icchi_info')}
                                    displayEmpty
                                    renderValue={
                                        data?.funshitsu_info?.icchi_info !== null &&
                                            data?.funshitsu_info?.icchi_info !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                    }
                                >
                                    {icchi_info_Option.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={parseInt(item.value)}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >
                                <div>
                                    <div>未適用グループ</div>
                                    <div>移動</div>
                                    <div style={{ fontSize: VW(8) }}>{"(紛失モードPUSH送信中）"}</div>
                                </div>
                            </div>
                            <div className={classes.itemInput} >
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: VW(219), height: VH(56), borderRadius: VW(6),
                                        backgroundColor: data?.funshitsu_info?.funshitsu_check ? "#fff" : "rgba(0, 0, 0, 0.04)"

                                    }}
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    value={data?.funshitsu_info?.mitekiyo_idou || ""}
                                    label=""
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'mitekiyo_idou')}
                                    displayEmpty
                                    renderValue={
                                        data?.funshitsu_info?.mitekiyo_idou !== null &&
                                            data?.funshitsu_info?.mitekiyo_idou !== "" ? undefined : () => <Placeholder>選択してください</Placeholder>
                                    }
                                >
                                    {mitekiyo_idou_Option.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={parseInt(item.value)}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>

                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >
                                最終的な状況
                            </div>
                            <div className={classes.itemInput} style={{ width: VW(615) }} >
                                <Input
                                    className={classes.itemInputInner}
                                    style={{ width: VW(615) }}
                                    value={data?.funshitsu_info?.saishu_jyokyo || ""}
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'saishu_jyokyo')}
                                    placeholder="最終的な状況を入力してください"
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                    maxLength={100}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} style={{ height: VH(188), borderBottom: '1px solid #E0E0E0' }}>備考</div>
                            <div className={classes.itemInput} style={{ height: VH(188), width: VW(616), borderBottom: '1px solid #E0E0E0', alignItems: "center", justifyContent: "center" }}>
                                <TextArea
                                    style={{ height: VH(156), width: VW(600), fontSize: VW(12), padding: 16 }}
                                    value={data?.funshitsu_info?.biko || ""}
                                    onChange={(event) => changeFunshitsuInfoData(event.target.value, 'biko')}
                                    maxLength={200}
                                    placeholder="備考を入力してください"
                                    disabled={data?.funshitsu_info?.funshitsu_check ? false : true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div style={{ boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD', width: VW(845), marginLeft: VW(24), marginTop: VH(16) }}>
                <div style={{ width: "100%" }}>
                    <div className={classes.bar} style={{ cursor: "pointer" }} onClick={() => setExpand4(!expand4)} >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <TabletMacRounded
                                style={{ marginRight: VW(8), color: "#2095D4", width: VW(25.33), height: VH(32) }}
                            />
                            <div
                                style={{
                                    color: "#053550",
                                    fontWeight: 700,
                                    fontSize: VW(20)
                                }}> {"インシデント情報 "}</div>
                        </div>
                        {expand4 ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
                    </div>

                    <div className={classes.detailTable} style={{ display: expand4 ? "" : "none", paddingTop: VH(24) }}>

                        <div className={classes.item}>
                            <div className={classes.itemText} >状況 </div>
                            <div className={classes.itemInput} >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF', color: "#000000" }} sx={{ color: "#E0E0E0" }} key={data?.incident_info?.incident_check} checked={data?.incident_info?.incident_check} onChange={(event) => changeIncidentInfoData(event.target.checked, 'incident_check')} />
                                    <div style={{ color: "#27303D", fontSize: VW(16), marginLeft: VW(8) }}>
                                        インシデント発生中
                                    </div>
                                </div>
                            </div>
                            <div className={classes.itemText} style={{ marginLeft: VW(16) }} >発生日時 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                            <div className={classes.itemInput} >
                                <DatePicker
                                    className={classes.itemInputInner}
                                    suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                    allowClear={true}
                                    clearIcon={
                                        <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                    }
                                    onChange={(value, dateString) => changeIncidentInfoData(value?.format("YYYY-MM-DD"), 'incident_date')}
                                    placeholder='---- / -- / --'
                                    format="YYYY/MM/DD"
                                    value={data?.incident_info?.incident_date ?
                                        dayjs(data?.incident_info?.incident_date) :
                                        data?.incident_info?.incident_date}
                                    disabled={data?.incident_info?.incident_check ? false : true}
                                    locale={locale}
                                />
                            </div>
                        </div>

                        <div className={classes.item}>
                            <div className={classes.itemText} >
                                隔離対象URL
                            </div>
                            <div className={classes.itemInput} style={{ width: VW(615) }} >
                                <Input
                                    className={classes.itemInputInner}
                                    style={{ width: VW(615) }}
                                    value={data?.incident_info?.kakuri_url || ""}
                                    onChange={(event) => changeIncidentInfoData(event.target.value, 'kakuri_url')}
                                    placeholder="隔離対象URLを入力してください"
                                    disabled={data?.incident_info?.incident_check ? false : true}
                                    maxLength={100}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >
                                直近サイトURL
                            </div>
                            <div className={classes.itemInput} style={{ width: VW(615) }} >
                                <Input
                                    className={classes.itemInputInner}
                                    style={{ width: VW(615) }}
                                    value={data?.incident_info?.chokkin_site_url || ""}
                                    onChange={(event) => changeIncidentInfoData(event.target.value, 'chokkin_site_url')}
                                    placeholder="直近サイトURLを入力してください"
                                    disabled={data?.incident_info?.incident_check ? false : true}
                                    maxLength={100}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >
                                サイト内容
                            </div>
                            <div className={classes.itemInput} style={{ width: VW(615) }} >
                                <Input
                                    className={classes.itemInputInner}
                                    style={{ width: VW(615) }}
                                    value={data?.incident_info?.site_content || ""}
                                    onChange={(event) => changeIncidentInfoData(event.target.value, 'site_content')}
                                    placeholder="サイト内容を入力してください"
                                    disabled={data?.incident_info?.incident_check ? false : true}
                                    maxLength={100}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemText} >隔離解除日時</div>
                            <div className={classes.itemInput} >
                                <DatePicker
                                    className={classes.itemInputInner}
                                    suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                                    allowClear={true}
                                    clearIcon={
                                        <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                                    }
                                    onChange={(value, dateString) => changeIncidentInfoData(value?.format("YYYY-MM-DD"), 'kakuri_kaijo_date')}
                                    placeholder='---- / -- / --'
                                    format="YYYY/MM/DD"
                                    value={data?.incident_info?.kakuri_kaijo_date ?
                                        dayjs(data?.incident_info?.kakuri_kaijo_date) :
                                        data?.incident_info?.kakuri_kaijo_date}
                                    disabled={data?.incident_info?.incident_check ? false : true}
                                    locale={locale}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className={classes.buttonGroup}  >
                <Button style={{ width: VW(400) }} disabled={isSame || data?.terminal_info?.newflag != 0} onClick={(event) => confirmDialogOnConfirm("false")}>登録</Button>
            </div>
            <div className={classes.buttonGroup2}  >
                <Button style={{ width: VW(400), marginBottom: VH(200) }} onClick={goBack} >端末一覧に戻る</Button>
            </div>
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={confirmDialogOnClose}
                confirm={confirmDialogOnConfirm}
                disable={confirmDialogOnDisable}
            />
            <StatusToLostDialog
                open={statusToLostDialogOpen}
                onClose={statusToLostDialogOnClose}
                confirm={statusToLostDialogOnConfirm}
                disable={statusToLostDialogOnDisable}
            />
            <LostToStatusDialog
                open={lostToStatusDialogOpen}
                onClose={lostToStatusDialogOnClose}
                confirm={lostToStatusDialogOnConfirm}
                disable={lostToStatusDialogOnDisable}
            />
        </div >
    )
}

TerminalDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TerminalDetail);