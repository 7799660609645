
import React from "react";
import { Routes, Route } from "react-router-dom"
import {
    Box
} from "@mui/material";
import { withStyles } from "@mui/styles";
import DashBoard from "../home/dashBoard/DashBoard";
import VisitRecordInput from "../home/visitRecord/VisitRecordInput";
import { routerNames } from "../wordsFile/wordsFile";
import VisitRecordConfirm from "../home/visitRecord/VisitRecordConfirm";
import VisitSubmitted from "../home/visitRecord/VisitSubmitted";

import MonthlyRecordConfirm from "../home/monthlyRecord/MonthlyRecordConfirm";
import MonthlyRecordConfirmSubmitted from "../home/monthlyRecord/MonthlyRecordConfirmSubmitted";
import SubmitConfirmCSVoutput from "../home/submitConfirm/SubmitConfirmCSVoutput";
import MonthlyRecordConfirmDetails from "../home/monthlyRecord/MonthlyRecordConfirmDetails";
import TerminalManage from "../home/terminalManage/TerminalManage"
import TerminalStateInquiry from "../home/terminalManage/TerminalStateInquiry"
import TerminalDetail from "../home/terminalManage/TerminalDetail";
import SupportContents from "../home/supportContents/SupportContents";




const styles = (theme) => ({

});

function MainRouters(props) {
    const {
        classes,
        setSelectFlag,
        visitData,
        setVisitData,
        isUpdate,
        setIsUpdate,
        updateVisitData,
        masterData,
        updateVisitSupportData,
        updateVisitRouteData,
        refreshVisit,
        setRefreshVisit,
        visitDataCompared,
        setVisitDataCompared,
        menuFlag
    } = props



    return (
        <Routes>
            <Route path="*" element={
                <DashBoard
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                />
            }
            />
            <Route path="DashBoard" element={
                <DashBoard
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                />
            }
            />
            <Route path="VisitRecordInput" element={
                <VisitRecordInput
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    updateVisitData={updateVisitData}
                    masterData={masterData}
                    updateVisitSupportData={updateVisitSupportData}
                    updateVisitRouteData={updateVisitRouteData}
                    refreshVisit={refreshVisit}
                    setRefreshVisit={setRefreshVisit}
                    visitDataCompared={visitDataCompared}
                    setVisitDataCompared={setVisitDataCompared}
                    menuFlag={menuFlag}
                />
            }
            />
            <Route path="VisitRecordConfirm" element={
                <VisitRecordConfirm
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    updateVisitData={updateVisitData}
                    masterData={masterData}
                    updateVisitSupportData={updateVisitSupportData}
                    updateVisitRouteData={updateVisitRouteData}
                />
            }
            />
            <Route path="VisitSubmitted" element={
                <VisitSubmitted
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    updateVisitData={updateVisitData}
                    masterData={masterData}
                />
            }
            />
            <Route path="MonthlyVisitingRecord" element={
                <MonthlyRecordConfirm
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    updateVisitData={updateVisitData}
                    masterData={masterData}
                    updateVisitSupportData={updateVisitSupportData}
                    menuFlag={menuFlag}
                />
            }
            />
            <Route path="MonthlyVisitingRecordDetail" element={
                <VisitRecordInput
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    updateVisitData={updateVisitData}
                    masterData={masterData}
                    updateVisitSupportData={updateVisitSupportData}
                    updateVisitRouteData={updateVisitRouteData}
                    refreshVisit={refreshVisit}
                    setRefreshVisit={setRefreshVisit}
                    menuFlag={menuFlag}
                />
            }
            />
            <Route path="MonthlyVisitingRecordConfirmDetail" element={
                <MonthlyRecordConfirmDetails
                    setSelectFlag={setSelectFlag}
                    setVisitData={setVisitData}
                    masterData={masterData}
                    menuFlag={menuFlag}
                />
            }
            />
            <Route path="MonthlyRecordConfirmSubmitted" element={
                <MonthlyRecordConfirmSubmitted
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    updateVisitData={updateVisitData}
                    masterData={masterData}
                    updateVisitSupportData={updateVisitSupportData}
                />
            }
            />
            <Route path="SubmitConfirmCSVoutput" element={
                <SubmitConfirmCSVoutput
                    setSelectFlag={setSelectFlag}
                    visitData={visitData}
                    setVisitData={setVisitData}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    updateVisitData={updateVisitData}
                    masterData={masterData}
                    updateVisitSupportData={updateVisitSupportData}
                    menuFlag={menuFlag}
                />
            }
            />
            <Route path="TerminalManage" element={
                <TerminalManage
                    setSelectFlag={setSelectFlag}
                    masterData={masterData}
                />
            }
            />
            <Route path="TerminalDetail" element={
                <TerminalDetail
                    setSelectFlag={setSelectFlag}
                    masterData={masterData}
                />
            }
            />
            <Route path="TerminalStateInquiry" element={
                <TerminalStateInquiry
                    setSelectFlag={setSelectFlag}
                    masterData={masterData}
                />
            }
            />
            <Route path="SupportContents" element={
                <SupportContents
                    setSelectFlag={setSelectFlag}
                    masterData={masterData}
                />
            }
            />

        </Routes>
    )
}


MainRouters.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(MainRouters);