import React, { useState, useEffect } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, DatePicker, message } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import locale from "antd/es/date-picker/locale/ja_JP";
import { routerNames } from '../../wordsFile/wordsFile'
import { formatCheck, errorMessage, localstorage } from "../../wordsFile/wordsFile";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton, Select, Box, MenuItem, Checkbox } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
    kyuka_kubunOption,
    support_kubunOption,
    statusOption,
    gyomu_shuruiOption,
    teiji_jikanOption,
    jikangaiOption,
    kotsu_kikanOption
} from "../../wordsFile/selectItem";
import sortIcon from "../../componets/icon/sortIcon.svg"
import visitIcon from "../../componets/icon/visitIcon.svg"
import transferIcon from "../../componets/icon/transferIcon.svg"
import supportIcon from "../../componets/icon/supportIcon.svg"
import moveIcon from "../../componets/icon/moveIcon.svg"
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@mui/icons-material";
import RouteView from "./RouteView";

dayjs.extend(utc)


const openSupportRecordInput = () => {
    alert('test')

}
const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-modal-content': {
            backgroundColor: "#F4F5F9",
            padding: 0
        },

    },


    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        padding: VH(25)
    },
    icon: {
        width: VW(32),
        height: VH(32),
        marginRight: VW(14)
    },
    iconContainer: {
        transform: 'scale(1.667)',
    },
    step: {
        '& .MuiStepLabel-root .Mui-completed': {
            color: '#2195D3',
        },
        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
            fontWeight: 400,
        },
        '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700
        },
        '& .MuiStepLabel-root .Mui-active': {
            color: '#2195D3',
        },
        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
            fontWeight: 400,
        },
        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700,
            fontSize: VW(14)
        },
        '& .Mui-disabled .MuiStepIcon-root': {
            color: '#E0E0E0',
        },
        '& .Mui-disabled .MuiStepIcon-root .MuiStepIcon-text': {
            fill: '#ffffff',
            fontWeight: 700,
            fontSize: VW(14),
        },
        '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
        {
            color: '#27303D',
        },
        "& .MuiStepConnector-line": {
            borderColor: "#E0E0E0",
        }
    },

    locationBar: {
        width: "100%",
        height: VH(104),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD',
        padding: VW(24)
    },
    row: {
        display: "flex",
        alignItems: "center",
        height: VH(56),
    },
    item: {
        display: "flex",
        alignItems: "center",
        borderTop: '1px solid #E0E0E0',
        // borderBottom: '1px solid #E0E0E0',
    },
    itemText: {
        color: "#27303D",
        fontSize: VW(12),
        fontWeight: 700,
        width: VW(80),
        height: VH(72),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
    },
    itemInput: {
        width: VW(140),
        height: VH(72),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        fontSize: VW(12),
    },
    content: {
        borderLeft: '1px solid #9E9E9E',
        marginLeft: VW(24),
        paddingTop: VH(24),
        paddingBottom: VH(24),
        paddingLeft: VW(39)
    },
    bar: {
        display: "flex",
        alignItems: "center",
        height: VH(58),
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #E0E0E0',
        justifyContent: 'space-between',
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(82),
        '& > Button': {
            borderRadius: "4px",
            width: VW(240),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(50),
        fontSize: VW(16),
        fontWeight: 'bold',
        marginBottom: VH(24),
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(40),
        marginBottom: VH(56),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(56),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    buttonGroup3: {
        color: '#FFF',
        marginBottom: VH(24),
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16)
    },
    buttonGroup4: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // marginTop: VH(40),
        // marginBottom: VH(56),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    buttonGroup4: {
        display: 'flex',
        justifyContent: 'center',
        '& > Button': {
            width: VW(111),
            backgroundColor: "#2095D4",
            color: "#fff",
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            border: `1px solid #2195D3`,
            '&:hover': {
                backgroundColor: '#2195D3',
            },
        },
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
});


let routeItem = {
    "kotsukikan_jiseki_id": null,
    "homon_jiseki_id": null,
    "ofuku": 1,
    "kotsukikan_code": null,
    "shuppatsu_ic": null,
    "tochaku_ic": null,
    "unchin_kosoku_ryokin": null,
    "delete_flag": null,
    "expand": 0
}
let routeItemEnd = {
    "kotsukikan_jiseki_id": null,
    "homon_jiseki_id": null,
    "ofuku": 2,
    "kotsukikan_code": null,
    "shuppatsu_ic": null,
    "tochaku_ic": null,
    "unchin_kosoku_ryokin": null,
    "delete_flag": null,
    "expand": 0
}
let routeLable = [
    { value: '1', label: "往路" },
    { value: '2', label: "復路" },
]

let ostartone = ''
let fukustartone = ''
let ostarttwo = ''
let fukustarttwo = ''
let oendone = ''
let fukuendone = ''
let oendtwo = ''
let fukuendtwo = ''

function RouteDialog(props) {
    const {
        classes,
        open,
        onClose,
        visitData,
        setVisitData,
        index,
        item,
        updateVisitData,
        masterData,
        updateVisitRouteData,
        startOfuku,
        endOfuku,
        setStartOfuku,
        setEndOfuku
    } = props
    const [routeSelect, setRouteSelect] = useState("1")
    const [checked, setChecked] = useState(false);
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [buttonDisabled, setButtonDisabled] = useState(false)

    let startNameNum = 0
    let endNameNum = 0
    useEffect(() => {

    }, [open])
    useEffect(() => {
        startNameNum = 0
        endNameNum = 0
        setValue()
    }, [visitData])

    const setValue = () => {
        ostartone = ''
        fukustartone = ''
        ostarttwo = ''
        fukustarttwo = ''
        oendone = ''
        fukuendone = ''
        oendtwo = ''
        fukuendtwo = ''
        visitData.homon_jiseki.map((itemdata, indexdata) => {
            itemdata.kotsukikanList.map((itemkotsu, indexkotsu) => {
                if (itemkotsu.ofuku == 1) {
                    // 往路
                    if (indexdata == 0) {
                        // 訪問先１
                        ostartone = '自宅'
                        if ((null == itemdata.sagyo_basho) || ("" == itemdata.sagyo_basho)) {
                            var sagyo_basholabel = ""
                            masterData.gako_info.map((row) => {
                                if (row.no == itemdata.homon_saki) {
                                    sagyo_basholabel = row.gakoMeisho
                                }
                            })
                            oendone = sagyo_basholabel
                        } else if (itemdata.sagyo_basho == "0") {
                            oendone = itemdata.sagyo_basho_sonota
                        } else {
                            var sagyo_basholabel = ""
                            masterData.sagyobasyo_info.map((row) => {
                                if (row.no == itemdata.sagyo_basho) {
                                    sagyo_basholabel = row.homonsakiMeisho
                                }
                            })
                            oendone = sagyo_basholabel
                        }
                    } else {
                        // 訪問先２
                        if ((null == itemdata.sagyo_basho) || ("" == itemdata.sagyo_basho)) {
                            var sagyo_basholabel = ""
                            masterData.gako_info.map((row) => {
                                if (row.no == itemdata.homon_saki) {
                                    sagyo_basholabel = row.gakoMeisho
                                }
                            })
                            oendtwo = sagyo_basholabel
                        } else if (itemdata.sagyo_basho == "0") {
                            oendtwo = itemdata.sagyo_basho_sonota
                        } else {
                            var sagyo_basholabel = ""
                            masterData.sagyobasyo_info.map((row) => {
                                if (row.no == itemdata.sagyo_basho) {
                                    sagyo_basholabel = row.homonsakiMeisho
                                }
                            })
                            oendtwo = sagyo_basholabel
                        }

                        if (!visitData.homon_jiseki[0].sagyo_basho) {
                            let sakiName = ""
                            masterData.gako_info.map((item, i) => {
                                if (item.homonJissekiFuragu === "1" && visitData.homon_jiseki[0].homon_saki == item.no) {
                                    sakiName = item.gakoMeisho
                                }
                            })
                            masterData.sagyobasyo_info.map((row, i) => {
                                if (sakiName == row.homonsakiMeisho) {
                                    ostarttwo = row.homonsakiMeisho
                                }
                            })
                        } else if (visitData.homon_jiseki[0].sagyo_basho == "0") {
                            ostarttwo = visitData.homon_jiseki[0].sagyo_basho_sonota
                        } else {
                            var sagyo_basholabel = ""
                            masterData.sagyobasyo_info.map((row) => {
                                if (row.no == visitData.homon_jiseki[0].sagyo_basho) {
                                    sagyo_basholabel = row.homonsakiMeisho
                                }
                            })
                            ostarttwo = sagyo_basholabel
                        }

                        visitData.homon_jiseki[0].kotsukikanList.map((row) => {
                            if (row.ofuku == 2) {
                                ostarttwo = '自宅'
                            }
                        })
                    }
                } else {
                    if (indexdata == 0) {
                        // 訪問先１
                        fukuendone = '自宅'
                        if ((null == itemdata.sagyo_basho) || ("" == itemdata.sagyo_basho)) {
                            var sagyo_basholabel = ""
                            masterData.gako_info.map((row) => {
                                if (row.no == itemdata.homon_saki) {
                                    sagyo_basholabel = row.gakoMeisho
                                }
                            })
                            fukustartone = sagyo_basholabel
                        } else if (itemdata.sagyo_basho == "0") {
                            fukustartone = itemdata.sagyo_basho_sonota
                        } else {
                            var sagyo_basholabel = ""
                            masterData.sagyobasyo_info.map((row) => {
                                if (row.no == itemdata.sagyo_basho) {
                                    sagyo_basholabel = row.homonsakiMeisho
                                }
                            })
                            fukustartone = sagyo_basholabel
                        }
                    } else {
                        // 訪問先２
                        if ((null == itemdata.sagyo_basho) || ("" == itemdata.sagyo_basho)) {
                            var sagyo_basholabel = ""
                            masterData.gako_info.map((row) => {
                                if (row.no == itemdata.homon_saki) {
                                    sagyo_basholabel = row.gakoMeisho
                                }
                            })
                            fukustarttwo = sagyo_basholabel
                        } else if (itemdata.sagyo_basho == "0") {
                            fukustarttwo = itemdata.sagyo_basho_sonota
                        } else {
                            var sagyo_basholabel = ""
                            masterData.sagyobasyo_info.map((row) => {
                                if (row.no == itemdata.sagyo_basho) {
                                    sagyo_basholabel = row.homonsakiMeisho
                                }
                            })
                            fukustarttwo = sagyo_basholabel
                        }
                        fukuendtwo = '自宅'
                    }
                }

            })

        })
        setStartOfuku({ 'ostartone': ostartone, 'fukustartone': fukustartone, 'ostarttwo': ostarttwo, 'fukustarttwo': fukustarttwo })
        setEndOfuku({ 'oendone': oendone, 'fukuendone': fukuendone, 'oendtwo': oendtwo, 'fukuendtwo': fukuendtwo })

    };
    useEffect(() => {
        // setValue('')
        var tempGoList = []
        var tempBackList = []
        for (var i = 0; i < item.kotsukikanList.length; i++) {
            if (item.kotsukikanList[i].ofuku == 1) {
                tempGoList.push(item.kotsukikanList[i])
            } else {
                tempBackList.push(item.kotsukikanList[i])
            }
        }
        if (tempGoList.length == 2 && tempBackList.length == 2) {
            setButtonDisabled(true)
        } else if (tempGoList.length == 2 && routeSelect == "1") {
            setButtonDisabled(true)
        } else if (tempBackList.length == 2 && routeSelect == "2") {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
        if (routeSelect == "1") {
            // 往路
            if (tempGoList.length == 0) {
                setStart("-")
                setEnd("-")
                // if (index == 0) {
                //     ostartone = ''
                //     oendone = ''
                // } else {
                //     ostarttwo = ''
                //     oendtwo = ''
                // }
                return
            }
            if (index == 0) {
                // 訪問先１
                setStart("自宅")
                // ostartone = '自宅'
                if ((null == item.sagyo_basho) || ("" == item.sagyo_basho)) {
                    var sagyo_basholabel = ""
                    masterData.gako_info.map((row) => {
                        if (row.no == item.homon_saki) {
                            sagyo_basholabel = row.gakoMeisho
                        }
                    })
                    setEnd(sagyo_basholabel)
                    // oendone = sagyo_basholabel
                } else if (item.sagyo_basho == "0") {
                    setEnd(item.sagyo_basho_sonota)
                    // oendone = item.sagyo_basho_sonota
                } else {
                    var sagyo_basholabel = ""
                    masterData.sagyobasyo_info.map((row) => {
                        if (row.no == item.sagyo_basho) {
                            sagyo_basholabel = row.homonsakiMeisho
                        }
                    })
                    setEnd(sagyo_basholabel)
                    // oendone = sagyo_basholabel
                }
            } else {
                // 訪問先２
                if ((null == item.sagyo_basho) || ("" == item.sagyo_basho)) {
                    var sagyo_basholabel = ""
                    masterData.gako_info.map((row) => {
                        if (row.no == item.homon_saki) {
                            sagyo_basholabel = row.gakoMeisho
                        }
                    })
                    setEnd(sagyo_basholabel)
                    // oendtwo = sagyo_basholabel
                } else if (item.sagyo_basho == "0") {
                    setEnd(item.sagyo_basho_sonota)
                    // oendtwo = item.sagyo_basho_sonota
                } else {
                    var sagyo_basholabel = ""
                    masterData.sagyobasyo_info.map((row) => {
                        if (row.no == item.sagyo_basho) {
                            sagyo_basholabel = row.homonsakiMeisho
                        }
                    })
                    setEnd(sagyo_basholabel)
                    // oendtwo = sagyo_basholabel
                }

                if (!visitData.homon_jiseki[0].sagyo_basho) {
                    let sakiName = ""
                    masterData.gako_info.map((item, i) => {
                        if (item.homonJissekiFuragu === "1" && visitData.homon_jiseki[0].homon_saki == item.no) {
                            sakiName = item.gakoMeisho
                        }
                    })
                    masterData.sagyobasyo_info.map((row, i) => {
                        if (sakiName == row.homonsakiMeisho) {
                            setStart(row.homonsakiMeisho)
                            // ostarttwo = row.homonsakiMeisho
                        }
                    })
                } else if (visitData.homon_jiseki[0].sagyo_basho == "0") {
                    setStart(visitData.homon_jiseki[0].sagyo_basho_sonota)
                    // ostarttwo = visitData.homon_jiseki[0].sagyo_basho_sonota
                } else {
                    var sagyo_basholabel = ""
                    masterData.sagyobasyo_info.map((row) => {
                        if (row.no == visitData.homon_jiseki[0].sagyo_basho) {
                            sagyo_basholabel = row.homonsakiMeisho
                        }
                    })
                    setStart(sagyo_basholabel)
                    // ostarttwo = sagyo_basholabel
                }

                visitData.homon_jiseki[0].kotsukikanList.map((row) => {
                    if (row.ofuku == 2) {
                        setStart("自宅")
                        // ostarttwo = '自宅'
                    }
                })
            }
        } else {
            // 復路
            if (tempBackList.length == 0) {
                setStart("")
                setEnd("")
                // if (index == 0) {
                //     fukustartone = ''
                //     fukuendone = ''
                // } else {
                //     fukustarttwo = ''
                //     fukuendtwo = ''
                // }
                return
            }
            if (index == 0) {
                // 訪問先１
                setEnd("自宅")
                // fukuendone = '自宅'
                if ((null == item.sagyo_basho) || ("" == item.sagyo_basho)) {
                    var sagyo_basholabel = ""
                    masterData.gako_info.map((row) => {
                        if (row.no == item.homon_saki) {
                            sagyo_basholabel = row.gakoMeisho
                        }
                    })
                    setStart(sagyo_basholabel)
                    // fukustartone = sagyo_basholabel
                } else if (item.sagyo_basho == "0") {
                    setStart(item.sagyo_basho_sonota)
                    // fukustartone = item.sagyo_basho_sonota
                } else {
                    var sagyo_basholabel = ""
                    masterData.sagyobasyo_info.map((row) => {
                        if (row.no == item.sagyo_basho) {
                            sagyo_basholabel = row.homonsakiMeisho
                        }
                    })
                    setStart(sagyo_basholabel)
                    // fukustartone = sagyo_basholabel
                }
            } else {
                // 訪問先２
                if ((null == item.sagyo_basho) || ("" == item.sagyo_basho)) {
                    var sagyo_basholabel = ""
                    masterData.gako_info.map((row) => {
                        if (row.no == item.homon_saki) {
                            sagyo_basholabel = row.gakoMeisho
                        }
                    })
                    setStart(sagyo_basholabel)
                    // fukustarttwo = sagyo_basholabel
                } else if (item.sagyo_basho == "0") {
                    setStart(item.sagyo_basho_sonota)
                    // fukustarttwo = item.sagyo_basho_sonota
                } else {
                    var sagyo_basholabel = ""
                    masterData.sagyobasyo_info.map((row) => {
                        if (row.no == item.sagyo_basho) {
                            sagyo_basholabel = row.homonsakiMeisho
                        }
                    })
                    setStart(sagyo_basholabel)
                    // fukustarttwo = sagyo_basholabel
                }
                setEnd("自宅")
                // fukuendtwo = '自宅'
            }
        }
        // setStartOfuku({ 'ostartone': ostartone, 'fukustartone': fukustartone, 'ostarttwo': ostarttwo, 'fukustarttwo': fukustarttwo })
        // setEndOfuku({ 'oendone': oendone, 'fukuendone': fukuendone, 'oendtwo': oendtwo, 'fukuendtwo': fukuendtwo })
    }, [visitData, routeSelect])


    const deleteRouteData = (indexRoute, routeIndex) => {

        visitData?.homon_jiseki[indexRoute]?.kotsukikanList?.splice(routeIndex, 1)
        let tempList = visitData.homon_jiseki
        var temp = []
        if (indexRoute == 0 && tempList.length > 1) {
            for (var i = 0; i < tempList[0].kotsukikanList.length; i++) {
                if (tempList[0].kotsukikanList[i].ofuku == 2) {
                    temp.push(tempList[0].kotsukikanList[i])
                }
            }
            if (temp.length == 0) {
                tempList[1].homon_shuppatsu = tempList[0].homon_tochaku
            }
        }
        setVisitData({ ...visitData })

        if (routeSelect == "1") {
            if (index == 0) {
                ostartone = ''
                oendone = ''
            } else {
                ostarttwo = ''
                oendtwo = ''
            }
            // setValue('1')
        } else {
            if (index == 0) {
                fukustartone = ''
                fukuendone = ''
            } else {
                fukustarttwo = ''
                fukuendtwo = ''
            }
            // setValue('2')
        }
        setStartOfuku({ 'ostartone': ostartone, 'fukustartone': fukustartone, 'ostarttwo': ostarttwo, 'fukustarttwo': fukustarttwo })
        setEndOfuku({ 'oendone': oendone, 'fukuendone': fukuendone, 'oendtwo': oendtwo, 'fukuendtwo': fukuendtwo })

    };
    const addRouteData = () => {
        var tempGoList = []
        var tempBackList = []
        for (var i = 0; i < visitData.homon_jiseki[index].kotsukikanList.length; i++) {
            if (visitData.homon_jiseki[index].kotsukikanList[i].ofuku == 1) {
                tempGoList.push(visitData.homon_jiseki[index].kotsukikanList[i])
            } else {
                tempBackList.push(visitData.homon_jiseki[index].kotsukikanList[i])
            }
        }

        if (routeSelect == "1") {
            if (tempGoList.length >= 2) {
                return
            }
            let tempList = visitData.homon_jiseki
            tempList[index].kotsukikanList.push(routeItem)
            log(tempList)
            setVisitData(JSON.parse(JSON.stringify({ "homon_jiseki": tempList })))
        } else {
            if (tempBackList.length >= 2) {
                return
            }
            let tempList = visitData.homon_jiseki
            tempList[index].kotsukikanList.push(routeItemEnd)
            if (index == 0 && tempList.length > 1) {
                tempList[1].homon_shuppatsu = "自宅"
            }
            setVisitData(JSON.parse(JSON.stringify({ "homon_jiseki": tempList })))
        }
    };
    // const addRouteEndData = () => {
    //     let tempList = visitData.homon_jiseki
    //     tempList[index].kotsukikanList.push(routeItemEnd)
    //     log(tempList)
    //     setVisitData(JSON.parse(JSON.stringify({ "homon_jiseki": tempList })))
    // };

    const changeSelectRoute = (value) => {
        setRouteSelect(value)
        setChecked(false)
    };


    const onCheckedChange = (e) => {
        let goList = []
        item.kotsukikanList.map((routeItem, routeIndex) => {
            if (routeItem.ofuku == 1) {
                goList.push(routeItem)
            }
        })
        let tempList = visitData.homon_jiseki
        tempList[index].kotsukikanList = []
        let backList = []
        goList.map((goItem, goIndex) => {
            tempList[index].kotsukikanList.push(goItem)
            backList.push({
                "kotsukikan_jiseki_id": null,
                "homon_jiseki_id": goItem.homon_jiseki_id ?? null,
                "ofuku": 2,
                "kotsukikan_code": goItem.kotsukikan_code ?? null,
                "shuppatsu_ic": goItem.tochaku_ic ?? null,
                "tochaku_ic": goItem.shuppatsu_ic ?? null,
                "unchin_kosoku_ryokin": goItem.unchin_kosoku_ryokin ?? null,
                "delete_flag": null,
                "expand": 0
            })
        })
        backList.reverse()
        if (e.target.checked) {
            backList.map((backItem, backIndex) => {
                tempList[index].kotsukikanList.push(backItem)
            })
        }

        setVisitData(JSON.parse(JSON.stringify({ "homon_jiseki": tempList })))
        setChecked(e.target.checked);
    };


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(840)}
            closable={false}

        >
            <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{visitData?.homon_jiseki[index].name + "の「経路」入力"}</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <div style={{ marginLeft: VW(24), marginRight: VW(24) }}>
                <div style={{ width: "100%", marginBottom: VH(8) }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: 'space-between',
                    }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <div className={classes.item}>
                                <div className={classes.itemText} >経路</div>
                                <div className={classes.itemInput}  >
                                    <Select
                                        className={classes.select}
                                        style={{
                                            width: VW(124), height: VH(56), borderRadius: VW(6), fontSize: VW(12)

                                        }}
                                        value={routeSelect}
                                        label=""
                                        onChange={(event) => changeSelectRoute(event.target.value)}
                                        displayEmpty
                                    >
                                        {routeLable.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className={classes.itemText}>出発地</div>
                                <div className={classes.itemInput}>
                                    <div style={{ width: VW(124), height: VH(56), borderRadius: VW(6), border: "1px solid #E0E0E0", display: "flex", alignItems: "center", fontSize: VW(12), paddingLeft: VW(8) }}>
                                        {start}
                                    </div>
                                </div>
                                <div className={classes.itemText}>到着地</div>
                                <div className={classes.itemInput}>
                                    <div style={{ width: VW(124), height: VH(56), borderRadius: VW(6), border: "1px solid #E0E0E0", display: "flex", alignItems: "center", fontSize: VW(12), paddingLeft: VW(8) }}>
                                        {end}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.buttonGroup4}  >
                            {/* <Button onClick={onClose}>保存</Button> */}

                        </div>
                    </div>
                </div>
                {routeSelect == "2" ?
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ width: VW(24), height: VH(24), borderBlockColor: '#FFFFFF' }} sx={{ color: "#E0E0E0" }} checked={checked} onChange={onCheckedChange} />
                        <div style={{ color: "#27303D", fontSize: VW(12) }}>
                            往路と同じ
                        </div>
                    </div>
                    :
                    null
                }
                {routeSelect == "1" ? Object.keys(visitData) !== 0 && visitData?.homon_jiseki[index].kotsukikanList.map((routeItem, routeIndex) => {
                    if (routeItem.ofuku === 1) {
                        startNameNum++
                        return (
                            <div key={routeIndex}
                                style={{
                                    backgroundColor: "#ffffff",
                                    paddingLeft: VW(22),
                                    paddingRight: VW(22),
                                    paddingTop: VH(16),
                                    paddingBottom: VH(16),
                                    borderRadius: "6px",
                                    marginTop: VH(4)
                                }}>

                                <RouteView
                                    visitData={visitData}
                                    setVisitData={setVisitData}
                                    routeIndex={routeIndex}
                                    index={index}
                                    item={item}
                                    routeItem={routeItem}
                                    updateVisitData={updateVisitData}
                                    masterData={masterData}
                                    updateVisitRouteData={updateVisitRouteData}
                                    deleteRouteData={deleteRouteData}
                                    nameNum={startNameNum}
                                ></RouteView>
                            </div>

                        )
                    } else {
                        return null
                    }

                }) : Object.keys(visitData) !== 0 && visitData?.homon_jiseki[index].kotsukikanList.map((routeItem, routeIndex) => {
                    if (routeItem.ofuku === 2) {
                        endNameNum++
                        return (
                            <div
                                key={routeIndex + 1}
                                style={{
                                    backgroundColor: "#ffffff",
                                    paddingLeft: VW(22),
                                    paddingRight: VW(22),
                                    paddingTop: VH(16),
                                    paddingBottom: VH(16),
                                    borderRadius: "6px",
                                    marginTop: VH(4)
                                }}>
                                <RouteView
                                    visitData={visitData}
                                    setVisitData={setVisitData}
                                    routeIndex={routeIndex}
                                    index={index}
                                    item={item}
                                    routeItem={routeItem}
                                    updateVisitData={updateVisitData}
                                    masterData={masterData}
                                    updateVisitRouteData={updateVisitRouteData}
                                    deleteRouteData={deleteRouteData}
                                    nameNum={endNameNum}
                                ></RouteView>
                            </div>
                        )
                    } else {
                        return null
                    }

                })}


                <div className={classes.buttonGroup2} style={{ paddingBottom: VH(82) }}  >
                    <Button onClick={addRouteData} disabled={buttonDisabled}>移動手段の追加</Button>
                </div>

                {/* <div className={classes.buttonGroup}>
                    <div className={classes.buttonGroup4} style={{ marginBottom: VH(82) }}>
                        <Button style={{ width: VW(240), height: VH(50), fontSize: VW(16), fontWeight: 'bold' }} onClick={onClose}>戻る</Button>
                    </div>
                </div> */}
            </div>
        </Modal>
    )
}

RouteDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(RouteDialog);