import { VW, VH } from "../../componets/function/sizeAdapter"
function HeaderBarIcon() {
    return (
        <svg width={VW(20)} height={VH(16)} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.98023e-07 11C2.98023e-07 10.4477 0.447716 10 1 10H10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H1C0.447715 12 2.98023e-07 11.5523 2.98023e-07 11Z" fill="#363434" />
            <path d="M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H1C0.447715 2 0 1.55228 0 1Z" fill="#363434" />
            <path d="M0 6C0 5.44772 0.447715 5 1 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H1C0.447715 7 0 6.55228 0 6Z" fill="#363434" />
        </svg>
    )
}

export default HeaderBarIcon;