import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { routerNames, localstorage } from '../../wordsFile/wordsFile'
import { VW, VH } from "../../componets/function/sizeAdapter"
import Stepper from '@mui/material/Stepper';
import { getSession, getUserPool, getCurrSession } from "../../network/UserPool";
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded, ArrowLeft } from "@mui/icons-material";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Modal, Button, Input, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import arrowLeft from "../../componets/icon/arrowLeft.svg";
import arrowRight from "../../componets/icon/arrowRight.svg";
import editRounded from "../../componets/icon/EditRounded.svg";
import inactivenot from "../../componets/icon/inactivenot.svg";
import inactived from "../../componets/icon/inactived.svg";
import deleteRounded from "../../componets/icon/DeleteForeverRounded.svg";
import { Box, TextField } from "@mui/material";
import log from "../../componets/function/log";
import timeFormat from "../../componets/function/timeFormat";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiTerminalList, ApiTerminalDeleteList, ApiAddTanmatsuInfo } from "../../network/api";
import BulkRegistrationDialog from "../../componets/dialog/BulkRegistrationDialog";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import pako from 'pako';
import Common_successAlert from "../../componets/common/Common_successAlert";
import { Link } from "react-router-dom";
import {
    MenuItem, Select,
    List,
    ListItemButton,
    Collapse,
    Autocomplete
} from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SearchRounded, DeleteForeverRounded, FileDownloadRounded, EditRounded } from "@mui/icons-material";
import { committeeCode_Option, ownerNo_Option, flag_Option, flag_Option_Storage, type_Option, status_Option, operating_Option, checked_Option, break_Option } from "../../wordsFile/selectItem";
import Pagination from "@mui/material/Pagination";
import DeleteDialog from "./DeleteDialog";
import TerminalCsvOutputDialog from "./TerminalCsvOutputDialog";
import SingleInputDialog from "./SingleInputDialog";
import TerminalBatchUpdateDialog from "./csvUpload/TerminalBatchUpdateDialog";
import StorageBatchUploadDialog from "./csvUpload/StorageBatchUploadDialog";
import StorageBatchUpdateDialog from "./csvUpload/StorageBatchUpdateDialog";

const styles = (theme) => ({
    ground: {
        height: '100%',
        paddingTop: "8px",
        paddingLeft: "24px",
        paddingRight: "24px"
    },
    itemMonthlyHead: {
        color: "#27303D",
        fontSize: "24px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px"
    },
    icon: {
        color: "#ffffff",
        padding: 0,
        width: "24px",
        height: "24px"
    },
    listButton: {
        backgroundColor: "#ffffff",
        width: "123px",
        height: "53px",
        fontWeight: 400,
        fontSize: "14px",

    },
    item: {
        display: "flex",
        alignItems: "center",
        borderTop: '1px solid #E0E0E0',
        borderBottom: '1px solid #E0E0E0',
    },
    itemText: {
        color: "#ffffff",
        fontSize: "12px",
        fontWeight: 700,
        width: "80px",
        height: "42px",
        backgroundColor: "#2095D4",
        display: "flex",
        alignItems: "center",
        padding: "8px",
    },
    itemInput: {
        width: "390px",
        height: "42px",
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        paddingLeft: "8px",
        fontSize: "12px",
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
        '& > Button': {
            height: "56px",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: 700,
            backgroundColor: '#FFF',
            color: theme.palette.primary.main,
            border: `1px solid #2195D3`,
        }
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
        '& > Button': {
            height: "56px",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: 700,
            backgroundColor: theme.palette.primary.main,
            color: '#FFF',
            border: `1px solid #2195D3`,
        }
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff",
            }
        },
        '& .MuiSelect-select': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    page: {
        "& .MuiPaginationItem-root": {
            color: "#000000",
        }
    },
    autocomplete: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: "26px",
            padding: 0
        },
        // '& .MuiInputBase-input': {
        //     height: "26px",
        // },
        '& .MuiInputBase-root': {
            height: "26px",
            backgroundColor: "#fff"
        },
        // '& .MuiAutocomplete-input': {
        //     fontSize: "16px",
        //     height: "26px",
        //     border: '0',
        // },
        // '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
        //     backgroundColor: "rgba(0, 0, 0, 0.04)",
        // },
    },
    text: {
        height: "26px",
        fontSize: "16px",
        boder: "0px"
    },
    textContent: {
        height: "26px",
        fontSize: "16px",
        boder: "0px"
    },
    input: {
        height: 26,
        fontSize: 14,
    },
    scrollbar: {
        '& ::-webkit-scrollbar': {
            width: "20px",
            height: "20px"
        },
        '& ::-webkit-scrollbar-thumb': {
            borderRadius: "5px",
            backgroundColor: "#808080",
            height: "80px"
        }
    },
});


//Comparer Function 升順
function GetSortDownOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
//Comparer Function 降順
function GetSortUpOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}

function unzip(b64Data) {
    var strData = atob(b64Data);
    var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
    var binData = new Uint8Array(charData);
    // unzip
    var data = pako.inflate(binData);
    var strData = new TextDecoder("utf-8").decode(data);
    return decodeURIComponent(strData);
}

function TerminalManage(props) {
    const {
        classes,
        setSelectFlag,
        masterData
    } = props

    const location = useLocation();
    const navigate = useNavigate()

    const { state } = location;

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [deleteData, setDeleteData] = useState([]);
    const deleteDialogConfirm = () => {

        //削除APIを呼び出しする
        deleteListData()
    }
    const [BulkRegistrationDialogOpen, setBulkRegistrationDialogOpen] = useState(false)
    const BulkRegistrationDialogOnClose = () => {
        setBulkRegistrationDialogOpen(false)
    }
    const BulkRegistrationDialogCloseOnOpen = () => {
        handleClick()
        setBulkRegistrationDialogOpen(true)
    }
    const [TerminalBatchUpdateDialogOpen, setTerminalBatchUpdateDialogOpen] = useState(false)
    const TerminalBatchUpdateDialogOnClose = () => {
        setTerminalBatchUpdateDialogOpen(false)
    }
    const TerminalBatchUpdateDialogCloseOnOpen = () => {
        handleClick()
        setTerminalBatchUpdateDialogOpen(true)
    }

    const [StorageBatchUploadDialogOpen, setStorageBatchUploadDialogOpen] = useState(false)
    const StorageBatchUploadDialogOnClose = () => {
        setStorageBatchUploadDialogOpen(false)
    }
    const StorageBatchUploadDialogCloseOnOpen = () => {
        handleClick()
        setStorageBatchUploadDialogOpen(true)
    }


    const [StorageBatchUpdateDialogOpen, setStorageBatchUpdateDialogOpen] = useState(false)
    const StorageBatchUpdateDialogOnClose = () => {
        setStorageBatchUpdateDialogOpen(false)
    }
    const StorageBatchUpdateDialogCloseOnOpen = () => {
        handleClick()
        setStorageBatchUpdateDialogOpen(true)
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  search value
    const [committeeCode, setCommitteeCode] = useState("1"); // 教育委員会
    const [gakoCode, setGakoCode] = useState(""); // 学校コード
    const [ownerNo, setOwnerNo] = useState(""); // 利用者No
    const [newFlag, setNewFlag] = useState("0"); // 履歴フラグ（含まれない場合、０を受け渡す）
    const [deleteFlag, setDeleteFlag] = useState("0"); // 削除含フラグ（含まれない場合、０を受け渡す）
    const [groupFlag, setgroupFlag] = useState("0"); // 預かり情報の個別表示フラグ（1つずつ表示する場合、０を受け渡す）
    const [appleId, setAppleId] = useState(""); // 検索キーワード

    const [listData, setListData] = useState();
    const [searchData, setSearchData] = useState();
    const [showData, setShowData] = useState();

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 500;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const [terminalCsvOutputDialogOpen, setTerminalCsvOutputDialogOpen] = useState(false)

    const terminalCsvOutputDialogOnClose = () => {
        setTerminalCsvOutputDialogOpen(false)
    }
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const deleteDialogOnClose = () => {
        setDeleteDialogOpen(false)
    }


    const terminalCsvOutputDialogOnOpen = () => {
        setTerminalCsvOutputDialogOpen(true)
    }

    const deleteDialogOnOpen = () => {
        var list = []
        showData.terminal_info.map(item => {
            if (item.checked) {
                list.push(item)
            }
        })
        setDeleteData(list)
        if (list.length > 0) { setDeleteDialogOpen(true) }


    }

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    }
    const getGakoList = () => {
        let list = []
        masterData.gako_info.map((item, index) => {
            if (item.tanmatsuKanriFuragu == "1") {
                list.push(item)
            }
        })
        return list;
    };

    const [headerList, setHeaderList] = useState([
        {
            "name": "種別",
            "type": "select",
            "value": null,
            "code": null,
            "data": type_Option,
            "width1": "117px",
            "width2": "87px",
            "key": "syubetsu_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "学校CD",
            "type": "input",
            "value": null,
            "code": null,
            "data": null,
            "width1": "117px",
            "width2": "77px",
            "key": "gako_code",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "学校名",
            "type": "autofill",
            "value": null,
            "code": null,
            "data": getGakoList(),
            "width1": "268px",
            "width2": "224px",
            "key": "gako_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "管理番号",
            "type": "input",
            "value": null,
            "code": null,
            "data": null,
            "width1": "173px",
            "width2": "129px",
            "key": "kanri_no",
            "id": "terminal_id",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "利用者",
            "type": "select",
            "value": null,
            "code": null,
            "data": ownerNo_Option,
            "width1": "117px",
            "width2": "87px",
            "key": "owner_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "シリアル番号",
            "type": "input",
            "value": null,
            "code": null,
            "data": null,
            "width1": "201px",
            "width2": "159px",
            "key": "serial_num",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "MACアドレス",
            "type": "input",
            "value": null,
            "code": null,
            "data": null,
            "width1": "201px",
            "width2": "159px",
            "key": "mac_address",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "預かり",
            "type": "select",
            "value": null,
            "code": null,
            "data": checked_Option,
            "width1": "117px",
            "width2": "87px",
            "key": "storage_check",
            "checkBox": true,
            "sortKey": "down"
        },
        {
            "name": "預かり作業状況",
            "type": "select",
            "value": null,
            "code": null,
            "data": operating_Option,
            "width1": "242px",
            "width2": "198px",
            "key": "work_status_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "破損・紛失届",
            "type": "select",
            "value": null,
            "code": null,
            "data": break_Option,
            "width1": "117px",
            "width2": "97px",
            "key": "funshitsu_check",
            "checkBox": true,
            "sortKey": "down"
        },
        {
            "name": "インシデント",
            "type": "select",
            "value": null,
            "code": null,
            "data": checked_Option,
            "width1": "117px",
            "width2": "87px",
            "key": "incident_check",
            "checkBox": true,
            "sortKey": "down"
        },
        {
            "name": "ステータス",
            "type": "select",
            "value": null,
            "code": null,
            "data": status_Option,
            "width1": "142px",
            "width2": "118px",
            "key": "jyotai_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "memo",
            "type": "input",
            "value": null,
            "code": null,
            "data": null,
            "width1": "500px",
            "width2": "458px",
            "key": "memo",
            "checkBox": false,
            "sortKey": "down"
        }
    ]);

    useEffect(() => {
        setSelectFlag(routerNames.TerminalManage)
        getCurrSession()
        getListData()
    }, [setSelectFlag])

    const [windowHeight, setWindowHeight] = useState(305);

    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')

    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")

    const [move, setMove] = useState(false)

    const [singleInputDialogOpen, setSingleInputDialogOpen] = useState(false)
    const singleInputDialogOnClose = () => {
        setSingleInputDialogOpen(false)
    }
    const singleInputDialogOnOpen = (index) => {
        handleClick()
        setSingleInputDialogOpen(true)
    }
    const singleInputDialogOnConfirm = (data) => {
        let params = {
            "terminal_info": data
        }
        ApiAddTanmatsuInfo(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                setSuccessOpen(true)
                setSuccessContent("端末情報を登録しました。")
                getListData()
                setSingleInputDialogOpen(false)
            } else {
                setMove(true)
                setRrrorOpen(true)
                setErrorContent(resData.detail)
            }
        }).catch(err => {

        })
    }

    // window.addEventListener('resize', () => {
    //     // const windowWidth = window.innerWidth;
    //     let height = 305
    //     let innerHeight = window.innerHeight
    //     if (innerHeight <= 720) {
    //         setWindowHeight(height);
    //     } else {
    //         while (innerHeight - 720 > 50) {
    //             height += 50;
    //             innerHeight -= 50;
    //         }
    //         setWindowHeight(height);
    //     }

    // });

    const getListData = (date, changeDate) => {
        headerList.map((item) => {
            item.value = null
        })
        setHeaderList([...headerList])
        let params = {
            committee_code: committeeCode,
            gako_code: !gakoCode ? '' : gakoCode,
            owner_no: ownerNo,
            newflag: newFlag,
            delete_flag: deleteFlag,
            groupFlag: groupFlag,
            // appleid: "12446"
            appleid: appleId
        }
        ApiTerminalList(params).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                let resdatatemp = unzip(resData.terminal_info)
                resData.terminal_info = JSON.parse(resdatatemp)
                for (let index = 0; index < resData.terminal_info.length; index++) {
                    resData.terminal_info[index]["checked"] = false
                }
                setListData(resData)
                setSearchData(resData)
                setCurrentPage(1)
                setShowData({ "terminal_info": resData.terminal_info.slice(startIndex, endIndex) })
            }
        }).catch(err => {

        })
    }
    const updateHeaderData = (value, index, name) => {
        headerList[index].value = value
        let filteredData = listData.terminal_info.filter(item => {
            return (item.syubetsu_name?.toString()?.includes(headerList[0].value) || headerList[0].value == null) &&
                (item.gako_code?.toString()?.includes(headerList[1].value) || headerList[1].value == null) &&
                (item.gako_name?.toString()?.includes(headerList[2].value) || headerList[2].value == null) &&
                (item.kanri_no?.toString()?.includes(headerList[3].value) || headerList[3].value == null) &&
                (item.owner_name?.toString()?.includes(headerList[4].value) || headerList[4].value == null) &&
                (item.serial_num?.toString()?.includes(headerList[5].value) || headerList[5].value == null) &&
                (item.mac_address?.toString()?.includes(headerList[6].value) || headerList[6].value == null) &&
                (item.storage_check.toString() == headerList[7].value || headerList[7].value == null) &&
                (item.work_status_name?.toString()?.includes(headerList[8].value) || headerList[8].value == null) &&
                (item.funshitsu_check.toString() == headerList[9].value || headerList[9].value == null) &&
                (item.incident_check.toString() == headerList[10].value || headerList[10].value == null) &&
                (item.jyotai_name?.toString()?.includes(headerList[11].value) || headerList[11].value == null) &&
                (item.memo?.toString()?.includes(headerList[12].value) || headerList[12].value == null)
        });

        for (let index = 0; index < filteredData.length; index++) {
            filteredData[index]["checked"] = false
        }
        setCheckAll(false)
        setHeaderList([...headerList])
        setSearchData({ "terminal_info": filteredData })
        setCurrentPage(1)
        setShowData({ "terminal_info": filteredData.slice(startIndex, endIndex) })

    }
    const deleteListData = () => {
        let deleteDataList = []
        deleteData.forEach(element => {
            deleteDataList.push({ "terminal_id": element.terminal_id, "kanri_no": element.kanri_no, "newflag": element.newflag })
        });

        let params = {
            terminal_delete_info: deleteDataList
        }
        ApiTerminalDeleteList(params).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                // 削除ダイロッグクローズ
                deleteDialogOnClose()
                // 最新データを取得する
                getListData()
            }
        }).catch(err => {

        })
    }

    const clearHeaderData = (index, name) => {
        headerList[index].value = null
        let filteredData = listData.terminal_info.filter(item => {
            return (item.syubetsu_name?.toString()?.includes(headerList[0].value) || headerList[0].value == null) &&
                (item.gako_code?.toString()?.includes(headerList[1].value) || headerList[1].value == null) &&
                (item.gako_name?.toString()?.includes(headerList[2].value) || headerList[2].value == null) &&
                (item.kanri_no?.toString()?.includes(headerList[3].value) || headerList[3].value == null) &&
                (item.owner_name?.toString()?.includes(headerList[4].value) || headerList[4].value == null) &&
                (item.serial_num?.toString()?.includes(headerList[5].value) || headerList[5].value == null) &&
                (item.mac_address?.toString()?.includes(headerList[6].value) || headerList[6].value == null) &&
                (item.storage_check.toString() == headerList[7].value || headerList[7].value == null) &&
                (item.work_status_name?.toString()?.includes(headerList[8].value) || headerList[8].value == null) &&
                (item.funshitsu_check.toString() == headerList[9].value || headerList[9].value == null) &&
                (item.incident_check.toString() == headerList[10].value || headerList[10].value == null) &&
                (item.jyotai_name?.toString()?.includes(headerList[11].value) || headerList[11].value == null) &&
                (item.memo?.toString()?.includes(headerList[12].value) || headerList[12].value == null)
        });
        for (let index = 0; index < filteredData.length; index++) {
            filteredData[index]["checked"] = false
        }
        setCheckAll(false)
        setHeaderList([...headerList])
        // setShowData({ "terminal_info": filteredData })
        setSearchData({ "terminal_info": filteredData })
        setCurrentPage(1)
        setShowData({ "terminal_info": filteredData.slice(startIndex, endIndex) })
    }
    const onCheckAllChange = (e) => {
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        for (let index = 0; index < showData.terminal_info.length; index++) {
            if (e.target.checked) {
                showData.terminal_info[index]["checked"] = true
            } else {
                showData.terminal_info[index]["checked"] = false
            }
        }
        setShowData({ ...showData })

    };
    const onCheckChange = (checked, index) => {
        showData.terminal_info[index].checked = checked
        setShowData({ ...showData })

    };

    const [sortKey, setSortKey] = useState();

    //ソート順実現メソッド
    const onSortChange = (itemkey, itemName) => {
        if (showData?.terminal_info?.length > 0) {
            let tempSearchData = searchData
            let tempShowData = showData
            if (sortKey) {
                if (sortKey.key != itemkey) {
                    if (itemkey == "gako_code") {
                        tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                    } else {
                        tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                    }
                    setSortKey({ key: itemkey, sort: "up" })
                } else {
                    if (sortKey.sort == "up") {
                        if (itemkey == "gako_code") {
                            tempSearchData.terminal_info.sort((a, b) => b[`${itemkey}`] - a[`${itemkey}`]);
                        } else {
                            tempSearchData.terminal_info.sort((a, b) => b[`${itemkey}`].toString().localeCompare(a[`${itemkey}`]));
                        }
                        setSortKey({ key: itemkey, sort: "down" })
                    } else {
                        if (itemkey == "gako_code") {
                            tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                        } else {
                            tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                        }
                        setSortKey({ key: itemkey, sort: "up" })
                    }
                }
            } else {
                if (itemkey == "gako_code") {
                    tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                } else {
                    tempSearchData.terminal_info.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                }
                setSortKey({ key: itemkey, sort: "up" })
            }



            if (currentPage === 1) {
                tempShowData = { "terminal_info": tempSearchData.terminal_info.slice(0, endIndex) }
            } else {
                tempShowData = { "terminal_info": tempSearchData.terminal_info.slice(startIndex, endIndex) }
            }
            setSearchData({ ...tempSearchData })
            setShowData({ ...tempShowData })
        }
        // let nextSort;
        // for (let index = 0; index < headerList.length; index++) {
        //     if (headerList[index].key === itemkey) {
        //         // ソート順切替
        //         if (headerList[index].sortKey === "down") {
        //             nextSort = "up";
        //         } else if (headerList[index].sortKey === "up") {
        //             nextSort = "down";
        //         }
        //         headerList[index].sortKey = nextSort
        //         if (showData !== undefined) {
        //             let showDatatemp = showData.terminal_info
        //             if (nextSort === "up") {
        //                 showDatatemp.sort(GetSortDownOrder(itemkey));
        //             } else {
        //                 showDatatemp.sort(GetSortUpOrder(itemkey));
        //             }
        //             showData.terminal_info = showDatatemp
        //             setShowData({ ...showData });
        //         }
        //     } else {
        //         headerList[index].sortKey = "down";
        //     }
        // }
    };
    const handleChange = (e, p) => {
        setCurrentPage(p)
    };
    useEffect(() => {
        if (searchData) {
            if (currentPage === 1) {
                setShowData({ "terminal_info": searchData.terminal_info.slice(0, endIndex) })
            } else {
                setShowData({ "terminal_info": searchData.terminal_info.slice(startIndex, endIndex) })
            }
        }
    }, [currentPage])

    const getGakoAutoList = () => {
        let list = []
        masterData.gako_info.map((item, index) => {
            if (item.tanmatsuKanriFuragu == "1") {
                list.push({ "label": item.gakoMeisho })
            }
        })
        return list;
    };
    const formatIdList = () => {
        let idList = []
        showData.terminal_info.map(item => {
            if (item.checked) {
                idList.push({ terminalId: item.terminal_id, name: item.kanri_no })
            }
        })
        if (idList.length > 0) {
            if (idList.length > 50) {
                idList = idList.slice(0, 50);
            }
            jumpToDetail(idList, "muilty")
        }
    };
    const jumpToDetail = (idList, from) => {
        if (from == "single") {
            navigate(routerNames.TerminalDetail, { state: { idList: idList, from: from } })
        } else {

            const jsonString = JSON.stringify({ idList: idList, from: from });
            // const base64Encoded = btoa(jsonString);
            const base64EncodedData = btoa(unescape(encodeURIComponent(jsonString)));
            const urlEncodedData = encodeURIComponent(base64EncodedData);
            window.open(`/home/terminalDetail?id=${urlEncodedData}`, '_blank');
        }
    };
    const none = () => {
        return
    };

    const [divHeight, setHeight] = useState(100);
    function getDivHeightById(id) {
        const div = document.getElementById(id);
        if (div) {
            return div.clientHeight;
        } else {
            return null;
        }
    }
    useEffect(() => {
        const s1 = document.getElementById("s1");
        const s2 = document.getElementById("s2");
        const s3 = document.getElementById("s3");
        const s4 = document.getElementById("s4");
        s1.addEventListener("scroll", () => {
            s2.scrollTop = s1.scrollTop;
        });
        s4.addEventListener("scroll", () => {
            s2.scrollTop = s4.scrollTop;
        });

        s2.addEventListener("scroll", () => {
            s1.scrollTop = s2.scrollTop;
            s4.scrollTop = s2.scrollTop;
            s3.scrollLeft = s2.scrollLeft;
        });
        s3.addEventListener("scroll", () => {
            s2.scrollLeft = s3.scrollLeft;
        });
    }, [])

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { target, contentRect } = entry;
                setHeight(getDivHeightById('s2'))
            }
        });
        const targetDiv = document.getElementById('s2');
        observer.observe(targetDiv);
    }, [])
    return (
        <div className={classes.ground} style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.itemMonthlyHead} style={{ borderLeft: '5px solid #2195D3', fontWeight: 'bold' }}>
                {"端末管理" + "（" + `${searchData ? searchData.terminal_info.length : "0"}` + "件 表示）"}
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                border
                marginTop={"24px"}
            />
            <div style={{ display: "flex", marginTop: "16px" }}>
                <div className={classes.item}>
                    <div className={classes.itemText} style={{ width: "76px", height: "42px" }}>
                        教育委員会
                    </div>
                    <div className={classes.itemInput} style={{ width: "287px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "277px", height: "26px", borderRadius: "4px"

                            }}
                            value={committeeCode || ""}
                            label=""
                            onChange={(event) => setCommitteeCode(event.target.value)}
                            displayEmpty
                        >
                            {committeeCode_Option.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div className={classes.itemText} style={{ width: "58px", height: "42px" }}>
                        学校名
                    </div>
                    <div className={classes.itemInput} style={{ width: "263px", height: "42px" }}>
                        <Autocomplete
                            className={classes.autocomplete}
                            disablePortal
                            options={getGakoAutoList()}
                            sx={{ width: 247 }}
                            value={gakoCode || ""}
                            onChange={(event: any, newValue: string | null) => {
                                setGakoCode(newValue != null ? newValue.label : "");
                            }}
                            // inputValue={gakoCode || ""}
                            // onInputChange={(event, newInputValue) => {
                            //     setGakoCode(newInputValue);
                            // }}
                            renderInput={(params) => <TextField {...params} size="small"
                            // InputProps={{
                            //     className: classes.input,


                            // }}
                            />}
                        />
                        {/* <Select
                            className={classes.select}
                            style={{
                                width: "247px", height: "26px", borderRadius: "4px"

                            }}
                            sx={{ "& .MuiSelect-iconOutlined": { display: gakoCode ? 'none' : '' } }}
                            endAdornment={<ClearIcon sx={{ display: gakoCode ? "" : "none", width: "15px", height: "15px" }} onClick={() => setGakoCode("")}></ClearIcon>}
                            value={gakoCode || ""}
                            label=""
                            onChange={(event) => setGakoCode(event.target.value)}
                            displayEmpty
                        >
                            {masterData.gako_info.map((item, index) => {
                                if (item.tanmatsuKanriFuragu == "1") {
                                    return (
                                        <MenuItem key={item.no} value={item.gakoMeisho}>{item.gakoMeisho}</MenuItem>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </Select> */}


                    </div>
                    <div className={classes.itemText} style={{ width: "58px", height: "42px" }}>
                        利用者
                    </div>
                    <div className={classes.itemInput} style={{ width: "126px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "110px", height: "26px", borderRadius: "4px", position: "relative"

                            }}
                            sx={{ "& .MuiSelect-iconOutlined": { display: ownerNo ? 'none' : '' } }}
                            endAdornment={<ClearIcon sx={{ display: ownerNo ? "" : "none", width: "15px", height: "15px", padding: 0, margin: 0 }} onClick={() => setOwnerNo("")}></ClearIcon>}
                            value={ownerNo || ""}
                            label=""
                            onChange={(event) => setOwnerNo(event.target.value)}
                            displayEmpty
                        >
                            {ownerNo_Option.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>

                </div>
                <List style={{ height: "42px", padding: 0, backgroundColor: "#2095D4", borderRadius: "4px", marginLeft: "14px" }}>
                    <ListItemButton style={{ display: "flex", justifyContent: 'center', position: 'relative', height: "42px", width: "123px" }} onClick={handleClick}>
                        <span style={{ fontSize: "16px", color: "#ffffff", fontWeight: 700 }}>{"新規登録"}</span>
                        <div style={{ display: "flex", justifyContent: 'center', position: 'relative', }}>
                            {open ? <ArrowDropUpIcon className={classes.icon} /> : <ArrowDropDownIcon className={classes.icon} />}
                        </div>
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{ position: 'absolute', width: '100%', zIndex: '9999' }}>
                        <List component="div" disablePadding>
                            <Button
                                className={classes.listButton} style={{ borderRadius: "4px 4px 0px 0px" }}
                                onClick={singleInputDialogOnOpen}
                            >個別登録</Button>
                        </List>
                        <List component="div" disablePadding>
                            <Button
                                className={classes.listButton} style={{ borderRadius: "0px 0px 0px 0px" }}
                                onClick={BulkRegistrationDialogCloseOnOpen}
                            >一括登録</Button>
                        </List>
                        <List component="div" disablePadding>
                            <Button
                                className={classes.listButton} style={{ borderRadius: "0px 0px 0px 0px" }}
                                onClick={TerminalBatchUpdateDialogCloseOnOpen}
                            >一括更新</Button>
                        </List>
                        <List component="div" disablePadding>
                            <Button
                                className={classes.listButton} style={{ borderRadius: "0px 0px 0px 0px" }}
                                onClick={StorageBatchUploadDialogCloseOnOpen}
                            >預かり一括登録</Button>
                        </List>
                        <List component="div" disablePadding>
                            <Button
                                className={classes.listButton} style={{ borderRadius: "0px 0px 4px 4px" }}
                                onClick={StorageBatchUpdateDialogCloseOnOpen}
                            >預かり一括更新</Button>
                        </List>
                    </Collapse>
                </List>
            </div>
            <div style={{ display: "flex", marginTop: "16px" }}>
                <div className={classes.item}>
                    <div className={classes.itemText} style={{ width: "44px", height: "42px" }}>
                        履歴
                    </div>
                    <div className={classes.itemInput} style={{ width: "127px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "110px", height: "26px", borderRadius: "4px"

                            }}
                            value={newFlag || ""}
                            label=""
                            onChange={(event) => setNewFlag(event.target.value)}
                            displayEmpty
                        >
                            {flag_Option.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div className={classes.itemText} style={{ width: "66px", height: "42px" }}>
                        削除端末
                    </div>
                    <div className={classes.itemInput} style={{ width: "126px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "110px", height: "26px", borderRadius: "4px"
                            }}
                            value={deleteFlag || ""}
                            label=""
                            onChange={(event) => setDeleteFlag(event.target.value)}
                            displayEmpty
                        >
                            {flag_Option.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    <div className={classes.itemText} style={{ width: "76px", height: "42px" }}>
                        預かり情報
                    </div>
                    <div className={classes.itemInput} style={{ width: "158px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "142px", height: "26px", borderRadius: "4px"
                            }}
                            value={groupFlag || ""}
                            label=""
                            onChange={(event) => setgroupFlag(event.target.value)}
                            displayEmpty
                        >
                            {flag_Option_Storage.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>

                </div>
                <div style={{ borderRadius: "0px 4px 4px 0px", paddingLeft: "8px" }}>
                    <Input
                        style={{ height: "42px", width: "396px" }}
                        value={appleId || ""}
                        onChange={(event) => setAppleId(event.target.value)}
                        placeholder="検索キーワード"
                    />
                </div>
                <div onClick={getListData} style={{ borderRadius: "0px 4px 4px 0px", width: "47px", height: "41px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1D3C70" }}>
                    <SearchRounded style={{ color: "#ffffff", width: "24px", height: "24px" }}></SearchRounded>
                </div>
                <div onClick={deleteDialogOnOpen} style={{ borderRadius: "4px", width: "50px", height: "42px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffffff", border: "1px solid #E0E0E0", marginLeft: "8px" }}>
                    <DeleteForeverRounded style={{ color: "#27303D", width: "24px", height: "24px" }}></DeleteForeverRounded>
                </div>
                <div onClick={terminalCsvOutputDialogOnOpen} style={{ borderRadius: "4px", width: "50px", height: "42px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffffff", border: "1px solid #2095D4", marginLeft: "8px" }}>
                    <FileDownloadRounded style={{ color: "#2095D4", width: "24px", height: "24px" }}></FileDownloadRounded>
                </div>
                <div style={{ borderRadius: "4px", width: "50px", height: "42px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffffff", border: "1px solid #E0E0E0", marginLeft: "8px" }}>
                    <EditRounded style={{ color: "#27303D", width: "24px", height: "24px" }} onClick={formatIdList}></EditRounded>
                </div>
            </div>
            <div className={classes.scrollbar} style={{ display: "flex", maxHeight: "100%", overflowY: "hidden", maxWidth: "100%", width: "100%" }}>
                <div style={{ marginTop: "24px", }}>
                    <div style={{ backgroundColor: "#27303D", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0" }}>
                        <Checkbox style={{ width: "24px", height: "24px", borderBlockColor: '#FFFFFF' }} sx={{ color: "#fff" }} key={checkAll} indeterminate={indeterminate} checked={checkAll} onChange={onCheckAllChange} />
                    </div>
                    <div style={{ backgroundColor: "#27303D", width: "40px", height: "40px", borderRight: "1px solid #E0E0E0" }} />
                    <div id="s4" className="scroll_sync" style={{ overflow: "hidden", height: divHeight }}>
                        {showData && showData.terminal_info.map((item, listIndex) => {
                            return (
                                <div style={{ backgroundColor: "#FFFFFF" }}>
                                    <div style={{ width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderLeft: "1px solid #E0E0E0", borderBottom: "1px solid #E0E0E0", borderRight: "1px solid #E0E0E0" }}>
                                        <Checkbox sx={{ color: "#E0E0E0", width: "24px", height: "24px" }} checked={item.checked} onChange={(event) => onCheckChange(event.target.checked, listIndex)} />

                                    </div>
                                </div>
                            )

                        })}
                    </div>

                </div>
                <div id="left" style={{ maxWidth: "100%" }}>
                    <div style={{ marginTop: "24px", backgroundColor: "#27303D", width: '40px', height: "40px", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontWeight: 700, fontSize: "14px", borderRight: "1px solid #E0E0E0", }}>
                        No.
                    </div>
                    <div style={{ backgroundColor: "#27303D", width: "40px", height: "40px", borderRight: "1px solid #E0E0E0" }} />

                    <div id="s1" className="scroll_sync" style={{ overflow: "hidden", height: divHeight }}>
                        {showData && showData.terminal_info.map((item, listIndex) => {
                            let backgroundColor = (listIndex + 1) % 2 === 1 ? "#FFFFFF" : "#F4F5F9"
                            return (
                                <div key={listIndex} style={{ backgroundColor: "#FFFFFF", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", color: "#27303D", fontWeight: 400, fontSize: "11px", paddingLeft: "2px", borderBottom: "1px solid #E0E0E0", borderRight: "1px solid #E0E0E0", }}>
                                    {listIndex + 1 + (currentPage - 1) * 500}
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div id="right" style={{ display: "flex", flexDirection: "column", flex: 1, overflowY: "hidden", maxWidth: "100%", width: "100%" }}>
                    <div id="s3" style={{ marginRight: "20px", marginTop: "24px", display: "flex", overflowX: showData?.terminal_info?.length > 0 ? "hidden" : "auto", minHeight: "80px", paddingRight: 8 }}>
                        {headerList.map((item, headerIndex) => {
                            return (
                                <div key={headerIndex}>
                                    <div onClick={() => onSortChange(item.key, item.name)} style={{ backgroundColor: "#27303D", width: item.width1, height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0", color: "#fff", fontWeight: 700, fontSize: "14px", }}>
                                        {item.name}
                                    </div>
                                    <div style={{ backgroundColor: "#27303D", width: item.width1, height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0", paddingLeft: "8px", paddingRight: "8px" }}>
                                        {
                                            item.type === "select" ?
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                    <Select
                                                        className={classes.select}
                                                        style={{
                                                            width: item.width2, height: "26px", borderRadius: "4px", backgroundColor: "#fff"
                                                        }}
                                                        sx={{ margin: 0, padding: 0 }}
                                                        value={item.value || ""}
                                                        label=""
                                                        onChange={(event) => updateHeaderData(event.target.value, headerIndex, item.name, event)}
                                                        displayEmpty
                                                    >
                                                        {item.data && item.data.map((item, index) => {
                                                            if (headerIndex === 2) {
                                                                return (
                                                                    <MenuItem key={index} value={item.gakoMeisho} >{item.gakoMeisho}</MenuItem>
                                                                )
                                                            } else {
                                                                if (headerIndex == 10 || headerIndex == 9 || headerIndex == 7) {
                                                                    return (
                                                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <MenuItem key={index} value={item.label}>{item.label}</MenuItem>
                                                                    )
                                                                }
                                                            }

                                                        })}
                                                    </Select>
                                                    <ClearIcon
                                                        sx={{ width: "15px", height: "15px", color: "#fff" }}
                                                        onClick={() => clearHeaderData(headerIndex, item.name)}
                                                    >

                                                    </ClearIcon>
                                                </div>
                                                : item.type === "autofill" ?
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                        <Autocomplete
                                                            className={classes.autocomplete}
                                                            disablePortal
                                                            options={getGakoAutoList()}
                                                            sx={{ width: item.width2 }}
                                                            value={item.value || ""}
                                                            onChange={(event: any, newValue: string | null) => {
                                                                updateHeaderData(newValue != null ? newValue.label : "", headerIndex, item.name, event)
                                                            }}
                                                            renderInput={(params) => <TextField {...params} size="small"
                                                            // sx={{ backgroundColor: "#fff" }}
                                                            // InputProps={{
                                                            //     className: classes.input,


                                                            // }}
                                                            />}
                                                        />
                                                        <ClearIcon
                                                            sx={{ width: "15px", height: "15px", color: "#fff" }}
                                                            onClick={() => clearHeaderData(headerIndex, item.name)}
                                                        >

                                                        </ClearIcon>
                                                    </div>
                                                    :
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                        <Input
                                                            style={{ height: "26px", width: item.width2 }}
                                                            value={item.value || ""}
                                                            onChange={(event) => updateHeaderData(event.target.value, headerIndex, item.name)}
                                                            placeholder=""
                                                        />
                                                        <ClearIcon
                                                            sx={{ width: "15px", height: "15px", color: "#fff" }}
                                                            onClick={() => clearHeaderData(headerIndex, item.name)}
                                                        >

                                                        </ClearIcon>
                                                    </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div id="s2" style={{ overflowX: "auto", }}>
                        {showData && showData.terminal_info.map((item, listIndex) => {
                            let backgroundColor = (listIndex + 1) % 2 === 1 ? "#FFFFFF" : "#F4F5F9"
                            return (
                                <div key={listIndex} style={{ display: "flex" }}>
                                    {headerList.map((row, headerIndex) => {
                                        let itemborderLeft = headerIndex === 0 ? "1px solid #E0E0E0" : "0px solid #E0E0E0"
                                        return (

                                            <div key={headerIndex}>
                                                <div style={{ backgroundColor: "#FFFFFF", minWidth: row.width1, width: row.width1, height: "40px", display: "flex", borderRight: "1px solid #E0E0E0", color: "#27303D", fontWeight: 400, fontSize: "14px", borderBottom: "1px solid #E0E0E0", paddingLeft: row.checkBox ? 0 : "8px" }}>
                                                    <div key={headerIndex} style={{ backgroundColor: "#FFFFFF", width: row.width1, height: "40px", display: "flex", alignItems: "center", justifyContent: row.checkBox ? "center" : "", borderRight: "1px solid #E0E0E0", color: "#27303D", fontWeight: 400, fontSize: "14px", borderBottom: "1px solid #E0E0E0", paddingLeft: row.checkBox ? 0 : "8px", textDecoration: headerIndex === 3 ? 'underline' : '', color: headerIndex === 3 && item.newflag == 0 ? "#2095D4" : '#27303D' }}>

                                                        {row.checkBox ?
                                                            <Checkbox sx={{ color: "#E0E0E0", width: "24px", height: "24px", display: item[row.key] == false ? "none" : "" }} style={{ color: "#000000" }} checked={item[row.key] == false ? false : true} />
                                                            :
                                                            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', cursor: headerIndex === 3 ? "pointer" : "" }} onClick={() => { headerIndex === 3 ? jumpToDetail([{ terminalId: item[row.id], name: item[row.key] }], "single") : none() }} >
                                                                <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item[row.key]}</p>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div >
            {/* < div style={{ overflowY: "hidden", display: "flex", flexDirection: "column", flex: 1, marginBottom: "8px" }
            }>
                <div style={{ marginTop: "24px", display: "flex", width: "2469px", }}>
                    <div>
                        <div style={{ backgroundColor: "#27303D", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0" }}>
                            <Checkbox style={{ width: "24px", height: "24px", borderBlockColor: '#FFFFFF' }} sx={{ color: "#fff" }} key={checkAll} indeterminate={indeterminate} checked={checkAll} onChange={onCheckAllChange} />
                        </div>
                        <div style={{ backgroundColor: "#27303D", width: "40px", height: "40px", borderRight: "1px solid #E0E0E0" }} />
                    </div>
                    {headerList.map((item, headerIndex) => {
                        return (
                            <div key={headerIndex}>
                                <div onClick={() => onSortChange(item.key, item.name)} style={{ backgroundColor: "#27303D", width: item.width1, height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0", color: "#fff", fontWeight: 700, fontSize: "14px", }}>
                                    {item.name}
                                </div>
                                <div style={{ backgroundColor: "#27303D", width: item.width1, height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0", paddingLeft: "8px", paddingRight: "8px" }}>
                                    {
                                        item.type === "select" ?
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                <Select
                                                    className={classes.select}
                                                    style={{
                                                        width: item.width2, height: "26px", borderRadius: "4px", backgroundColor: "#fff"
                                                    }}
                                                    value={item.value || ""}
                                                    label=""
                                                    onChange={(event) => updateHeaderData(event.target.value, headerIndex, item.name, event)}
                                                    displayEmpty
                                                >
                                                    {item.data && item.data.map((item, index) => {
                                                        if (headerIndex === 2) {
                                                            return (
                                                                <MenuItem key={index} value={item.gakoMeisho} >{item.gakoMeisho}</MenuItem>
                                                            )
                                                        } else {
                                                            if (headerIndex == 10 || headerIndex == 9 || headerIndex == 7) {
                                                                return (
                                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                                )
                                                            } else {
                                                                return (
                                                                    <MenuItem key={index} value={item.label}>{item.label}</MenuItem>
                                                                )
                                                            }
                                                        }

                                                    })}
                                                </Select>
                                                <ClearIcon
                                                    sx={{ width: "15px", height: "15px", color: "#fff" }}
                                                    onClick={() => clearHeaderData(headerIndex, item.name)}
                                                >

                                                </ClearIcon>
                                            </div>
                                            : item.type === "autofill" ?
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                    <Autocomplete
                                                        className={classes.autocomplete}
                                                        disablePortal
                                                        options={getGakoAutoList()}
                                                        sx={{ width: item.width2 }}
                                                        value={item.value || ""}
                                                        onChange={(event: any, newValue: string | null) => {
                                                            updateHeaderData(newValue != null ? newValue.label : "", headerIndex, item.name, event)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} size="small"
                                                        // sx={{ backgroundColor: "#fff" }}
                                                        // InputProps={{
                                                        //     className: classes.input,


                                                        // }}
                                                        />}
                                                    />
                                                    <ClearIcon
                                                        sx={{ width: "15px", height: "15px", color: "#fff" }}
                                                        onClick={() => clearHeaderData(headerIndex, item.name)}
                                                    >

                                                    </ClearIcon>
                                                </div>
                                                :
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                    <Input
                                                        style={{ height: "26px", width: item.width2 }}
                                                        value={item.value || ""}
                                                        onChange={(event) => updateHeaderData(event.target.value, headerIndex, item.name)}
                                                        placeholder=""
                                                    />
                                                    <ClearIcon
                                                        sx={{ width: "15px", height: "15px", color: "#fff" }}
                                                        onClick={() => clearHeaderData(headerIndex, item.name)}
                                                    >

                                                    </ClearIcon>
                                                </div>
                                    }
                                </div>
                            </div>
                        )
                    })}

                </div>
                <div style={{ overflowY: "auto", width: "2469px", marginBottom: "8px" }} >

                    {showData && showData.terminal_info.map((item, listIndex) => {
                        return (
                            <div key={listIndex} style={{ display: "flex" }}>

                                <div style={{ backgroundColor: "#FFFFFF" }}>
                                    <div style={{ width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderLeft: "1px solid #E0E0E0", borderBottom: "1px solid #E0E0E0", borderRight: "1px solid #E0E0E0" }}>
                                        <Checkbox sx={{ color: "#E0E0E0", width: "24px", height: "24px" }} checked={item.checked} onChange={(event) => onCheckChange(event.target.checked, listIndex)} />

                                    </div>
                                </div>
                                {
                                    headerList.map((row, headerIndex) => {
                                        return (
                                            <div key={headerIndex} style={{ display: "flex" }}>
                                                <div key={headerIndex} style={{ backgroundColor: "#FFFFFF", width: row.width1, height: "40px", display: "flex", alignItems: "center", justifyContent: row.checkBox ? "center" : "", borderRight: "1px solid #E0E0E0", color: "#27303D", fontWeight: 400, fontSize: "14px", borderBottom: "1px solid #E0E0E0", paddingLeft: row.checkBox ? 0 : "8px", textDecoration: headerIndex === 3 ? 'underline' : '', color: headerIndex === 3 && item.newflag == 0 ? "#2095D4" : '#27303D' }}>
                                                    {row.checkBox ?
                                                        <Checkbox sx={{ color: "#E0E0E0", width: "24px", height: "24px", display: item[row.key] == false ? "none" : "" }} style={{ color: "#000000" }} checked={item[row.key] == false ? false : true} />
                                                        :
                                                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} onClick={() => { headerIndex === 3 ? jumpToDetail([{ terminalId: item[row.id], name: item[row.key] }], "single") : none() }} >
                                                            <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item[row.key]}</p>
                                                        </div>}
                                                </div>
                                            </div>
                                        )


                                    })
                                }
                            </div>
                        )
                    })}
                </div>
            </div > */}

            <Pagination
                page={currentPage}
                className={classes.page}
                count={searchData ? Math.ceil(searchData?.terminal_info?.length / 500) : 0}
                color="buttonBLue"
                onChange={handleChange}
                style={{
                    display: "flex", justifyContent: "center", marginBottom: 8, marginTop: 8
                }}
            />
            <BulkRegistrationDialog
                open={BulkRegistrationDialogOpen}
                onClose={BulkRegistrationDialogOnClose}
            />
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={deleteDialogOnClose}
                deleteConfirm={deleteDialogConfirm}
                data={deleteData}
            />
            <TerminalCsvOutputDialog
                open={terminalCsvOutputDialogOpen}
                onClose={terminalCsvOutputDialogOnClose}
            // terminalCsvOutputDialogConfirm={terminalCsvOutputDialogConfirm}
            // data={deleteData}
            />
            <SingleInputDialog
                open={singleInputDialogOpen}
                onClose={singleInputDialogOnClose}
                masterData={masterData}
                onConfirm={singleInputDialogOnConfirm}
                errorOpen={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                setErrorContent={setErrorContent}
                move={move}
                setMove={setMove}
            />
            <TerminalBatchUpdateDialog
                open={TerminalBatchUpdateDialogOpen}
                onClose={TerminalBatchUpdateDialogOnClose}
            />
            <StorageBatchUploadDialog
                open={StorageBatchUploadDialogOpen}
                onClose={StorageBatchUploadDialogOnClose}
            />
            <StorageBatchUpdateDialog
                open={StorageBatchUpdateDialogOpen}
                onClose={StorageBatchUpdateDialogOnClose}
            />
        </div >
    )
}

TerminalManage.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TerminalManage);